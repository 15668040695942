import React, { useState, useEffect } from "react";
import { Container, Card } from "react-bootstrap";
import FormattedDropdownWithButton from "../GenericComponents/FormattedDropdownWithButton";
import GetProfile from "../Api/Controllers/Profiles/GetProfile";
import GetEntities from "../GenericComponents/Entities";
import GetUser from "../Api/Controllers/Users/GetUser";
import PutUser from "../Api/Controllers/Users/PutUser";
import Toolbar from "../GenericComponents/ToolBar";
import DeleteWarning from "../GenericComponents/DeleteWarning";

const profileEntity = GetEntities().Perfil;

const getEmptyUser = () => {
  return {
    ProfileId: "",
  };
};

const UserProfile = (userId) => {
  const [user, setUser] = useState(getEmptyUser());
  const [profile, setProfile] = useState([]);
  const [refreshDropDown, setrefreshDropDown] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deleteProfileForUser = async () => {
    user.ProfileId = "";
    const key = { type: "guid", value: userId.userId };
    await PutUser({ key }, user);
    setRefresh(!refresh);
  };

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const getUser = async () => {
    const key = { type: "guid", value: userId.userId };
    const response = await GetUser({ key });
    setUser({
      name: response.Name,
      lastName: response.LastName,
      DateOfBirth: response.DateOfBirth,
      DocumentNumber: response.DocumentNumber,
      PhoneNumber: response.PhoneNumber,
      CellPhoneNumber: response.CellPhoneNumber,
      Email: response.Email,
      UserName: response.UserName,
      DocumentTypeId: response.DocumentTypeId,
      AddressStreet: response.AddressStreet,
      AddressNumber: response.AddressNumber,
      PostalCode: response.PostalCode,
      AddressAdditionalDetails: response.AddressAdditionalDetails,
      TownId: response.TownId,
      ProvinceId: response.ProvinceId,
      CountryId: response.CountryId,
      ProfileId: response.ProfileId,
    });
  };

  const handleSave = async () => {
    setIsLoading(true);
    const key = { type: "guid", value: userId.userId };
    await PutUser({ key }, user);
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
    setrefreshDropDown(!refreshDropDown);
  };

  const fetchProfile = async () => {
    const response = await GetProfile();
    const profile = response.value.map((profile) => {
      return {
        Name: profile.Name,
        Id: profile.Id,
      };
    });
    setProfile(profile);
  };

  const fillDropdowns = () => {
    fetchProfile();
  };

  useEffect(() => {
    fillDropdowns();
    getUser();
  }, [refreshDropDown]);

  return (
    <Container>
      <Card>
        <Card.Header>
          <Toolbar
            title={"Seleccionar Perfil"}
            entity={profileEntity}
            deleteAction={() => DeleteWarning(() => deleteProfileForUser())}
            saveAction={handleSave}
            setRefresh={setRefresh}
            refresh={refresh}
            showBackButton={false}
            showSaveButton={true}
            showNewButton={false}
            showDeleteButton={true}
            showRefreshButton={true}
            disabled={isLoading}
          />
        </Card.Header>
        <Card.Body>
          <FormattedDropdownWithButton
            dropdownOptions={profile}
            label="Perfil"
            required={true}
            name="ProfileId"
            onChange={handleChange}
            selected={user.ProfileId}
            entity={profileEntity}
            setRefresh={setrefreshDropDown}
            refresh={refreshDropDown}
            popupentity="IdName"
            key={profileEntity}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default UserProfile;
