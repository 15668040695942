import { Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';

function FormattedTextBox({ label, type , as, name, value = "", handleChange, required, onBlur, disabled = false, children }) {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    handleChange(event);
  };

  return (
    <Form.FloatingLabel label={label} className="mb-3">
      <Form.Control
        onBlur={onBlur}
        type={type}
        name={name}
        placeholder={name}
        value={inputValue}
        onChange={handleInputChange}
        required={required}
        disabled={disabled}
        as={as}
      />
      <Form.Control.Feedback type="invalid" />
      {children}
    </Form.FloatingLabel>
  );
}

export default FormattedTextBox;
