import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

dayjs.locale('es');

const SpanishDateCalendar = ({ value, onChange }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DateCalendar
                value={value}
                onChange={onChange}
            />
        </LocalizationProvider>
    );
};

export default SpanishDateCalendar;
