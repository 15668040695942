import Post from "../../BaseApiController/Post"

const endPoint = "/api/Genders"

async function PostGender(body) {
    const successMessage = "Genero creado con exito.";
    const relationship = await Post(endPoint, body, null, successMessage)
    return relationship
}

export default PostGender