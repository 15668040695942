import { axiosPrivate } from "./axios"
import HandleError from "./ErrorHandling"
import SuccessEvent from "../../GenericComponents/SuccessEvent"

//const baseURL = window.location.origin + ":5000"

async function Post(endPoint, body, headers, successMessage) {
    try {
        const response = await axiosPrivate.post(endPoint, body, { headers })
        const json = await response.data
        if (successMessage) {
            SuccessEvent(successMessage);
        }
        return json
    } catch (err) {
        HandleError(err);
    }
}

export default Post