import { axiosPrivate } from "./axios"
import QueryBuilder from "../../QueryBuilder/QueryBuilder"
import HandleError from "./ErrorHandling"
import SuccessEvent from "../../GenericComponents/SuccessEvent"

//const baseURL = window.location.origin

async function Get(endPoint, queryObj, headers, body, successMessage) {

    const query = QueryBuilder(queryObj)
    try {
        const response = await axiosPrivate.get(endPoint + query, body, { headers })
        if (successMessage) {
            SuccessEvent(successMessage);
        }
        return response.data
    } catch (err) {
        HandleError(err);
    }
}

export default Get