import { Modal } from "react-bootstrap";
import FormRelatedPeople from "../Patient/RelatedPeoples/FormRelatedPeople";
import FormProfessionalSchedule from "../Professional/ProfessionalSchedule/FormProfessionalSchedule";
import HealthInsuranceCopaymentForm from "./HealthInsuranceCopaymentForm";
import IdNameForm from "./IdNameForm";
import GetEntities from "./Entities";
import NewScheduleRow from "../Appointment/NewScheduleRow";
import ScheduleModal from "./ScheduleModal";
import { useContext, useEffect } from "react";
import { PopupContext } from "../Dashboard/PopupContext";
import CardTableSearch from "../TableSearch/CardTableSearch";
import MultipleScheduleEvolution from "./MultipleScheduleEvolution";

const Entity = GetEntities();

function Popup(props) {
  const { setPopupOpen } = useContext(PopupContext);

  useEffect(() => {
    if (props.show || props.showModalEvolucion) {
      setPopupOpen(true);
    } else {
      setPopupOpen(false);
    }
  }, [props.show, props.showModalEvolucion, setPopupOpen]);
  switch (props.popupentity) {
    case Entity.Familiares:
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <FormRelatedPeople
              entityId={props.entityId}
              patientId={props.entityfieldid}
              action={props.onHide}
              userAllowed={props.userAllowed}
            />
          </Modal.Body>
        </Modal>
      );
    case Entity.Agenda:
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <FormProfessionalSchedule
              entityId={props.entityId}
              professionalId={props.entityfieldid}
              action={props.onHide}
              userAllowed={props.userAllowed}
            />
          </Modal.Body>
        </Modal>
      );
    case Entity.Copago:
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <HealthInsuranceCopaymentForm
              entityId={props.entityId}
              healthInsuranceId={props.entityfieldid}
              action={props.onHide}
              userAllowed={props.userAllowed}
            />
          </Modal.Body>
        </Modal>
      );
    case "IdName":
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <IdNameForm
              type={props.entity}
              title={props.label}
              isPopUp={true}
              key="idNameForm"
              action={props.onHide}
            />
          </Modal.Body>
        </Modal>
      );
    case Entity.Localidad:
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <IdNameForm
              type={props.entity}
              title={props.label}
              isPopUp={true}
              key="idNameForm"
              action={props.onHide}
              parentType={Entity.Provincia}
              selectedParentIdParentIdparam={props.parentId}
            />
          </Modal.Body>
        </Modal>
      );
    case Entity.Provincia:
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <IdNameForm
              type={props.entity}
              title={props.label}
              isPopUp={true}
              key="idNameForm"
              action={props.onHide}
              parentType={Entity.Pais}
              selectedParentIdParentIdparam={props.parentId}
            />
          </Modal.Body>
        </Modal>
      );
    case Entity.Turno:
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <NewScheduleRow
              existingSchedule={props.entity}
              key={Entity.Turno}
              action={props.onHide}
              openForm={true}
            />
          </Modal.Body>
        </Modal>
      );
    case Entity.Evolucion:
      return (
        <Modal
          {...props}
          show={props.showModalEvolucion}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <ScheduleModal
              showModal={props.showModalEvolucion}
              handleModalClose={props.handleModalClose}
              handleSaveClick={props.handleSaveClick}
              schedule={props.schedule}
              text={props.text}
              setText={props.setText}
            />
          </Modal.Body>
        </Modal>
      );
    case Entity.MultiplesEvoluciones:
      return (
        <Modal
          {...props}
          show={props.showMultipleEvolucionModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <MultipleScheduleEvolution
              handleModalCloseMultipleEvolucion={
                props.handleModalCloseMultipleEvolucion
              }
              schedules={props.schedules}
              fetchData={props.fetchData}
              setShowMultipleEvolucionModal={
                props.setShowMultipleEvolucionModal
              }
            />
          </Modal.Body>
        </Modal>
      );
    case Entity.Paciente:
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <CardTableSearch
              title="Pacientes"
              entity={Entity.Paciente}
              handleSelect={props.handleSelect}
              key={Entity.Paciente}
              showDeleteButton={false}
              showNewButton={false}
              popUp={true}
              checkboxSelection={false}
              action={props.onHide}
              showCloseButton={true}
            />
          </Modal.Body>
        </Modal>
      );
    case Entity.Medicina:
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <CardTableSearch
              title="Medicina"
              entity={Entity.Medicina}
              handleSelect={props.handleSelect}
              key={Entity.Medicina}
              showDeleteButton={false}
              showNewButton={false}
              popUp={true}
              checkboxSelection={false}
              action={props.onHide}
              showCloseButton={true}
              orderBy={['Name asc']}
            />
          </Modal.Body>
        </Modal>
      );
    default:
      break;
  }
}

export default Popup;
