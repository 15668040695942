import Post from "../../BaseApiController/Post"

const endPoint = "/api/Appointments/GetProfessionalsForAppointments/{date}"

async function GetProfessionalAppointment(date, body) {
    let ensambledEndPoint = endPoint.replace("{date}", date);
    const response  = await Post(ensambledEndPoint, body)
    return response
}

export default GetProfessionalAppointment