class SelfserviceAppointment {
    constructor(careModalityId, serviceId, appointmentDateTime, siteId, assistanceId, isOnline) {
        this.CareModalityId = careModalityId;
        this.ServiceId = serviceId;
        this.AppointmentDateTime = appointmentDateTime;
        this.SiteId = siteId;
        this.AssistanceId = assistanceId;
        this.IsOnline = isOnline;
    }
}

export default SelfserviceAppointment