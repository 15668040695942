import React, { useState } from "react";
import { Button, Card, Form, Col, Modal } from "react-bootstrap";
import {
  CheckCircle,
  X,
  Save2Fill,
  XCircle,
  CardChecklist,
} from "react-bootstrap-icons";
import PutAppointmentHC from "../Api/Controllers/Appointments/PutAppointmentHC";

const MultipleScheduleEvolution = ({
  handleModalCloseMultipleEvolucion,
  schedules,
  fetchData,
  setShowMultipleEvolucionModal,
}) => {
  const [appointmentText, setAppointmentText] = useState("");
  const [tickStates, setTickStates] = useState({});

  const handleSaveClick = async () => {
    for (const schedule of schedules) {
      if (tickStates[schedule.Id]) {
        const key = { type: "guid", value: schedule.Id };
        const body = { HC: appointmentText };
        try {
          await PutAppointmentHC(key, body);
          await fetchData();
          setShowMultipleEvolucionModal(false);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const toggleTick = (scheduleId) => {
    setTickStates((prevState) => ({
      ...prevState,
      [scheduleId]: !prevState[scheduleId],
    }));
  };

  return (
    <Col md={12}>
      <Card className="mb-3">
        <Card.Header>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>Cargar Evolucion</h2>
            <div>
              <Button
                variant="success"
                onClick={handleSaveClick}
                style={{ marginRight: "2px" }}
              >
                <Save2Fill title="Save" />
              </Button>
              <Button
                variant="danger"
                onClick={handleModalCloseMultipleEvolucion}
              >
                <XCircle title="Close" />
              </Button>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Form.Group className="mb-3" controlId={`apointmentTextarea`}>
            <Form.Control
              as="textarea"
              rows={3}
              value={appointmentText}
              onChange={(e) => setAppointmentText(e.target.value)}
            />
          </Form.Group>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          {schedules.map((schedule) => (
            <div
              key={schedule.Id}
              onClick={() => !schedule.HC && toggleTick(schedule.Id)}
              style={{ cursor: "pointer" }}
            >
              <span style={{ marginRight: "10px" }}>
                {schedule.PatientName}
              </span>
              {schedule.HC ? (
                <CardChecklist color="green" size={24} />
              ) : tickStates[schedule.Id] ? (
                <CheckCircle color="green" size={24} />
              ) : (
                <X color="red" size={24} />
              )}
              {schedule.HC && (
                <span style={{ marginLeft: "10px" }}>Evolucion Cargada</span>
              )}
            </div>
          ))}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default MultipleScheduleEvolution;
