import Put from "../../BaseApiController/Put"

const endPoint = "/api/Appointments"

async function PutAppointment(queryObj, body) {
    const successMessage = "El turno ha sido modificado con exito.";
    const response = await Put(endPoint, queryObj, body, null, successMessage)
    return response
}

export default PutAppointment