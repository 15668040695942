import Post from "../../BaseApiController/Post"

const endPoint = "/api/Professionals"

async function PostProfessional(body) {
    const successMessage = "Profesional creado con exito.";
    const professional = await Post(endPoint, body, null, successMessage)
    return professional
}

export default PostProfessional