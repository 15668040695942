import Post from "../../BaseApiController/Post"

const endPoint = "/api/RelatedPeoples"

async function PostRelatedPeople(body) {
    const successMessage = "Familiar creado con exito.";
    const people = await Post(endPoint, body, null, successMessage)
    return people
}

export default PostRelatedPeople