import axios from 'axios';
import TokenService from '../../../hooks/TokenService';

const BASE_URL = process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_BASE_URL
    : window.location.origin + ":5000";

export default axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' }
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
});

axiosPrivate.interceptors.request.use(
    config => {
        const accessToken = JSON.parse(localStorage.getItem("accessToken"));
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

axiosPrivate.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            if (TokenService.isRefreshing) {
                return new Promise((resolve, reject) => {
                    TokenService.subscribeTokenRefresh((token) => {
                        originalRequest.headers.Authorization = `Bearer ${token}`;
                        resolve(axiosPrivate(originalRequest));
                    });
                });
            }

            originalRequest._retry = true;
            TokenService.isRefreshing = true;

            try {
                await TokenService.refreshTokens();
                const accessToken = TokenService.getAccessToken();
                originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                return axiosPrivate(originalRequest);
            } catch (error) {
                return Promise.reject(error);
            }
        }

        return Promise.reject(error);
    }
);
