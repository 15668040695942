import React, { useEffect, useState, useRef } from "react";
import GetRelatedPeople from "../../Api/Controllers/RelatedPeoples/GetRelatedPeople";
import FormattedDropdownWithButton from "../../GenericComponents/FormattedDropdownWithButton";
import { Form, Container, Row, Col, Spinner, Card } from "react-bootstrap";
import Toolbar from "../../GenericComponents/ToolBar";
import DeleteRelatedPeople from "../../Api/Controllers/RelatedPeoples/DeleteRelatedPeople";
import PostRelatedPeople from "../../Api/Controllers/RelatedPeoples/PostRelatedPeople";
import PutRelatedPeople from "../../Api/Controllers/RelatedPeoples/PutRelatedPeople";
import GetRelationship from "../../Api/Controllers/Relationships/GetRelationship";
import PersonalInformationForm from "../../GenericComponents/PersonalInformationForm";
import GetEntities from "../../GenericComponents/Entities";
import DeleteWarning from "../../GenericComponents/DeleteWarning";
import FillEntity from "../../GenericComponents/FillEntity";

const relatedPeopleEntity = GetEntities().Familiares;
const relationShipEntity = GetEntities().Parentesco;

const getEmptyRelatedPeople = (patientId) => {
  return FillEntity(relatedPeopleEntity, {
    Id: "",
    PatientId: patientId,
    IsResponsable: false,
    Relationship: "",
    RelationshipId: "",
    Order: 1,
  });
};

const FormRelatedPeople = ({
  entityId,
  patientId,
  action,
  userAllowed = true,
}) => {
  const [resposable, setResponsable] = useState(false);
  const [relationShips, setRelationShips] = useState([]);
  const [refreshDropDown, setrefreshDropDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [relatedPeopleId, setRelatedPeopleId] = useState(entityId);
  const [relatedPeople, setRelatedPeople] = useState(
    getEmptyRelatedPeople(patientId)
  );
  const [refresh, setRefresh] = useState(false);
  const [validated, setValidated] = useState(false);
  const form = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchRelatedPeople = async () => {
    try {
      setLoading(true);
      const key = { type: "guid", value: relatedPeopleId };
      const json = await GetRelatedPeople({ key });
      setRelatedPeople(FillEntity(relatedPeopleEntity, json));
      setResponsable(json.IsResponsable);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = () => {
    setResponsable(!resposable);
    setRelatedPeople({ ...relatedPeople, IsResponsable: !resposable });
  };

  const fetchRelationShip = async () => {
    const response = await GetRelationship();
    const relationShip = response.value.map((relation) => {
      return {
        Name: relation.Name,
        Id: relation.Id,
      };
    });
    setRelationShips(relationShip);
  };

  const fillDropdowns = () => {
    fetchRelationShip();
  };

  const handleChange = (event) => {
    setRelatedPeople({
      ...relatedPeople,
      [event.target.name]: event.target.value,
    });
  };

  const deleteRelatedPeople = async () => {
    const key = { type: "guid", value: entityId };
    await DeleteRelatedPeople({ key });
    setRelatedPeopleId(undefined);
    setResponsable(false);
    entityId = undefined;
    action();
    setRefresh(!refresh);
  };

  const createRelatedPeopleRequest = () => {
    return {
      name: relatedPeople.Name,
      lastName: relatedPeople.LastName,
      dateOfBirth: relatedPeople.DateOfBirth,
      documentNumber: relatedPeople.DocumentNumber,
      phoneNumber: relatedPeople.PhoneNumber,
      cellPhoneNumber: relatedPeople.CellPhoneNumber,
      email: relatedPeople.Email,
      documentTypeId: relatedPeople.DocumentTypeId,
      addressStreet: relatedPeople.AddressStreet,
      addressNumber: relatedPeople.AddressNumber,
      postalCode: relatedPeople.PostalCode,
      addressAdditionalDetails: relatedPeople.AddressAdditionalDetails,
      townId: relatedPeople.TownId,
      relationshipId: relatedPeople.RelationshipId,
      patientId: relatedPeople.PatientId,
      order: 1,
      isResponsable: relatedPeople.IsResponsable,
    };
  };

  const handleSaveClick = async (event) => {
    setIsLoading(true);
    const formValid = form.current.checkValidity();
    if (!formValid) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      try {
        setLoading(true);
        if (relatedPeopleId !== undefined) {
          const key = { type: "guid", value: relatedPeople.Id };
          await PutRelatedPeople({ key }, relatedPeople);
        } else {
          const request = createRelatedPeopleRequest();
          const relatedPeopleResponse = await PostRelatedPeople(request);
          if (relatedPeopleResponse) {
            setRelatedPeopleId(relatedPeopleResponse.Id);
            setRefresh(!refresh);
          }
        }
        action();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    setTimeout(() => setIsLoading(false), 1000);
  };

  useEffect(() => {
    if (relatedPeopleId !== undefined) {
      fetchRelatedPeople();
    } else {
      setRelatedPeople(getEmptyRelatedPeople(patientId));
    }
  }, [refresh]);

  useEffect(() => {
    fillDropdowns();
  }, [refreshDropDown]);

  if (loading) {
    return <Spinner animation="border" variant="secondary" />;
  }

  return (
    <Container>
      <Card>
        <Card.Header>
          {!relatedPeopleId ? (
            <Toolbar
              title="Crear Familiar"
              entity={relatedPeopleEntity}
              saveAction={handleSaveClick}
              setRefresh={setRefresh}
              refresh={refresh}
              showSaveButton={userAllowed}
              showCloseButton={true}
              actionClose={action}
              disabled={isLoading}
            />
          ) : (
            <Toolbar
              title="Editar Familiar"
              entity={relatedPeopleEntity}
              deleteAction={() => DeleteWarning(deleteRelatedPeople)}
              saveAction={handleSaveClick}
              setRefresh={setRefresh}
              refresh={refresh}
              showSaveButton={userAllowed}
              showDeleteButton={userAllowed}
              showRefreshButton={true}
              showCloseButton={true}
              actionClose={action}
              disabled={isLoading}
            />
          )}
        </Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} key="formRole" ref={form}>
            <Col sm={50}>
              <PersonalInformationForm
                userId={relatedPeopleId}
                user={relatedPeople}
                setUser={setRelatedPeople}
                validated={validated}
                setValidated={setValidated}
                refresh={refresh}
                setRefresh={setRefresh}
                userType={relatedPeopleEntity}
                isPopUp={true}
                disabled={!userAllowed}
              />
              <Row className="g-2">
                <Col md>
                  <FormattedDropdownWithButton
                    dropdownOptions={relationShips}
                    label="Relacion"
                    required={true}
                    name="RelationshipId"
                    onChange={handleChange}
                    selected={relatedPeople.RelationshipId}
                    entity={relationShipEntity}
                    setRefresh={setrefreshDropDown}
                    refresh={refreshDropDown}
                    popupentity="IdName"
                    key="RelationshipId"
                    disabled={!userAllowed}
                  />
                </Col>
                <Col md>
                  <Form.Check
                    type="checkbox"
                    label="Responsable"
                    checked={resposable}
                    onChange={handleCheckboxChange}
                    disabled={!userAllowed}
                  />
                </Col>
              </Row>
            </Col>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default FormRelatedPeople;
