import Put from "../../BaseApiController/Put"

const endPoint = "/api/Prescriptions"

async function PutPrescriptions(queryObj, body) {
    const successMessage = "Receta modificado con exito.";
    const prescription = await Put(endPoint, queryObj, body, null, successMessage)
    return prescription
}

export default PutPrescriptions