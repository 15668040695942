import React, { useEffect, useReducer, useCallback } from 'react';
import { Row, Card, Container, ListGroup } from "react-bootstrap";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import GetEntities from '../GenericComponents/Entities';
import { GetEndPoint } from "../Api/Controllers/Common/EndPoints"
import ExistingSchedule from './ExistingSchedule';
import SpanishDateCalendar from '../GenericComponents/SpanishDateCalendar';

const Entity = GetEntities();
dayjs.extend(utc);
const apiAllAppointments = GetEndPoint(Entity.Turnos);

const initialState = {
    date: dayjs(new Date().toISOString()),
    appointments: {},
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_DATE':
            return { ...state, date: action.payload };
        case 'SET_APPOINTMENTS':
            return { ...state, appointments: action.payload };
        case 'TOGGLE_REFRESH':
            return { ...state, refresh: !state.refresh };
        default:
            return state;
    }
};

// /Turnos screen -> Agenda Diaria Menu item
const AllAppointments = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const currentDate = state.date.format('YYYY-MM-DD');

    const groupByProfessional = (appointments) => {
        return appointments.reduce((acc, appointment) => {
            const key = appointment.ProfessionalName;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(appointment);
            return acc;
        }, {});
    };

    const handleRefresh = useCallback(() => {
        dispatch({ type: 'TOGGLE_REFRESH' });
    }, [dispatch]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await apiAllAppointments(currentDate);
            let arrayAppointments;
            if (response) {
                arrayAppointments = groupByProfessional(response);
            }
            dispatch({ type: 'SET_APPOINTMENTS', payload: arrayAppointments });
        };
        fetchData();
    }, [state.date, state.refresh])

    return (
        <Container>
            <Card>
                <Card.Header>
                    <h2>Todos los turnos</h2>
                </Card.Header>
                <Card.Body>
                    <Row className="d-flex align-items-center justify-content-center">
                            <Card style={{ width: '320px' }}>
                            <SpanishDateCalendar
                                    value={state.date}
                                    onChange={(newDate) => dispatch({ type: 'SET_DATE', payload: newDate })}
                                />
                            </Card>
                        <Row className="g-2">
                            {
                                Object.keys(state.appointments).length > 0 ?
                                    <h4>Esta viendo los turnos del dia {state.date.format('DD/MM')}</h4> :
                                    <h4>No hay turnos para el dia {state.date.format('DD/MM')}</h4>
                            }
                        </Row>
                    </Row>
                    <Row className="g-2">
                        <ListGroup variant="flush">
                            {Object.keys(state.appointments).map((ProfessionalName, appointments) => (
                                <div key={ProfessionalName}>
                                    <div style={{ backgroundColor: '#abe695', width: '100%', padding: '10px' }}>
                                        <h4>Turnos de {ProfessionalName}</h4>
                                    </div>
                                    {state.appointments[ProfessionalName].map((schedule, innerIndex) => (
                                        <ExistingSchedule
                                            key={innerIndex}
                                            schedule={schedule}
                                            refresh={handleRefresh}
                                        />
                                    ))}
                                </div>
                            ))}
                        </ListGroup>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default AllAppointments