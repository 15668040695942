import React, { useEffect, useState } from "react";
import { Form, Row, Col, Image, InputGroup, Spinner } from "react-bootstrap";
import { GetEndPoint } from "../Api/Controllers/Common/EndPoints";
import FormattedTextBox from "./FormattedTextBox";
import { FileEarmarkPerson } from 'react-bootstrap-icons';
import imgDefault from "../../assets/defaultImage.jpg"
import FormattedDropdownWithButton from "./FormattedDropdownWithButton";
import GetCountry from "../Api/Controllers/Countries/GetCountry"
import GetDocumentType from "../Api/Controllers/DocumentTypes/GetDocumentType"
import GetProvince from "../Api/Controllers/Provinces/GetProvince"
import GetTown from "../Api/Controllers/Towns/GetTown"
import GetUploadedFiles from "../Api/Controllers/UploadedFiles/GetUploadedFiles";
import FillEntity from "./FillEntity";
import GetEntities from "./Entities";
import PhoneNumberValidator from "./PhoneNumberValidator";
import { useUnsavedChanges } from "../Context/UnsavedChangesContext";

const Entity = GetEntities();

const getEmptyUser = (userType) => {
    return FillEntity(userType, {
        Name: "",
        LastName: "",
        Email: "",
        UserName: "",
        PhoneNumber: "",
        CellPhoneNumber: "",
        DateOfBirth: "",
        DocumentNumber: "",
        DocumentTypeId: "",
        AddressStreet: "",
        AddressNumber: "",
        AddressAdditionalDetails: "",
        TownId: "",
        ProvinceId: "",
        CountryId: "",
        PostalCode: ""
    })
}

const PersonalInformationForm = ({ userId,
    user,
    setUser,
    preview,
    setPreview,
    validated,
    setValidated,
    refresh,
    setRefresh,
    setimgProfile,
    userType,
    isPopUp,
    disabled = false,
    featureFillEntity = false }) => {

    const [countries, setCountries] = useState([])
    const [gender, setGender] = useState([])
    const [provinces, setProvinces] = useState([])
    const [towns, setTowns] = useState([])
    const [documentTypes, setDocumentTypes] = useState([])
    const [refreshDropDown, setrefreshDropDown] = useState(false)
    const [loading, setLoading] = useState(false)
    const { setHasUnsavedChanges } = useUnsavedChanges();
    let colSm = isPopUp ? 50 : 10

    const locationFieldsValidation = () => {
        if (user.AddressNumber || user.PostalCode || user.AddressStreet || user.CountryId || user.ProvinceId || user.TownId) {
            return true
        }
        return false
    }

    const fetchUserData = async (userId) => {
        try {
            setLoading(true)
            const key = { type: "guid", value: userId }
            const api = GetEndPoint(userType)
            const response = await api({ key })
            if (featureFillEntity) {
                const responseEntity = FillEntity(userType, response)
                setUser(responseEntity)
            } else {
                setUser({
                    ...user,
                    Name: response.Name, LastName: response.LastName, Email: response.Email, UserName: response.UserName, PhoneNumber: response.PhoneNumber,
                    CellPhoneNumber: response.CellPhoneNumber, DateOfBirth: response.DateOfBirth, DocumentNumber: response.DocumentNumber, DocumentTypeId: response.DocumentTypeId,
                    AddressStreet: response.AddressStreet, AddressNumber: response.AddressNumber, AddressAdditionalDetails: response.AddressAdditionalDetails, TownId: response.TownId,
                    ProvinceId: response.ProvinceId, CountryId: response.CountryId, PostalCode: response.PostalCode, GenderId: response.GenderId
                })
            }
            if (setPreview !== undefined) {
                const obj = { relatedEntityId: userId, uploadedFileType: 0 }
                const srcProfilePicture = await GetUploadedFiles(obj)
                if (srcProfilePicture.length > 0) {
                    const urlProfilePicture = srcProfilePicture[0].Url
                    setPreview(urlProfilePicture)
                }
            }
            fetchProvince(response.CountryId)
            fetchTown(response.ProvinceId)

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }

    }

    const fillDropdowns = () => {
        fetchCountry()
        fetchDocumentType()
        fetchGender()
    }

    const fetchDocumentType = async () => {
        const response = await GetDocumentType()
        const documentType = response.value.map((responseDocumentType) => {
            return {
                Name: responseDocumentType.Name,
                Id: responseDocumentType.Id
            }
        })
        setDocumentTypes(documentType)

    }

    const fetchGender = async () => {
        const api = GetEndPoint(Entity.Genero)
        const response = await api()
        const genders = response.value.map((response) => {
            return {
                Name: response.Name,
                Id: response.Id
            }
        })
        setGender(genders)

    }

    const fetchCountry = async () => {
        const response = await GetCountry()
        const countries = response.value.map((responseCountry) => {
            return {
                Name: responseCountry.Name,
                Id: responseCountry.Id
            }
        })
        setCountries(countries)
    }

    const fetchProvince = async (parentId) => {
        const filter = { "ParentId": { eq: { type: 'guid', value: parentId } } };
        const response = await GetProvince({ filter })
        const provinces = response.value.map((responseProvince) => {
            return {
                Name: responseProvince.Name,
                Id: responseProvince.Id
            }
        })
        setProvinces(provinces)
    }

    const fetchTown = async (parentId) => {
        const filter = { "ParentId": { eq: { type: 'guid', value: parentId } } };
        const response = await GetTown({ filter })
        const towns = response.value.map((responseTown) => {
            return {
                Name: responseTown.Name,
                Id: responseTown.Id
            }
        })
        setTowns(towns)
    }

    const handleChange = event => {
        if (event.target.name === "profilePicture") {
            setPreview(URL.createObjectURL(event.target.files[0]));
            setimgProfile(event.target.files[0]);
        }
        if (event.target.name === "CountryId") {
            fetchProvince(event.target.value)
            setTowns([])
        }
        if (event.target.name === "ProvinceId") {
            fetchTown(event.target.value)
        }
        setUser({ ...user, [event.target.name]: event.target.value });
        setHasUnsavedChanges(true);
    };

    const handlePhoneNumberChange = event => {
        setHasUnsavedChanges(true);
        const { name, value } = event.target;
        setUser({ ...user, [name]: value });
    };

    useEffect(() => {
        if (userId !== undefined) {
            fetchUserData(userId)
        } else {
            setUser(getEmptyUser(userType))
        }
        if (!disabled) {
            setValidated(false)
        }
    }, [refresh])

    useEffect(() => {
        fillDropdowns()
    }, [refreshDropDown])

    if (loading) {
        return <Spinner animation="border" variant="secondary" />
    }

    return (
        <>
            {setPreview && (
                <Col sm={2} className="text-center">
                    {preview && (<Image fluid={true} src={preview} alt="Profile"></Image>)}
                    {!preview && (<Image fluid={true} src={imgDefault} alt="Profile"></Image>)}
                    <Form.Label htmlFor="profilePicture" style={{ marginTop: '5px' }}>
                        <InputGroup>
                            <InputGroup.Text><FileEarmarkPerson title='Cambiar foto' size={20}></FileEarmarkPerson></InputGroup.Text>
                            <InputGroup.Text>Foto</InputGroup.Text>
                        </InputGroup>
                    </Form.Label>
                    <Form.Control
                        id="profilePicture"
                        type="file"
                        placeholder=""
                        name="profilePicture"
                        value=""
                        onChange={handleChange}
                        size="sm"
                        style={{ visibility: 'hidden' }}
                    />
                </Col>
            )}
            <Col sm={colSm}>
                <Row className="g-2">
                    <Col md>
                        <FormattedTextBox
                            label="Nombres"
                            type="text"
                            name="Name"
                            value={user.Name}
                            handleChange={handleChange}
                            required={true}
                            validated={validated}
                            disabled={disabled} />
                    </Col>
                    <Col md>
                        <FormattedTextBox
                            label="Apellidos"
                            type="text"
                            name="LastName"
                            value={user.LastName}
                            handleChange={handleChange}
                            required={true}
                            validated={validated}
                            disabled={disabled} />
                    </Col>
                </Row>
                <Row className="g-2">
                    <Col md >
                        <FormattedDropdownWithButton
                            dropdownOptions={documentTypes}
                            label="Tipo de Documento"
                            required={true}
                            name="DocumentTypeId"
                            onChange={handleChange}
                            selected={user.DocumentTypeId}
                            entity="TipoDocumento"
                            setRefresh={setrefreshDropDown}
                            refresh={refreshDropDown}
                            popupentity="IdName"
                            disabled={disabled} />
                    </Col>
                    <Col md >
                        <FormattedTextBox
                            label="Numero de documento"
                            type="number"
                            name="DocumentNumber"
                            value={user.DocumentNumber}
                            handleChange={handleChange}
                            required={true}
                            validated={validated}
                            disabled={disabled} />
                    </Col>
                </Row>
                {userType === Entity.Paciente && (
                    <Row className="g-2">
                        <Col md className="mb-3">
                            <FormattedDropdownWithButton
                                dropdownOptions={gender}
                                label="Genero"
                                required={false}
                                name="GenderId"
                                onChange={handleChange}
                                selected={user.GenderId}
                                entity={Entity.Genero}
                                setRefresh={setrefreshDropDown}
                                refresh={refreshDropDown}
                                popupentity="IdName"
                                disabled={disabled} />
                        </Col>
                    </Row>
                )}
                <Row className="g-2">
                    <FormattedTextBox
                        label="Email"
                        type="email"
                        name="Email"
                        value={user.Email}
                        handleChange={handleChange}
                        required={true}
                        validated={validated}
                        disabled={disabled} />
                </Row>
                <Row className="g-2">
                    <Col md className="mb-3">
                        <PhoneNumberValidator
                            label="Numero de telefono"
                            name="PhoneNumber"
                            value={user.PhoneNumber}
                            handleChange={handlePhoneNumberChange}
                            required={false}
                            disabled={disabled}
                        />
                    </Col>
                    <Col md className="mb-3">
                        <PhoneNumberValidator
                            label="Numero de Celular"
                            name="CellPhoneNumber"
                            value={user.CellPhoneNumber}
                            handleChange={handlePhoneNumberChange}
                            required={false}
                            disabled={disabled}
                            isCellPhone={true}
                        />
                    </Col>
                </Row>
                <Row className="g-2">
                    <Col md>
                        <FormattedTextBox
                            label="Calle"
                            type="text"
                            name="AddressStreet"
                            value={user.AddressStreet}
                            handleChange={handleChange}
                            required={locationFieldsValidation()}
                            validated={validated}
                            disabled={disabled} />
                    </Col>
                    <Col md>
                        <Row className="g-2">
                            <Col md>
                                <FormattedTextBox
                                    label="Altura"
                                    type="text"
                                    name="AddressNumber"
                                    value={user.AddressNumber}
                                    handleChange={handleChange}
                                    required={false}
                                    validated={validated}
                                    disabled={disabled} />
                            </Col>
                            <Col md>
                                <FormattedTextBox
                                    label="Codigo Postal"
                                    type="text"
                                    name="PostalCode"
                                    value={user.PostalCode}
                                    handleChange={handleChange}
                                    required={false}
                                    validated={validated}
                                    disabled={disabled} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <FormattedTextBox
                    label="Datos adicionales de Domicilio"
                    type="text"
                    name="AddressAdditionalDetails"
                    value={user.AddressAdditionalDetails}
                    handleChange={handleChange}
                    required={false}
                    validated={validated}
                    disabled={disabled} />
                <Row className="g-2 mb-3">
                    <Col md style={{ marginRight: "10px" }}>
                        <FormattedDropdownWithButton
                            dropdownOptions={countries}
                            label="Pais"
                            required={false}
                            name="CountryId"
                            onChange={handleChange}
                            selected={user.CountryId}
                            entity="Pais"
                            setRefresh={setrefreshDropDown}
                            refresh={refreshDropDown}
                            popupentity="IdName"
                            disabled={disabled} />
                    </Col>
                    <Col md style={{ marginRight: "10px" }}>
                        <FormattedDropdownWithButton
                            dropdownOptions={provinces}
                            label="Provincia"
                            required={false}
                            name="ProvinceId"
                            onChange={handleChange}
                            selected={user.ProvinceId}
                            entity="Provincia"
                            setRefresh={setrefreshDropDown}
                            refresh={refreshDropDown}
                            popupentity={Entity.Provincia}
                            disabled={disabled}
                            parentId={user.CountryId} />
                    </Col>
                    <Col md >
                        <FormattedDropdownWithButton
                            dropdownOptions={towns}
                            label="Localidad"
                            required={false}
                            name="TownId"
                            onChange={handleChange}
                            selected={user.TownId}
                            entity="Localidad"
                            setRefresh={setrefreshDropDown}
                            refresh={refreshDropDown}
                            popupentity={Entity.Localidad}
                            disabled={disabled}
                            parentId={user.ProvinceId} />
                    </Col>
                </Row>
                <FormattedTextBox
                    label="Fecha de nacimiento"
                    type="date"
                    name="DateOfBirth"
                    value={user.DateOfBirth}
                    handleChange={handleChange}
                    required={false}
                    validated={validated}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    disabled={disabled} />
            </Col>
        </>
    );
};

export default PersonalInformationForm;
