import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Form,
  Button,
  Pagination,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import Toolbar from "../../GenericComponents/ToolBar";
import GetEntities from "../../GenericComponents/Entities";
import PutAppointmentHC from "../../Api/Controllers/Appointments/PutAppointmentHC";
import GetAppointment from "../../Api/Controllers/Appointments/GetAppointment";
import GetCurrentUser from "../../Api/Controllers/Security/GetCurrentUser";
import { ColumnsForAPI } from "../../TableSearch/TableSearchUtils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { FiletypePdf } from "react-bootstrap-icons";
import GetUploadedFiles from "../../Api/Controllers/UploadedFiles/GetUploadedFiles";

const Entity = GetEntities();
const ITEMS_PER_PAGE = 4;
dayjs.extend(utc);

const Evolution = ({ patientId, userAllowed = true }) => {
  const [text, setText] = useState({});
  const [appointments, setAppointments] = useState([]);
  const [onlyMyAppointment, setOnlyMyAppointment] = useState(false);
  const [myProfessionalId, setMyProfessionalId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentAppointments, setCurrentAppointments] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const columsForApi = ColumnsForAPI(Entity.MisTurnos);
  const [loading, setLoading] = useState(false);

  const [obj, setObj] = useState({
    count: true,
    top: ITEMS_PER_PAGE,
    skip: 0,
    filter: {},
    select: columsForApi,
    orderBy: ["AppointmentDateTime desc"],
  });

  const fetchAppointment = async () => {
    let updatedObj = { ...obj };
    updatedObj.skip = (currentPage - 1) * ITEMS_PER_PAGE;

    if (onlyMyAppointment) {
      updatedObj.filter = {
        ProfessionalId: { eq: { type: "guid", value: myProfessionalId } },
        patientId: { eq: { type: "guid", value: patientId } },
      };
    } else {
      updatedObj.filter = {
        patientId: { eq: { type: "guid", value: patientId } },
      };
    }

    const response = await GetAppointment(updatedObj);
    setAppointments(response.value);
    setCurrentAppointments(response.value);
    setTotalPages(Math.ceil(response["@odata.count"] / ITEMS_PER_PAGE));
  };

  useEffect(() => {
    fetchAppointment();
  }, [onlyMyAppointment, currentPage, patientId, myProfessionalId, refresh]);

  const handleInputChange = (id) => (event) => {
    setText({ ...text, [id]: event.target.value });
  };

  const fetchPDFUrl = async (currentAppointmentId) => {
    try {
      const obj = {
        relatedEntityId: currentAppointmentId,
        uploadedFileType: 4,
      };
      const response = await GetUploadedFiles(obj);
      if (response.length > 0) {
        downloadPDF(response[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const downloadPDF = (pdf) => {
    window.open(pdf.Url);
  };

  const handleSaveClick = async (event) => {
    for (const appointmentId in text) {
      const appointment = appointments.find((app) => app.Id === appointmentId);
      if (appointment && appointment.HC === null) {
        const key = { type: "guid", value: appointmentId };
        const body = { HC: text[appointmentId] };
        try {
          await PutAppointmentHC(key, body);
        } catch (error) {
          console.log(error);
        }
      }
    }

    const updatedObj = {
      ...obj,
      skip: (currentPage - 1) * ITEMS_PER_PAGE,
      top: ITEMS_PER_PAGE,
    };

    fetchAppointment(updatedObj);
  };

  const handleOnlyMyEvolutionsClick = () => {
    const onlyMyApp = !onlyMyAppointment;

    if (onlyMyApp) {
      fetchProfessionalId().then((fetchedProfessionalId) => {
        setMyProfessionalId(fetchedProfessionalId);
        setOnlyMyAppointment(onlyMyApp);
      });
    } else {
      setMyProfessionalId(null);
      setOnlyMyAppointment(onlyMyApp);
    }
  };

  const fetchProfessionalId = async () => {
    const userResponse = await GetCurrentUser();
    return userResponse.data.Id;
  };

  if (loading) {
    return <Spinner animation="border" variant="secondary" />;
  }

  return (
    <Container>
      <Card>
        <Card.Header>
          <Toolbar
            title="Evoluciones"
            deleteAction={""}
            saveAction={handleSaveClick}
            setRefresh={setRefresh}
            refresh={refresh}
            showSaveButton={true}
            showRefreshButton={true}
            key="HC"
          />
        </Card.Header>
        <Card.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="light" onClick={handleOnlyMyEvolutionsClick}>
              {onlyMyAppointment
                ? "Todas las evoluciones"
                : "Solo mis evoluciones"}
            </Button>
          </div>
          {currentAppointments.map((currentAppointment, index) => (
            <Form key={index}>
              <Row>
                <Col md={4}>
                  <Form.Group className="mb-3" controlId={`dateLabel-${index}`}>
                    <Form.Label style={{ marginRight: "2px" }}>
                      Fecha:{" "}
                      <strong>
                        {currentAppointment.AppointmentDateTime
                          ? dayjs
                              .utc(currentAppointment.AppointmentDateTime)
                              .format("DD/MM/YYYY HH:mm")
                          : "NO INGRESADO"}
                      </strong>
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    className="mb-3"
                    controlId={`professionalLabel-${index}`}
                  >
                    <Form.Label>
                      Profesional: {currentAppointment.ProfessionalName}
                    </Form.Label>
                  </Form.Group>
                </Col>
                {currentAppointment.HC && userAllowed && (
                  <Col md={4}>
                    <Button
                      variant="warning"
                      onClick={() => fetchPDFUrl(currentAppointment.Id)}
                    >
                      <FiletypePdf title="Descargar evolución" />
                    </Button>
                  </Col>
                )}
              </Row>
              <Form.Label>Evolución:</Form.Label>
              <Form.Group
                className="mb-3"
                controlId={`appointmentTextarea-${index}`}
              >
                <Form.Control
                  as="textarea"
                  rows={10}
                  className="textarea-evolution"
                  placeholder="Ingrese su evolución"
                  value={
                    currentAppointment.HC || text[currentAppointment.Id] || ""
                  }
                  readOnly={currentAppointment.HC != null}
                  onChange={handleInputChange(currentAppointment.Id)}
                />
              </Form.Group>
              <hr />
            </Form>
          ))}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination>
              <Pagination.Prev
                onClick={() =>
                  setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
                }
              />
              <Pagination.Next
                onClick={() =>
                  setCurrentPage(
                    currentPage < totalPages ? currentPage + 1 : currentPage
                  )
                }
              />
            </Pagination>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Evolution;
