import Put from "../../BaseApiController/Put"

const endPoint = "/api/DocumentTypes"

async function PutDocumentType(queryObj, body) {
    const successMessage = "Tipo de documento modificado con exito.";
    const documentType = await Put(endPoint, queryObj, body, null, successMessage)
    return documentType
}

export default PutDocumentType