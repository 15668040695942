import Swal from "sweetalert2";

const Warning = (action, message, confirmButtonText = "Enviar") => {
    const alertConfig = {
        showConfirmButton: true,
        width: 500,
        position: "center",
        icon: "question",
        title: message,
        confirmButtonColor: "green",
        cancelButtonColor: "red",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: confirmButtonText
    };
    
    Swal.fire(alertConfig).then((result) => {
        if (result.isConfirmed) {
            action()
        }
    })
}

export default Warning