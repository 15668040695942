import Put from "../../BaseApiController/Put"

const endPoint = "/api/HealthInsurances"

async function PutHealthInsurances(queryObj, body) {
    const successMessage = "Obra social modificada con exito.";
    const response =  await Put(endPoint, queryObj, body, null, successMessage)
    return response
}

export default PutHealthInsurances