import React, { useState, useEffect, useRef, useContext } from "react";
import FormattedTextBox from "./FormattedTextBox";
import { Form, Container, Card, Tabs, Tab } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { NewEntityPath, EditEntityPath } from "../Api/Controllers/Common/Paths";
import {
  GetEndPoint,
  PutEndPoint,
  DeleteEndPoint,
  PostEndPoint,
} from "../Api/Controllers/Common/EndPoints";
import Toolbar from "./ToolBar";
import DeleteWarning from "../GenericComponents/DeleteWarning";
import CardTableSearch from "../TableSearch/CardTableSearch";
import { UserOperationsAllowedContext } from "../Dashboard/UserOperationsAllowedContext";
import FormattedDropdown from "./FormattedDropdown";
import GetEntities from "./Entities";

const Entity = GetEntities();

const HealthInsuranceIdNameForm = ({
  type,
  title,
  isPopUp,
  action,
  requiredOperations = [],
}) => {
  let { id } = useParams();
  let { healthInsuranceId } = useParams();
  const [activeTab, setActiveTab] = useState("healthInsurance");
  const newEntityPath = NewEntityPath(type);
  let navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [entityName, setEntityName] = useState("");
  const [secondEntityName, setSecondEntityName] = useState("");
  const [electronicPrescriptionProviderId, setElectronicPrescriptionProviderId] = useState(null);
  const [showConditionalDropdown, setShowConditionalDropdown] = useState(false);
  const [electronicPrescriptionHealthInsuranceId, setElectronicPrescriptionHealthInsuranceId] = useState(null);
  const [validated, setValidated] = useState(false);
  const form = useRef(null);
  const editEntityPath = EditEntityPath(type);
  const [isLoading, setIsLoading] = useState(false);
  const { userOperationsAllowed, hasAllRequiredOperations } = useContext(
    UserOperationsAllowedContext
  );

  const handleChange = (event) => {
    if (event.target.name === "name") {
      setEntityName(event.target.value);
    } else if (event.target.name === "plan") {
      setSecondEntityName(event.target.value);
    }
  };

  const fetchData = async (id, type) => {
    const key = { type: "guid", value: id };
    const api = GetEndPoint(type);
    const response = await api({ key });
    setEntityName(response.Name);
    setSecondEntityName(response.Plan);
    setElectronicPrescriptionProviderId(response.ElectronicPrescriptionProviderId);
    setElectronicPrescriptionHealthInsuranceId(response.ElectronicPrescriptionHealthInsuranceId);
  };

  const deleteEntity = async () => {
    const key = { type: "guid", value: id };
    const api = DeleteEndPoint(type);
    await api({ key });
    navigate(newEntityPath);
    id = undefined;
    setRefresh(!refresh);
  };

  const handleSaveClick = async (event) => {
    setIsLoading(true);
    setValidated(form.current.checkValidity() === false);
    if (form.current.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const body = {
        name: entityName,
        plan: secondEntityName,
        ElectronicPrescriptionProviderId: electronicPrescriptionProviderId,
        ElectronicPrescriptionHealthInsuranceId: electronicPrescriptionHealthInsuranceId,
      };

      if (id !== undefined) {
        const key = { type: "guid", value: id };
        const api = PutEndPoint(type);
        await api({ key }, body);
      } else {
        const api = PostEndPoint(type);
        const response = await api(body);
        if (isPopUp) {
          setEntityName("");
          setSecondEntityName("");
        } else {
          const newEntityId = response.Id;
          navigate(`${editEntityPath}${newEntityId}`, {
            state: { id: newEntityId },
          });
        }
      }
      if (isPopUp) {
        action();
      }
    }
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (id !== undefined) {
      fetchData(id, type);
    } else {
      setEntityName("");
      setSecondEntityName("");
      setElectronicPrescriptionProviderId("");
      setElectronicPrescriptionHealthInsuranceId("");
    }
    setValidated(false);
  }, [id, type, refresh]);

  useEffect(() => {
    if (electronicPrescriptionProviderId === 0) {
      setShowConditionalDropdown(true);
    } else {
      setShowConditionalDropdown(false);
    }
  }, [electronicPrescriptionProviderId]);

  const handleSelect = (key) => setActiveTab(key);

  const userAllowed = hasAllRequiredOperations({
    requiredOperations,
    userOperations: userOperationsAllowed,
  });

  return (
    <Container>
      <Card>
        <Tabs
          activeKey={activeTab}
          onSelect={handleSelect}
          id="justify-tab-example"
          className="mb-3"
          justify
        >
          <Tab eventKey="healthInsurance" title="Obras Sociales">
            {activeTab === "healthInsurance" && (
              <Card.Body>
                <Container>
                  <Card>
                    <Card.Header>
                      {!id ? (
                        <Toolbar
                          title={"Crear " + title}
                          entity={type}
                          saveAction={handleSaveClick}
                          setRefresh={setRefresh}
                          refresh={refresh}
                          showBackButton={!isPopUp}
                          showSaveButton={userAllowed}
                          showCloseButton={isPopUp}
                          actionClose={action}
                          disabled={isLoading}
                        />
                      ) : (
                        <Toolbar
                          title={"Editar " + title}
                          entity={type}
                          deleteAction={() => DeleteWarning(deleteEntity)}
                          saveAction={handleSaveClick}
                          setRefresh={setRefresh}
                          refresh={refresh}
                          showBackButton={!isPopUp}
                          showSaveButton={userAllowed}
                          showNewButton={userAllowed}
                          showDeleteButton={userAllowed}
                          showRefreshButton={true}
                          disabled={isLoading}
                        />
                      )}
                    </Card.Header>
                    <Card.Body>
                      <Form noValidate validated={validated} ref={form}>
                        <FormattedTextBox
                          label="Nombre"
                          type="text"
                          placeholder="Ingrese el nombre"
                          name="name"
                          value={entityName}
                          handleChange={handleChange}
                          required={true}
                          disabled={!userAllowed}
                        />
                        <FormattedTextBox
                          label="Plan"
                          type="text"
                          placeholder="Ingrese el plan"
                          name="plan"
                          value={secondEntityName}
                          handleChange={handleChange}
                          required={true}
                          disabled={!userAllowed}
                        />
                        <FormattedDropdown
                          apiEndpoint={Entity.ProvedorElectronico}
                          label="Provedor Electronico"
                          required={false}
                          name="GenericId"
                          value={electronicPrescriptionProviderId}
                          onChange={setElectronicPrescriptionProviderId}
                          disabled={!userAllowed}
                        />
                        {showConditionalDropdown && (
                          <FormattedDropdown
                            apiEndpoint={Entity.ObraSocialFarmaLink}
                            label="Obra Social Farmalink"
                            required={true}
                            name="conditionalDropdown"
                            value={electronicPrescriptionHealthInsuranceId}
                            onChange={setElectronicPrescriptionHealthInsuranceId}
                            conditions={{ ElectronicPrescriptionProviderId: electronicPrescriptionProviderId }}
                            disabled={!userAllowed}
                          />
                        )}
                      </Form>
                    </Card.Body>
                  </Card>
                </Container>
              </Card.Body>
            )}
          </Tab>
          <Tab eventKey="copago" title="Copago" disabled={id === undefined}>
            {activeTab === "copago" && (
              <Card.Body>
                <CardTableSearch
                  title="Copago"
                  entity={"Copago"}
                  columns={"Copago"}
                  key={"Copago"}
                  entityId={id}
                  popUp={true}
                  showDeleteButton={true}
                  showNewButton={true}
                  requiredOperations={requiredOperations}
                />
              </Card.Body>
            )}
          </Tab>
        </Tabs>
      </Card>
    </Container>
  );
};

export default HealthInsuranceIdNameForm;
