import Post from "../../BaseApiController/Post"

const endPoint = "/api/HealthInsuranceCoPayments"

async function PostHealthInsuranceCopayment(body) {
    const successMessage = "Copago creado con exito.";
    const response = await Post(endPoint, body, null, successMessage)
    return response
}

export default PostHealthInsuranceCopayment