import { useState, useEffect, useRef } from "react";
import { Card, Container, Form, Row, Col } from "react-bootstrap";
import Toolbar from "../../GenericComponents/ToolBar";
import GetEntities from "../../GenericComponents/Entities";
import { GetEndPoint } from "../../Api/Controllers/Common/EndPoints";
import FormattedDropdown from "../../GenericComponents/FormattedDropdown";
import FormattedTextBox from "../../GenericComponents/FormattedTextBox";
import PutPatient from "../../Api/Controllers/Patients/PutPatient";
import FillEntity from "../../GenericComponents/FillEntity";
import MultiSelect from "../../GenericComponents/MultiSelect";

const Entity = GetEntities();

const CoverageAttentionData = ({ patientId, userAllowed = true }) => {
  const [patient, setPatient] = useState({});
  const [selectedGeneralPractitioners, setSelectedGeneralPractitioners] =
    useState([]);
  const [refresh, setRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const form = useRef(null);

  const handleSaveClick = async (event) => {
    setIsLoading(true);
    const updatedPatient = {
      ...patient,
      GeneralPractitionersIds: selectedGeneralPractitioners,
    };
    try {
      setValidated(form.current.checkValidity() === false);
      if (form.current.checkValidity() === false) {
        event.stopPropagation();
      } else {
        setLoading(true);
        const key = { type: "guid", value: patientId };
        await PutPatient({ key }, updatedPatient);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
  };

  const fetchPatientData = async () => {
    try {
      setLoading(true);
      const key = { type: "guid", value: patientId };
      const patientApi = GetEndPoint(Entity.Paciente);
      const response = await patientApi({ key });
      const responsePatient = FillEntity(Entity.Paciente, response);
      setPatient(responsePatient);
      if (responsePatient.GeneralPractitionersIds) {
        setSelectedGeneralPractitioners(
          responsePatient.GeneralPractitionersIds
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPatientData();
    if (patient.GeneralPractitionersIds) {
      setSelectedGeneralPractitioners(patient.GeneralPractitionersIds);
    }
  }, [refresh]);

  return (
    <Container>
      <Card>
        <Card.Header>
          <Toolbar
            title="Datos de cobertura y atencion"
            showBackButton={false}
            showSaveButton={userAllowed}
            showNewButton={userAllowed}
            showDeleteButton={false}
            showRefreshButton={true}
            key="coverageattentiondata"
            disabled={isLoading}
            saveAction={handleSaveClick}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        </Card.Header>

        <Card.Body>
          <Form
            noValidate
            validated={validated}
            key="coverageattentiondata"
            ref={form}
          >
            <Container>
              <Row className="g-2">
                <Col md>
                  <FormattedDropdown
                    apiEndpoint={Entity.ObraSocial}
                    label="Obra social"
                    required={false}
                    name="GenericId"
                    value={patient.HealthInsuranceId}
                    onChange={(value) => {
                      setPatient((prevpatient) => ({
                        ...prevpatient,
                        HealthInsuranceId: value,
                      }));
                    }}
                    validated={false}
                    featureFillEntity={true}
                    disabled={!userAllowed}
                  />
                </Col>
                <Col md>
                  <FormattedTextBox
                    label="Numero de obra social"
                    type="text"
                    name="HealthInsuranceNumber"
                    value={patient.HealthInsuranceNumber}
                    handleChange={(value) => {
                      setPatient((prevpatient) => ({
                        ...prevpatient,
                        HealthInsuranceNumber: value.target.value,
                      }));
                    }}
                    required={false}
                    validated={false}
                    disabled={!userAllowed}
                  />
                </Col>
              </Row>
              <Row className="g-2 mb-3">
                <Col md>
                  <MultiSelect
                    featureFillEntity={true}
                    apiEndpoint={Entity.Profesional}
                    labelComp="Profesional tratante"
                    selectedOptions={selectedGeneralPractitioners}
                    setSelectedOptions={setSelectedGeneralPractitioners}
                    onSelectionChange={(newSelectedOptions) => {
                      setPatient((prevPatient) => ({
                        ...prevPatient,
                        GeneralPractitionersIds: newSelectedOptions,
                      }));
                    }}
                    disabled={!userAllowed}
                  />
                </Col>
              </Row>
              <Row className="g-2">
                <Col md>
                  <FormattedDropdown
                    apiEndpoint={Entity.ModalidadAtencion}
                    label="Modalidad de atencion"
                    required={false}
                    name="CareModalityId"
                    value={patient.CareModalityId}
                    onChange={(newCareModalityId) => {
                      setPatient((prevpatient) => ({
                        ...prevpatient,
                        CareModalityId: newCareModalityId,
                      }));
                    }}
                    disabled={!userAllowed}
                  />
                </Col>
              </Row>
            </Container>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CoverageAttentionData;
