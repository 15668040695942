import Put from "../../BaseApiController/Put"

const endPoint = "/api/Roles"

async function PutRole(queryObj, body) {
    const successMessage = "Rol modificado con exito.";
    const rol = await Put(endPoint, queryObj, body, null, successMessage)
    return rol
}

export default PutRole