import Put from "../../BaseApiController/Put"

const endPoint = "/api/Profiles"

async function PutProfile(queryObj, body) {
    const successMessage = "Perfil modificado con exito.";
    const profile = await Put(endPoint, queryObj, body, null, successMessage)
    return profile
}

export default PutProfile