import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Container, ListGroup } from "react-bootstrap";
import { Headset } from 'react-bootstrap-icons';
import TableSearch from '../TableSearch/TableSearch';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import GetEntities from '../GenericComponents/Entities';
import { GetEndPoint } from "../Api/Controllers/Common/EndPoints"
import PutAppointmentRegisterOnlineConnection from '../Api/Controllers/Appointments/PutRegisterOnlineConnection';
import './MyAppointment.css';
import FillEntity from '../GenericComponents/FillEntity';
import { useNavigate } from "react-router-dom";

const Entity = GetEntities()
dayjs.extend(utc);

const MyAppointment = () => {

    const [appointments, setAppointments] = useState([]);
    const navigate = useNavigate();

    const handleHeadsetClick = (schedule) => {
        const url = schedule.OnlineMeetingLink;
        window.open(url, '_blank');
        RegisterOnlineConnection(schedule.Id);
    };
    

    const fetchData = async () => {
        const apiMyAppointment = GetEndPoint(Entity.MisTurnosFuturos)
        const response = await apiMyAppointment()
        const futureAppointments = response.map(a => FillEntity(Entity.MisTurnosFuturos, a));
        const sortedAppointments = futureAppointments.sort((a, b) => {
            return dayjs.utc(a.appointmentDateTime).isAfter(dayjs.utc(b.appointmentDateTime)) ? 1 : -1;
        });
        setAppointments(sortedAppointments)
    }

    const RegisterOnlineConnection = async (appointmentId) =>{
        const key = { type: "guid", value: appointmentId };
        try {
          await PutAppointmentRegisterOnlineConnection(key, null);
        } catch (error) {
          console.log(error);
        }
      }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <Container>
            <Card>
                <Card.Header>
                    <h2>Mis Turnos</h2>
                </Card.Header>
                <Card.Body>
                    <Row className="g-2">
                        <ListGroup variant="flush" className="w-100">
                            <Row className="g-2">
                                <Col xs={12}>
                                    <h4>Mis proximos turnos</h4>
                                    <hr />
                                </Col>
                            </Row>
                            <div className="scrollable-container">
                                {appointments.length === 0 ?
                                    <h5>No tiene turnos proximamente</h5> :
                                    appointments.map((schedule, index) => (
                                        <div key={index} className="appointment-card">
                                            <Card className="mb-2">
                                                <div className="custom-header">
                                                    <h5>Fecha y hora: <strong>{dayjs.utc(schedule.AppointmentDateTime).format('DD/MM/YYYY HH:mm')}</strong></h5>
                                                </div>
                                                <Card.Body>
                                                    <Row className="d-flex align-items-center">
                                                        <Col xs={12} sm={6} md={3}>
                                                            <Card.Text>Profesional: <strong>{schedule.ProfessionalName || "NO INGRESADO"}</strong></Card.Text>
                                                            <Card.Text>Sede: <strong>{schedule.SiteName || "NO INGRESADO"}</strong></Card.Text>
                                                        </Col>
                                                        <Col xs={12} sm={6} md={3}>
                                                            <Card.Text>Asistencia: <strong>{schedule.AssistanceName || "NO INGRESADO"}</strong></Card.Text>
                                                            <Card.Text>Modalidad de atencion: <strong>{schedule.CareModalityName || "NO INGRESADO"}</strong></Card.Text>
                                                        </Col>
                                                        <Col xs={12} sm={6} md={3}>
                                                            <Card.Text>Prestacion: <strong>{schedule.ServiceName || "NO INGRESADO"}</strong></Card.Text>
                                                            <Card.Text>Consultorio: <strong>{schedule.RoomName || "NO INGRESADO"}</strong></Card.Text>
                                                        </Col>
                                                        {schedule.IsOnline && (
                                                            <Col xs={12} sm={6} md="auto" className="d-flex justify-content-end align-items-center">
                                                                <Headset
                                                                    size={20}
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => handleHeadsetClick(schedule)}
                                                                />
                                                                <button className="btn btn-primary ml-2" onClick={() => handleHeadsetClick(schedule)}>
                                                                    Conectar
                                                                </button>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    ))
                                }
                            </div>
                        </ListGroup>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={12}>
                            <hr />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={12}>
                            <h4>Mis turnos pasados</h4>
                            <hr />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={12}>
                            <TableSearch
                                userType={Entity.MisTurnosPasados}
                                checkboxSelection={false}
                                columnClickEnabled={false}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card >
        </Container >
    );
}

export default MyAppointment