import { Container, Card, Row, Col, Button, Spinner } from "react-bootstrap";
import FormattedTextBox from "../GenericComponents/FormattedTextBox";
import { Headphones, EyeSlash, Eye } from "react-bootstrap-icons";
import CardTableSearch from "../TableSearch/CardTableSearch";
import Toolbar from "../GenericComponents/ToolBar";
import { useState, useEffect } from "react";
import GetEntities from "../GenericComponents/Entities";
import {
  GetEndPoint,
  PutEndPoint,
  PostEndPoint,
} from "../Api/Controllers/Common/EndPoints";
import SuccessEvent from "../GenericComponents/SuccessEvent";
import FillEntity from "../GenericComponents/FillEntity";
import { useUnsavedChanges } from "../Context/UnsavedChangesContext";
import ZoomDetails from "../../Models/Zoom/ZoomDetails";
import AttachmentFiles from "../GenericComponents/AttachmentFiles";
import PostUploadedFiles from "../Api/Controllers/UploadedFiles/PostUploadedFiles";
import DeleteUploadedFiles from "../Api/Controllers/UploadedFiles/DeleteUploadedFiles";
import PhoneNumberValidator from "../GenericComponents/PhoneNumberValidator";

const Entities = GetEntities();

function ConfigurationPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [zoomClientId, setZoomClientId] = useState("");
  const [zoomClientSecret, setZoomClientSecret] = useState("");
  const [zoomSdkKey, setZoomSdkKey] = useState("");
  const [zoomSdkSecret, setZoomSdkSecret] = useState("");
  const { setHasUnsavedChanges } = useUnsavedChanges();
  const [account, setAccount] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [showLCPrivateKey, setShowLCPrivateKey] = useState(false);
  const [showZoomClientSecret, setShowZoomClientSecret] = useState(false);
  const [showZoomSdkSecret, setShowZoomSdkSecret] = useState(false);

  const [attachmentFile, setAttachmentFile] = useState([]);
  const [woSavingAttachmentFiles, setWoSavingAttachmentFiles] = useState([]);
  const [deleteAttachmentFiles, setDeleteAttachmentFiles] = useState([]);

  const fetchData = async () => {
    const accountData = GetEndPoint(Entities.Cuenta);
    const response = await accountData();
    if (response && !response.error) {
      setAccount(FillEntity(Entities.Cuenta, response));
    }
  };

  const saveAttachments = async (id) => {
    const obj = { relatedEntityId: id, uploadedFileType: 3 };
    let formData = new FormData();
    woSavingAttachmentFiles.map((file) => {
      return formData.append("files", file);
    });
    await PostUploadedFiles(obj, formData);
    setWoSavingAttachmentFiles([]);
    setRefresh(!refresh);
    setHasUnsavedChanges(false);
  };
  const deleteAttachments = () => {
    deleteAttachmentFiles.map(async (file) => {
      const obj = { relatedEntityId: file };
      await DeleteUploadedFiles(obj);
    });
  };

  const handleInputChange = (e, key) => {
    setAccount((prevState) => ({ ...prevState, [key]: e.target.value }));
    setHasUnsavedChanges(true);
  };

  const toggleShowLCPrivateKey = () => {
    setShowLCPrivateKey(!showLCPrivateKey);
  };

  const toggleShowZoomClientSecret = () => {
    setShowZoomClientSecret(!showZoomClientSecret);
  };

  const toggleShowZoomSdkSecret = () => {
    setShowZoomSdkSecret(!showZoomSdkSecret);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const key = { type: "guid", value: account.Id };
      const api = PutEndPoint(Entities.Cuenta);
      await api({ key }, account);
      if (woSavingAttachmentFiles) {
        await saveAttachments(account.Id);
      }
      if (deleteAttachmentFiles) {
        deleteAttachments();
      }
      SuccessEvent("Configuracion guardada con exito");
      setHasUnsavedChanges(false);
      setRefresh(!refresh);
    } catch (error) {
      console.error("Error saving account:", error);
    }
    setTimeout(() => setIsLoading(false), 1000);
    setIsLoading(false);
  };

  const handleConnectClick = async () => {
    const postZoomDetails = PostEndPoint(Entities.CuentasZoom);

    let zoomDetailsBody = new ZoomDetails(
      zoomClientId,
      zoomClientSecret,
      zoomSdkKey,
      zoomSdkSecret
    );

    const response = await postZoomDetails(zoomDetailsBody);

    if (response && !response.error) {
      window.location.replace(response.ZoomUrl);
    } else {
      console.error("Error adding Zoom configuration:", response.error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [refresh]);

  if (isLoading) {
    return <Spinner animation="border" variant="secondary" />;
  }

  return (
    <Container>
      <Toolbar
        title={"Configuracion"}
        entity={Entities.Cuenta}
        saveAction={handleSave}
        showBackButton={false}
        showSaveButton={true}
        disabled={false}
        refresh={refresh}
        setRefresh={setRefresh}
        showRefreshButton={true}
      />
      <hr />
      <Card className="mb-4">
        <Card.Header>
          <h2>Datos Generales</h2>
        </Card.Header>
        <Card.Body>
          <div className="mt-3">
            <Row className="g-2">
              <Col>
                <FormattedTextBox
                  label="Nombre"
                  type="text"
                  placeholder=""
                  name="name"
                  value={account.Name}
                  disabled={true}
                />
              </Col>
              <Col>
                <FormattedTextBox
                  label="URL"
                  type="text"
                  placeholder=""
                  name="name"
                  value={account.Url}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row className="g-2">
              <Col>
                <FormattedTextBox
                  label="Numero de Telefono"
                  type="text"
                  placeholder=""
                  name="PhoneNumber"
                  value={account.PhoneNumber}
                  disabled={false}
                  handleChange={(e) => handleInputChange(e, "PhoneNumber")}
                />
              </Col>
              <Col>
                <FormattedTextBox
                  label="Email"
                  type="Email"
                  placeholder=""
                  name="Email"
                  value={account.Email}
                  disabled={false}
                  handleChange={(e) => handleInputChange(e, "Email")}
                />
              </Col>
            </Row>
            <Row className="g-2">
              <Col>
                <FormattedTextBox
                  label="Web Site Url"
                  type="text"
                  placeholder=""
                  name="WebSiteUrl"
                  value={account.WebSiteUrl}
                  disabled={false}
                  handleChange={(e) => handleInputChange(e, "WebSiteUrl")}
                />
              </Col>
              <Col>
                <FormattedTextBox
                  label="Entidad Legal"
                  type="text"
                  placeholder=""
                  name="LegalName"
                  value={account.LegalName}
                  disabled={false}
                  handleChange={(e) => handleInputChange(e, "LegalName")}
                />
              </Col>
            </Row>
            <Row className="g-2">
              <FormattedTextBox
                label="Direcciones"
                type="textarea"
                placeholder=""
                name="FullAddressInformation"
                value={account.FullAddressInformation}
                disabled={false}
                handleChange={(e) =>
                  handleInputChange(e, "FullAddressInformation")
                }
              />
            </Row>
          </div>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Header>
          <h2>Whatsapp</h2>
        </Card.Header>
        <Card.Body>
          <div className="mt-3">
            <Row className="g-2">
              <Col>
                <FormattedTextBox
                  label="Live Connect Access Key"
                  type="text"
                  placeholder=""
                  name="SettingLiveConnectAccessKey"
                  value={account.SettingLiveConnectAccessKey}
                  disabled={false}
                  handleChange={(e) =>
                    handleInputChange(e, "SettingLiveConnectAccessKey")
                  }
                />
              </Col>
              <Col>
                <FormattedTextBox
                  label="Live Connect Private Key"
                  type={showLCPrivateKey ? "text" : "password"}
                  placeholder=""
                  name="SettingLiveConnectPrivateKey"
                  value={account.SettingLiveConnectPrivateKey}
                  disabled={false}
                  handleChange={(e) =>
                    handleInputChange(e, "SettingLiveConnectPrivateKey")
                  }
                >
                  <Button
                    variant="link"
                    onClick={toggleShowLCPrivateKey}
                    style={{
                      color: "inherit",
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {showLCPrivateKey ? (
                      <EyeSlash className="mx-2" size={20} />
                    ) : (
                      <Eye className="mx-2" size={20} />
                    )}
                  </Button>
                </FormattedTextBox>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormattedTextBox
                  label="Live Connect Channel Id"
                  type="text"
                  placeholder=""
                  name="SettingLiveConnectChannelId"
                  value={account.SettingLiveConnectChannelId}
                  disabled={false}
                  handleChange={(e) =>
                    handleInputChange(e, "SettingLiveConnectChannelId")
                  }
                />
              </Col>
              <Col></Col>
            </Row>
          </div>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Header>
          <h2>Atencion virtual - Google Meet</h2>
        </Card.Header>
        <Card.Body>
          <div className="mt-3">
            <Row className="g-2">
              <Col>
                <FormattedTextBox
                  label="Credencial de Service Account"
                  as="textarea"
                  placeholder=""
                  name="name"
                  value={account.GoogleCloudServiceAccountCredential}
                  disabled={false}
                  handleChange={(e) =>
                    handleInputChange(e, "GoogleCloudServiceAccountCredential")
                  }
                />
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Header>
          <h2>Atencion virtual - Zoom</h2>
        </Card.Header>
        <Card.Body>
          <div className="mt-3">
            <Row className="g-2">
              <Col>
                <FormattedTextBox
                  label="Zoom Client Id"
                  type="text"
                  placeholder=""
                  name="name"
                  value={""}
                  disabled={false}
                  handleChange={(e) => setZoomClientId(e.target.value)}
                />
              </Col>
              <Col>
                <FormattedTextBox
                  label="Zoom Client Secret"
                  type={showZoomClientSecret ? "text" : "password"}
                  placeholder=""
                  name="name"
                  value={""}
                  disabled={false}
                  handleChange={(e) => setZoomClientSecret(e.target.value)}
                >
                  <Button
                    variant="link"
                    onClick={toggleShowZoomClientSecret}
                    style={{
                      color: "inherit",
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {showZoomClientSecret ? (
                      <EyeSlash className="mx-2" size={20} />
                    ) : (
                      <Eye className="mx-2" size={20} />
                    )}
                  </Button>
                </FormattedTextBox>
              </Col>
            </Row>
            <Row className="g-2">
              <Col>
                <FormattedTextBox
                  label="Zoom Sdk key"
                  type="text"
                  placeholder=""
                  name="name"
                  value={""}
                  disabled={false}
                  handleChange={(e) => setZoomSdkKey(e.target.value)}
                />
              </Col>
              <Col>
                <FormattedTextBox
                  label="Zoom Sdk Secret"
                  type={showZoomSdkSecret ? "text" : "password"}
                  placeholder=""
                  name="name"
                  value={""}
                  disabled={false}
                  handleChange={(e) => setZoomSdkSecret(e.target.value)}
                >
                  <Button
                    variant="link"
                    onClick={toggleShowZoomSdkSecret}
                    style={{
                      color: "inherit",
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {showZoomClientSecret ? (
                      <EyeSlash className="mx-2" size={20} />
                    ) : (
                      <Eye className="mx-2" size={20} />
                    )}
                  </Button>
                </FormattedTextBox>
              </Col>
              <Col xs={2} style={{ paddingTop: "7px", paddingRight: "20px" }}>
                <Button variant="success" onClick={handleConnectClick}>
                  <Headphones title="Add" />
                </Button>
                <b style={{ marginLeft: "12px" }}>Conectar</b>
              </Col>
            </Row>
            <Row>
              <CardTableSearch
                entity={Entities.CuentasZoom}
                key={Entities.CuentasZoom}
                showNewButton={false}
              />
            </Row>
          </div>
        </Card.Body>
      </Card>
      <Row>
        <AttachmentFiles
          userId={account.Id}
          attachmentFiles={attachmentFile}
          setAttachmentFiles={setAttachmentFile}
          woSavingAttachmentFiles={woSavingAttachmentFiles}
          setWoSavingAttachmentFiles={setWoSavingAttachmentFiles}
          deleteAttachmentFiles={deleteAttachmentFiles}
          setDeleteAttachmentFiles={setDeleteAttachmentFiles}
          refresh={refresh}
          uploadedFileType={3}
          singleAttachment={true}
        />
      </Row>
    </Container>
  );
}

export default ConfigurationPage;
