import Post from "../../BaseApiController/Post"

const endPoint = "/api/Relationships"

async function PostRelationship(body) {
    const successMessage = "Parentezco creado con exito.";
    const relationship = await Post(endPoint, body, null, successMessage)
    return relationship
}

export default PostRelationship