import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

function PhoneNumberValidator({ label, name, value = "", handleChange, required = false, disabled = false, isCellPhone = false }) {
  const [inputValue, setInputValue] = useState(value);
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [codeCountry, setCodeCountry] = useState('');

  const codeCountryMessageCellPhone = 'Por favor ingrese el codigo del pais (549 - ARG) sin el "+".';
  const codeCountryPhone = 'AR';
  const errorMessageInvalidNumber = 'Por favor ingrese un número válido.';

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setCodeCountry(codeCountryMessageCellPhone);
    setInputValue(newValue);
    handleChange(event);
  };

  const handleInputBlur = (event) => {
    const newValue = event.target.value;
    setCodeCountry('');

    if (newValue === '') {
      setIsValid(true);
      setErrorMessage('');
      return;
    }

    const phoneNumber = parsePhoneNumberFromString(newValue, codeCountryPhone);
    const validPhone = phoneNumber ? phoneNumber.isValid() : false;
    setIsValid(validPhone);
    if (!validPhone) {
      setErrorMessage(errorMessageInvalidNumber);
    }
  };

  return (
    <Form.FloatingLabel label={label}>
      <Form.Control
        isInvalid={!isValid}
        type="tel"
        name={name}
        placeholder={label}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        required={required}
        disabled={disabled}
      />
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
      <Form.Text className="text-success">
        {inputValue !== '' && errorMessage === '' && isCellPhone ? codeCountry : ''}
      </Form.Text>
    </Form.FloatingLabel>
  );
}

export default PhoneNumberValidator;
