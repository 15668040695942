import Swal from "sweetalert2";

const DeleteWarning = (deleteAction) => {
    const alertConfig = {
        showConfirmButton: true,
        width: 500,
        position: "center",
        icon: "question",
        title: "¿Estás seguro que querés eliminar?",
        confirmButtonColor: "red",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Eliminar"
    };
    
    Swal.fire(alertConfig).then((result) => {
        if (result.isConfirmed) {
            deleteAction()
        }
    })
}

export default DeleteWarning