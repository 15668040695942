import Post from "../../BaseApiController/Post"

const endPoint = "/api/Security/ResetPassword/{userId}"

async function PostResetPassword(userId) {
    let assembledEndPoint = endPoint.replace("{userId}", userId);
    const successMessage = "Contraseña reestablecida con exito.";
    const response = await Post(assembledEndPoint, null, null, successMessage)
    return response
}

export default PostResetPassword