import Post from "../../BaseApiController/Post"

const endPoint = "/api/Profiles"

async function PostProfile(body) {
    const successMessage = "Perfil creado con exito.";
    const profile = await Post(endPoint, body, null, successMessage)
    return profile
}

export default PostProfile