import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Form, FormLabel, Container, Row, Col, Card } from "react-bootstrap";
import GetRole from "../Api/Controllers/Roles/GetRole";
import GetProfile from "../Api/Controllers/Profiles/GetProfile";
import PutProfile from "../Api/Controllers/Profiles/PutProfile";
import PostProfile from "../Api/Controllers/Profiles/PostProfile";
import DeleteProfile from "../Api/Controllers/Profiles/DeleteProfile";
import { useNavigate } from "react-router-dom";
import FormattedTextBox from "../GenericComponents/FormattedTextBox";
import Toolbar from "../GenericComponents/ToolBar";
import GetEntities from "../GenericComponents/Entities";
import { EditEntityPath, SearchPath } from "../Api/Controllers/Common/Paths";
import DeleteWarning from "../GenericComponents/DeleteWarning";
import { UserOperationsAllowedContext } from "../Dashboard/UserOperationsAllowedContext";

const entity = GetEntities().Perfil;
const editPath = EditEntityPath(entity);
const searchPath = SearchPath(entity);

function Profile({ requiredOperations = [] }) {
  const [profileName, setProfileName] = useState("");
  const [checkedRoles, setCheckedRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [validated, setValidated] = useState(false);
  const form = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const { userOperationsAllowed, hasAllRequiredOperations } = useContext(
    UserOperationsAllowedContext
  );

  let { profileId } = useParams();
  let navigate = useNavigate();

  const fetchProfileData = async () => {
    const queryObj = {
      key: { type: "guid", value: profileId },
      expand: ["roles"],
    };
    const response = await GetProfile(queryObj);
    setProfileName(response.Name);
    const initalRoles = response.Roles.map((obj) => obj.Id);
    setCheckedRoles(initalRoles);
  };

  const fetchRolesData = async () => {
    const responseRole = await GetRole();
    setRoles(responseRole.value);
  };

  useEffect(() => {
    if (profileId !== undefined) {
      fetchProfileData();
    }
    setValidated(false);
    fetchRolesData();
  }, [profileId, refresh]);

  const handleProfileNameChange = (e) => {
    setProfileName(e.target.value);
  };

  const handleSaveClick = async (event) => {
    setIsLoading(true);
    setValidated(form.current.checkValidity() === false);
    if (form.current.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const body = { name: profileName, roleIds: checkedRoles };
      if (profileId !== undefined) {
        const key = { type: "guid", value: profileId };
        await PutProfile({ key }, body);
      } else {
        const newProfile = await PostProfile(body);
        const id = newProfile.Id;
        if (newProfile) {
          navigate(`${editPath}${id}`, { state: { id: id } });
        }
      }
    }
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
  };

  const handleCheckboxChange = (e) => {
    const id = e.target.id;
    if (e.target.checked) {
      setCheckedRoles([...checkedRoles, id]);
    } else {
      setCheckedRoles(checkedRoles.filter((ruleId) => ruleId !== id));
    }
  };

  const deleteProfile = async () => {
    const key = { type: "guid", value: profileId };
    await DeleteProfile({ key });
    navigate(searchPath);
    profileId = undefined;
    setRefresh(!refresh);
  };

  const handleSelectAllRoles = (selectAll) => {
    if (selectAll) {
      setCheckedRoles(roles.map((role) => role.Id));
    } else {
      setCheckedRoles([]);
    }
  };

  const userAllowed = hasAllRequiredOperations({
    requiredOperations,
    userOperations: userOperationsAllowed,
  });

  return (
    <Container>
      <Card>
        <Card.Header>
          {!profileId ? (
            <Toolbar
              title="Crear Perfil"
              entity={entity}
              saveAction={handleSaveClick}
              setRefresh={setRefresh}
              refresh={refresh}
              showBackButton={true}
              showSaveButton={userAllowed}
              disabled={isLoading}
            />
          ) : (
            <Toolbar
              title="Editar Perfil"
              entity={entity}
              deleteAction={() => DeleteWarning(deleteProfile)}
              saveAction={handleSaveClick}
              setRefresh={setRefresh}
              refresh={refresh}
              showBackButton={true}
              showSaveButton={userAllowed}
              showNewButton={userAllowed}
              showDeleteButton={userAllowed}
              showRefreshButton={true}
              disabled={isLoading}
            />
          )}
        </Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} key="formProfile" ref={form}>
            <FormattedTextBox
              label="Nombre"
              type="text"
              name="name"
              value={profileName}
              handleChange={handleProfileNameChange}
              required={true}
              validated={validated}
              disabled={!userAllowed}
            />
            <Form.Group as={Row}>
              <Col>
                <FormLabel>Roles</FormLabel>
                {roles.length > 0 && (
                  <Form.Check
                    type="checkbox"
                    label={
                      checkedRoles.length === roles.length
                        ? "Deseleccionar todo"
                        : "Seleccionar todo"
                    }
                    checked={checkedRoles.length === roles.length}
                    onChange={() =>
                      handleSelectAllRoles(checkedRoles.length !== roles.length)
                    }
                    disabled={!userAllowed}
                  />
                )}
                {roles.map((rol) => (
                  <Form.Check
                    key={rol.Id}
                    type="checkbox"
                    label={rol.Name}
                    id={rol.Id}
                    checked={checkedRoles.includes(rol.Id)}
                    onChange={handleCheckboxChange}
                    disabled={!userAllowed}
                  />
                ))}
              </Col>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Profile;
