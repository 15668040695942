import Put from "../../BaseApiController/Put"

const endPoint = "/api/Users"

async function PutUser(queryObj, body) {
    const successMessage = "Usuario modificado con exito.";
    const response = await Put(endPoint, queryObj, body, null, successMessage)
    return response
}

export default PutUser