export class GetProfessionalsForAppointmentsRequest {
    constructor(SpecialtyId, ServicesRequired) {
        this.SpecialtyId = SpecialtyId;
        this.ServicesRequired = ServicesRequired;
    }
}

export class GetProfessionalsForAppointmentsResponse {
    constructor(Id, Name, LastName, IsWorking) {
        this.Id = Id;
        this.Name = Name;
        this.LastName = LastName;
        this.IsWorking = IsWorking;
    }
}
