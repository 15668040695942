import React from 'react';
import { Button, Card, Form } from "react-bootstrap";
import { Save2Fill, XCircle } from "react-bootstrap-icons";

const ScheduleModal = ({ handleSaveClick, handleModalClose, schedule, text, setText }) => {
  return (
    <Card>
      <Card.Header>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Cargar Evolucion</h2>
          <div>
            <Button
              variant="success"
              onClick={handleSaveClick}
              style={{ marginRight: "2px" }}
            >
              <Save2Fill title="Save" />
            </Button>
            <Button variant="danger" onClick={handleModalClose}>
              <XCircle title="Close" />
            </Button>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <Form.Control
          as="textarea"
          style={{ height: "100px" }}
          value={text[schedule.Id] || ""}
          onChange={(e) =>
            setText({ ...text, [schedule.Id]: e.target.value })
          }
        />
      </Card.Body>
    </Card>
  );
};

export default ScheduleModal;
