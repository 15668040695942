import axios from '../components/Api/BaseApiController/axios';
import jwtDecode from 'jwt-decode';

const API_URL = "/api/Security/RefreshToken";

class TokenService {
  constructor() {
    this.isRefreshing = false;
    this.refreshSubscribers = [];
  }

  getAccessToken() {
    return JSON.parse(localStorage.getItem("accessToken"));
  }

  getRefreshToken() {
    return JSON.parse(localStorage.getItem("refreshToken"));
  }

  async refreshTokens() {
    let refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }
    const response = await axios.post(API_URL, { refreshToken });
    let accessToken = response.data.accessToken;
    refreshToken = response.data.refreshToken;
    localStorage.setItem("accessToken", JSON.stringify(accessToken));
    localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
    this.isRefreshing = false;
    this.onRrefreshed(accessToken);
  }

  tokenIsValid() {
    const accessToken = this.getAccessToken();
    if (!accessToken) return false;

    const decodedToken = jwtDecode(accessToken);
    return decodedToken.exp * 1000 > new Date().getTime();
  }

  async initAuth() {
    if (this.tokenIsValid()) {
      return true;
    } else {
      try {
        await this.refreshTokens();
        return true;
      } catch (error) {
        this.clearTokens();
        throw error;
      }
    }
  }


  clearTokens() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  subscribeTokenRefresh(cb) {
    this.refreshSubscribers.push(cb);
  }

  onRrefreshed(token) {
    this.refreshSubscribers.map(cb => cb(token));
    this.refreshSubscribers = [];
  }
}

export default new TokenService();
