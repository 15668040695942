import Post from "../../BaseApiController/Post"

const endPoint = "/api/Towns"

async function PostTown(body) {
    const successMessage = "Ciudad creada con exito.";
    const town = await Post(endPoint, body, null, successMessage)
    return town
}

export default PostTown