class Appointments {
    constructor(PatientId, ProfessionalId, CareModalityId, ServiceId, AppointmentDateTime,
        SiteId, AssistanceId, IsOnline, RoomId, HealthInsuranceId, CoPaymentCostPaid, HealthInsuranceNumber) {
        this.PatientId = PatientId;
        this.ProfessionalId = ProfessionalId;
        this.CareModalityId = CareModalityId;
        this.ServiceId = ServiceId;
        this.AppointmentDateTime = AppointmentDateTime;
        this.SiteId = SiteId;
        this.AssistanceId = AssistanceId;
        this.IsOnline = IsOnline;
        this.RoomId = RoomId;
        this.HealthInsuranceId = HealthInsuranceId;
        this.HealthInsuranceNumber = HealthInsuranceNumber;
        this.CoPaymentCostPaid = CoPaymentCostPaid;
    }
}

export default Appointments