import Post from "../../BaseApiController/Post"

const endPoint = "/api/Provinces"

async function PostProvince(body) {
    const successMessage = "Provincia creada con exito.";
    const province = await Post(endPoint, body, null, successMessage)
    return province
}

export default PostProvince