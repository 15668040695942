import Post from "../../BaseApiController/Post"

const endPoint = "/api/Rooms"

async function PostRooms(body) {
    const successMessage = "Consultorio creado con exito.";
    const response = await Post(endPoint, body, null, successMessage)
    return response
}

export default PostRooms