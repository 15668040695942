import Post from "../../BaseApiController/Post"

const endPoint = "/api/ProfessionalSchedules"

async function PostProfessionalSchedule(body) {
    const successMessage = "Agenda del profesional creada con exito.";
    const professionalSchedule = await Post(endPoint, body, null, successMessage)
    return professionalSchedule
}

export default PostProfessionalSchedule