import React, { useState } from "react";
import { Form, FloatingLabel, Button, Row, Col } from "react-bootstrap";
import { PlusSquare } from "react-bootstrap-icons";
import Popup from "./Popup";
import { useUnsavedChanges } from "../Context/UnsavedChangesContext";

const FormattedDropdownWithButton = ({
  dropdownOptions,
  label,
  required,
  name,
  onChange,
  selected,
  entity,
  setRefresh,
  refresh,
  popupentity,
  disabled = false,
  parentId = null,
}) => {
  const [popUpShow, setPopUpShow] = useState(false);
  const sortedDropdownOptions = [...dropdownOptions].sort((a, b) =>
    a.Name.localeCompare(b.Name)
  );
  const { setHasUnsavedChanges } = useUnsavedChanges();
  const emptyOption = { Id: null, Name: " " };

  const handleDropdownChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      return;
    }
    setHasUnsavedChanges(true);
    onChange(e);
  };

  const handleClosePopup = () => {
    setHasUnsavedChanges(false);
    setPopUpShow(false);
    setRefresh(!refresh);
  };

  return (
    <Row>
      <Col>
        <FloatingLabel
          controlId="floatingSelect"
          label={label}
        >
          <Form.Select
            aria-label="Floating label select example"
            required={required}
            onChange={handleDropdownChange}
            name={name}
            value={selected || ""}
            disabled={disabled}
          >
            <option key={0} value={emptyOption.Id}>
              {emptyOption.Name}
            </option>
            {sortedDropdownOptions.map((opt) => (
              <option key={opt.Id} value={opt.Id}>
                {opt.Name}
              </option>
            ))}
          </Form.Select>
        </FloatingLabel>
      </Col>
      <Col xs="auto" className="d-flex align-items-center justify-content-center">
        {!disabled && (
          <Button variant="success" onClick={() => setPopUpShow(true)}>
            <PlusSquare title="Add" />
          </Button>
        )}
      </Col>
      <Popup
        show={popUpShow}
        onHide={handleClosePopup}
        popupentity={popupentity}
        label={label}
        entity={entity}
      />
    </Row>
  );
};

export default FormattedDropdownWithButton;
