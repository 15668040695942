class HealthInsurance {
    constructor(Id, Name, Plan, ElectronicPrescriptionProvider) {
        this.Id = Id;
        this.Plan = Plan;
        this.Name = Name;
        this.ElectronicPrescriptionProvider = ElectronicPrescriptionProvider;
    }

    getName() {
        return `${this.Name} - ${this.Plan}`;
    }
}

export default HealthInsurance