import Put from "../../BaseApiController/Put"

const endPoint = "/api/Countries"

async function PutCountry(queryObj, body) {
    const successMessage = "Pais modificado con exito.";
    const country = await Put(endPoint, queryObj, body, null, successMessage)
    return country
}

export default PutCountry