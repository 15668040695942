import Post from "../../BaseApiController/Post"

const endPoint = "/api/Appointments"

async function PostAppointment(body) {
    const successMessage = "El turno ha sido creado con exito";
    const response = await Post(endPoint, body, null, successMessage)
    return response
}

export default PostAppointment