import Put from "../../BaseApiController/Put"

const endPoint = "/api/Appointments/RegisterOnlineConnection/{id}"

async function PutAppointmentRegisterOnlineConnection(queryObj, body) {
    let assembledEndPoint = endPoint.replace("{id}", queryObj.value, body);
    const headers = { 'Content-Type': 'application/json' };
    const response = await Put(assembledEndPoint, queryObj, body, headers, null);
    return response;
}

export default PutAppointmentRegisterOnlineConnection
