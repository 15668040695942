import React, { useState } from 'react';
import { PopupContext } from './PopupContext';

export const PopupProvider = ({ children }) => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  return (
    <PopupContext.Provider value={{ isPopupOpen, setPopupOpen }}>
      {children}
    </PopupContext.Provider>
  );
};