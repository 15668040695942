import Put from "../../BaseApiController/Put"

const endPoint = "/api/Relationships"

async function PutRelationship(queryObj, body) {
    const successMessage = "Parentezco modificado con exito.";
    const relationship = await Put(endPoint, queryObj, body, null, successMessage)
    return relationship
}

export default PutRelationship