import Get from "../../BaseApiController/Get"

const endPoint = "/api/Appointments/Selfservice/GetGeneralPractitionerSchedule/{date}/{generalPractitionerId}"

async function GetGeneralPractitionerSchedule(date, generalPractitionerId) {
    let ensambledEndPoint = endPoint.replace("{date}", date).replace("{generalPractitionerId}", generalPractitionerId);
    const response =  await Get(ensambledEndPoint)
    return response
}

export default GetGeneralPractitionerSchedule