import Post from "../../BaseApiController/Post"

const endPoint = "/api/Patients"

async function PostPatient(body) {
    const successMessage = "Paciente creado con exito.";
    const patient = await Post(endPoint, body, null, successMessage)
    return patient
}

export default PostPatient