import React from 'react';
import './App.css';
import Dashboard from './components/Dashboard/Dashboard';
import Login from './components/Login/Login';
import { PopupProvider } from './components/Dashboard/PopupProvider';
import { UserOperationsAllowedProvider } from './components/Dashboard/UserOperationsAllowedProvider';
import { Spinner } from 'react-bootstrap';
import { UnsavedChangesProvider } from './components/Context/UnsavedChangesContext';
import { AuthProvider, useAuth } from './hooks/useAuth';

function App() {
  return (
    <AuthProvider>
      <MainContent />
    </AuthProvider>
  );
}

const MainContent = () => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return (
      <div className="App">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <div className="App">
      {isAuthenticated ? (
        <UnsavedChangesProvider>
          <UserOperationsAllowedProvider>
            <PopupProvider>
              <Dashboard />
            </PopupProvider>
          </UserOperationsAllowedProvider>
        </UnsavedChangesProvider>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default App;
