import Swal from "sweetalert2";

function HandleError(error) {
  let customMessage;

  let alertConfig = {
    showConfirmButton: true,
    width: 500,
    position: "center",
    icon: "error"
  };

  if (error.response) {
    switch (error.response.status) {
      
      case 401:
        customMessage = "Su sesion ha expirado, por favor vuelva a ingresar."
        alertConfig = { ...alertConfig, title: customMessage };
        Swal.fire(alertConfig).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            setTimeout(function () { window.location.href = "/" }, 1000);
          }
        })
        break;

      case 500:
        const headers = error.response.headers
        const code = headers["x-correlation-id"]
        customMessage = `Ha ocurrido un error, por favor pongase en contacto con Alammoit y de el siguiente codigo para encontrar el error:<br/><u>${code}</u>`
        alertConfig = { ...alertConfig, title: customMessage, showDenyButton: true, denyButtonText: 'Copiar codigo', denyButtonColor: "blue", confirmButtonColor: "green" };
        Swal.fire(alertConfig).then((result) => {
          if (result.isDenied) {
            navigator.clipboard.writeText(code);
            customMessage = "Se ha copiado el codigo en el portapapeles"
            alertConfig = { ...alertConfig, title: customMessage, showConfirmButton: false, icon: "success", showDenyButton: false };
            Swal.fire(alertConfig);
          }
          localStorage.clear();
          setTimeout(function () { window.location.href = "/" }, 1000);
        })
        break;

      case 400:
        customMessage = error.response.data.Message
        alertConfig = { ...alertConfig, title: customMessage };
        Swal.fire(alertConfig);
        break;

      default:
        break;

    }
  } else if (error.request) {
    customMessage = "Ha ocurrido un error al mandar la solicitud, por favor vuelva a intentarlo"
    alertConfig = { ...alertConfig, title: customMessage };
    Swal.fire(alertConfig);
  }
}

export default HandleError;