import Put from "../../BaseApiController/Put"

const endPoint = "/api/Patients"

async function PutPatient(queryObj, body) {
    const successMessage = "Paciente modificado con exito.";
    const patient = await Put(endPoint, queryObj, body, null, successMessage)
    return patient
}

export default PutPatient