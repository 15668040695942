import React, { useEffect, useState, useRef } from "react";
import { Form, Container, Spinner, Card, Row, Col } from "react-bootstrap";
import Toolbar from "../../GenericComponents/ToolBar";
import DeleteProfessionalSchedule from "../../Api/Controllers/Professional/DeleteProfessionalSchedule";
import PostProfessionalSchedule from "../../Api/Controllers/Professional/PostProfessionalSchedule";
import PutProfessionalSchedule from "../../Api/Controllers/Professional/PutProfessionalSchedule";
import GetProfessionalSchedule from "../../Api/Controllers/Professional/GetProfessionalSchedule";
import DayTimePicker from "../../GenericComponents/DayTimePicker";
import GetEntities from "../../GenericComponents/Entities";
import DeleteWarning from "../../GenericComponents/DeleteWarning";
import FormattedDropdown from "../../GenericComponents/FormattedDropdown";
import FillEntity from "../../GenericComponents/FillEntity";
import ProfesionalSchedule from "../../../Models/Profesional/ProfesionalSchedule";
import MultiSelect from "../../GenericComponents/MultiSelect";

const scheduleEntity = GetEntities().Agenda;
const Entity = GetEntities();

const getEmptyProfessionalSchedule = (professionalId) => {
  return new ProfesionalSchedule(
    "",
    professionalId,
    1,
    "00:00:00",
    "00:00:00",
    "",
    "",
    [],
    ""
  );
};

const FormProfessionalSchedule = ({
  entityId,
  professionalId,
  action,
  userAllowed,
}) => {
  const [loading, setLoading] = useState(false);
  const [professionalScheduleId, setProfessionalScheduleId] =
    useState(entityId);
  const [professionalSchedule, setProfessionalSchedule] = useState(
    getEmptyProfessionalSchedule(professionalId)
  );
  const [refresh, setRefresh] = useState(false);
  const [validated, setValidated] = useState(false);
  const form = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectServices, setSelectServices] = useState([]);

  const fetchProfessionalSchedule = async () => {
    try {
      setLoading(true);
      const key = { type: "guid", value: professionalScheduleId };
      const json = await GetProfessionalSchedule({ key });
      const professionalScheduleResponse = FillEntity(Entity.Agenda, json);
      setSelectServices(professionalScheduleResponse.ServiceIds);
      setProfessionalSchedule(professionalScheduleResponse);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteProfessionalSchedule = async () => {
    const key = { type: "guid", value: entityId };
    await DeleteProfessionalSchedule({ key });
    setProfessionalScheduleId(undefined);
    entityId = undefined;
    action();
    setRefresh(!refresh);
  };

  const handleSaveClick = async (event) => {
    setIsLoading(true);
    try {
      setValidated(form.current.checkValidity() === false);
      if (form.current.checkValidity() === false) {
        event.stopPropagation();
      } else {
        setLoading(true);
        if (professionalScheduleId !== undefined) {
          const key = { type: "guid", value: professionalScheduleId };
          await PutProfessionalSchedule({ key }, professionalSchedule);
        } else {
          const newProfessionalSchedule = await PostProfessionalSchedule(
            professionalSchedule
          );
          if (newProfessionalSchedule) {
            setProfessionalScheduleId(professionalSchedule.Id);
            setProfessionalSchedule(professionalSchedule);
            setRefresh(!refresh);
          }
        }
      }
      action();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (professionalScheduleId !== undefined) {
      fetchProfessionalSchedule();
    } else {
      setProfessionalSchedule(getEmptyProfessionalSchedule(professionalId));
    }
  }, [refresh]);

  if (loading) {
    return <Spinner animation="border" variant="secondary" />;
  }

  return (
    <Container>
      <Card>
        <Card.Header>
          {!professionalScheduleId ? (
            <Toolbar
              title="Crear Agenda"
              entity={scheduleEntity}
              saveAction={handleSaveClick}
              setRefresh={setRefresh}
              refresh={refresh}
              showSaveButton={userAllowed}
              showCloseButton={true}
              actionClose={action}
              disabled={isLoading}
            />
          ) : (
            <Toolbar
              title="Editar Agenda"
              entity={scheduleEntity}
              deleteAction={() => DeleteWarning(deleteProfessionalSchedule)}
              saveAction={handleSaveClick}
              setRefresh={setRefresh}
              refresh={refresh}
              showSaveButton={userAllowed}
              showDeleteButton={userAllowed}
              showRefreshButton={true}
              showCloseButton={true}
              actionClose={action}
              disabled={isLoading}
            />
          )}
        </Card.Header>
        <Card.Body>
          <Row className="g-2  mb-3">
            <Form
              noValidate
              validated={validated}
              key="formSchedule"
              ref={form}
            >
              <DayTimePicker
                onDayChange={(dayOfWeek) => {
                  setProfessionalSchedule((prevSchedule) => ({
                    ...prevSchedule,
                    dayOfWeek: dayOfWeek,
                  }));
                }}
                onFromTimeChange={(fromTime) => {
                  setProfessionalSchedule((prevSchedule) => ({
                    ...prevSchedule,
                    From: fromTime,
                  }));
                }}
                onToTimeChange={(toTime) => {
                  setProfessionalSchedule((prevSchedule) => ({
                    ...prevSchedule,
                    To: toTime,
                  }));
                }}
                validated={validated}
                setValidated={setValidated}
                professionalSchedule={professionalSchedule}
                disabled={!userAllowed}
              />
            </Form>
          </Row>
          <Row className="g-2  mb-3">
            <Col>
              <FormattedDropdown
                apiEndpoint={Entity.Consultorio}
                label="Consultorio"
                required={false}
                name="Consultorio"
                value={professionalSchedule.RoomId}
                onChange={(newRoom) => {
                  setProfessionalSchedule((prevSchedule) => ({
                    ...prevSchedule,
                    RoomId: newRoom,
                  }));
                }}
                disabled={!userAllowed}
              />
            </Col>
            <Col>
              <Col>
                <FormattedDropdown
                  apiEndpoint={Entity.ModalidadAtencion}
                  label="Modalidad de atencion"
                  required={true}
                  name="GenericId"
                  value={professionalSchedule.CareModalityId}
                  onChange={(newCareModalityId) => {
                    setProfessionalSchedule((prevSchedule) => ({
                      ...prevSchedule,
                      CareModalityId: newCareModalityId,
                    }));
                  }}
                  disabled={!userAllowed}
                />
              </Col>
            </Col>
          </Row>
          <Row className="g-2  mb-3">
            <Col>
              <MultiSelect
                apiEndpoint={Entity.Prestacion}
                useConditions={true}
                conditions={
                  professionalSchedule.CareModalityId
                    ? {
                        CareModalityId: {
                          eq: {
                            type: "guid",
                            value: professionalSchedule.CareModalityId,
                          },
                        },
                      }
                    : null
                }
                selectedOptions={selectServices}
                setSelectedOptions={setSelectServices}
                onSelectionChange={(newSelectedOptions) => {
                  setProfessionalSchedule((prevSchedule) => ({
                    ...prevSchedule,
                    ServiceIds: newSelectedOptions,
                  }));
                }}
                labelComp="Prestaciones"
                disabled={!userAllowed}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default FormProfessionalSchedule;
