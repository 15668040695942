import moment from "moment/moment";
import GetEntities from "../GenericComponents/Entities";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import GetUploadedFiles from "../Api/Controllers/UploadedFiles/GetUploadedFiles";
import { Trash3, FiletypePdf, CheckLg, XLg } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";

dayjs.extend(utc);

const Entities = GetEntities();

function timeFormatter(params) {
  if (params.value == null) {
    return "";
  }

  return moment("2000-01-01 " + params.value).format("HH:mm");
}

const fetchPDFUrl = async (prescriptionId) => {
  try {
    const obj = { relatedEntityId: prescriptionId, uploadedFileType: 5 };
    const response = await GetUploadedFiles(obj);
    if (response.length > 0) {
      downloadPDF(response[0]);
    }
  } catch (error) {
    console.log(error);
  }
};
const downloadPDF = (pdf) => {
  window.open(pdf.Url);
};

function dayOfWeekFormatter(params) {
  switch (params.value) {
    case 0:
      return "Domingo";
    case 1:
      return "Lunes";
    case 2:
      return "Martes";
    case 3:
      return "Miercoles";
    case 4:
      return "Jueves";
    case 5:
      return "Viernes";
    case 6:
      return "Sabado";
    default:
      return "";
  }
}

function patientColumns(deleteIconAction, userType, userAllowed) {
  return [
    {
      field: "Id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "FullName",
      headerName: "Nombre Completo",
      flex: 1,
    },
    {
      field: "DocumentNumber",
      headerName: "Documento",
      flex: 1,
    },
    {
      field: "CellPhoneNumber",
      headerName: "Celular",
      flex: 1,
    },
    {
      field: "Email",
      headerName: "Correo electronico",
      flex: 1,
    },
    {
      field: "HealthInsuranceWithPlan",
      headerName: "Obra Social - Plan",
      flex: 1,
    },
    {
      field: "CareModalityId",
      headerName: "Modalidad de atencion",
      hide: true,
    },
    {
      field: "HealthInsuranceId",
      headerName: "Obra Social",
      hide: true,
    },
    {
      flex: 1,
      renderCell: (params) => {
        return userAllowed ? (
          <Trash3
            color="red"
            size={25}
            onClick={() => deleteIconAction(userType, params.row.Id)}
          />
        ) : null;
      },
    },
  ];
}

function familyColumns(deleteIconAction, userType, userAllowed) {
  return [
    {
      field: "Id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "FullName",
      headerName: "Nombre Completo",
      flex: 1,
    },
    {
      field: "CellPhoneNumber",
      headerName: "Celular",
      flex: 1,
    },
    {
      field: "Email",
      headerName: "Correo electronico",
      flex: 1,
    },
    {
      field: "Relationship",
      headerName: "Parentezco",
      flex: 1,
    },
    {
      field: "IsResponsable",
      type: "boolean",
      headerName: "Responsable",
      flex: 1,
      renderCell: (params) => {
        return params.value ? (
          <CheckLg color="green" size={35} />
        ) : (
          <XLg color="red" size={25} />
        );
      },
    },
    {
      flex: 1,
      renderCell: (params) => {
        return userAllowed ? (
          <Trash3
            color="red"
            size={25}
            onClick={() => deleteIconAction(userType, params.row.Id)}
          />
        ) : null;
      },
    },
  ];
}

function idDescriptionColumns(deleteIconAction, userType, userAllowed) {
  return [
    {
      field: "Id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "Name",
      headerName: "Descripcion",
      flex: 1,
    },
    {
      flex: 1,
      renderCell: (params) => {
        return userAllowed ? (
          <Trash3
            color="red"
            size={25}
            onClick={() => deleteIconAction(userType, params.row.Id)}
          />
        ) : null;
      },
    },
  ];
}

function idPaisProvincia(deleteIconAction, userType, userAllowed) {
  return [
    {
      field: "ParentId",
      headerName: "ParentID",
      hide: true,
    },
    {
      field: "Id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "ParentName",
      headerName: "Pais",
      flex: 1,
    },
    {
      field: "Name",
      headerName: "Provincia",
      flex: 1,
    },
    {
      flex: 1,
      renderCell: (params) => {
        return userAllowed ? (
          <Trash3
            color="red"
            size={25}
            onClick={() => deleteIconAction(userType, params.row.Id)}
          />
        ) : null;
      },
    },
  ];
}

function idSedeColumns(deleteIconAction, userType, userAllowed) {
  return [
    {
      field: "ParentId",
      headerName: "ParentID",
      hide: true,
    },
    {
      field: "Id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "ParentName",
      headerName: "Sede",
      flex: 1,
    },
    {
      field: "Name",
      headerName: "Consultorio",
      flex: 1,
    },
    {
      flex: 1,
      renderCell: (params) => {
        return userAllowed ? (
          <Trash3
            color="red"
            size={25}
            onClick={() => deleteIconAction(userType, params.row.Id)}
          />
        ) : null;
      },
    },
  ];
}

function idProvinciaLocalidad(deleteIconAction, userType, userAllowed) {
  return [
    {
      field: "ParentId",
      headerName: "ParentID",
      hide: true,
    },
    {
      field: "Id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "ParentName",
      headerName: "Provincia",
      flex: 1,
    },
    {
      field: "Name",
      headerName: "Localidad",
      flex: 1,
    },
    {
      flex: 1,
      renderCell: (params) => {
        return userAllowed ? (
          <Trash3
            color="red"
            size={25}
            onClick={() => deleteIconAction(userType, params.row.Id)}
          />
        ) : null;
      },
    },
  ];
}

function professionalColumns(deleteIconAction, userType, userAllowed) {
  return [
    {
      field: "Id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "FullName",
      headerName: "Nombre Completo",
      flex: 1,
    },
    {
      field: "DocumentNumber",
      headerName: "Documento",
      flex: 1,
    },
    {
      field: "CellPhoneNumber",
      headerName: "Celular",
      flex: 1,
    },
    {
      field: "Email",
      headerName: "Correo electronico",
      flex: 1,
    },
    {
      field: "SpecialtyName",
      headerName: "Especialdiad",
      flex: 1,
    },
    {
      field: "IsActive",
      headerName: "Activo",
      flex: 0,
      renderCell: (params) => {
        return params.value ? (
          <CheckLg color="green" size={35} />
        ) : (
          <XLg color="red" size={25} />
        );
      },
    },
    {
      flex: 1,
      renderCell: (params) => {
        return userAllowed ? (
          <Trash3
            color="red"
            size={25}
            onClick={() => deleteIconAction(userType, params.row.Id)}
          />
        ) : null;
      },
    },
  ];
}

function profesionalScheduleColumn(deleteIconAction, userType, userAllowed) {
  return [
    {
      field: "Id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "DayOfWeek",
      headerName: "Dia",
      flex: 1,
      valueFormatter: dayOfWeekFormatter,
    },
    {
      field: "From",
      headerName: "De",
      flex: 1,
      valueFormatter: timeFormatter,
    },
    {
      field: "To",
      headerName: "Hasta",
      flex: 1,
      valueFormatter: timeFormatter,
    },
    {
      field: "RoomName",
      headerName: "Consultorio",
      flex: 1,
    },
    {
      flex: 1,
      renderCell: (params) => {
        return userAllowed ? (
          <Trash3
            color="red"
            size={25}
            onClick={() => deleteIconAction(userType, params.row.Id)}
          />
        ) : null;
      },
    },
  ];
}

function usersColumns(deleteIconAction, userType, userAllowed) {
  return [
    {
      field: "Id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "FullName",
      headerName: "Nombre Completo",
      flex: 1,
    },
    {
      field: "DocumentNumber",
      headerName: "Documento",
      flex: 1,
    },
    {
      field: "Email",
      headerName: "Correo electronico",
      flex: 1,
    },
    {
      field: "Profile",
      headerName: "Perfil",
      flex: 1,
    },
    {
      flex: 1,
      renderCell: (params) => {
        return userAllowed ? (
          <Trash3
            color="red"
            size={25}
            onClick={() => deleteIconAction(userType, params.row.Id)}
          />
        ) : null;
      },
    },
  ];
}

function myAppointmentPast(deleteIconAction, userType, userAllowed) {
  return [
    {
      field: "Id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "AppointmentDateTime",
      headerName: "Fecha",
      flex: 1,
      valueFormatter: (params) => {
        let fecha = params.value;
        return dayjs.utc(fecha).format("DD/MM/YYYY HH:mm");
      },
    },
    {
      field: "ProfessionalName",
      headerName: "Profesional",
      flex: 1,
    },
    {
      field: "ServiceName",
      headerName: "Prestacion",
      flex: 1,
    },
    {
      field: "CareModalityName",
      headerName: "Modalidad de atención",
      flex: 1.5,
    },
    {
      field: "AssistanceName",
      headerName: "Asistencia",
      flex: 1,
    },
    {
      field: "SiteName",
      headerName: "Sede",
      flex: 1,
    },
  ];
}

function movementAppointments(deleteIconAction, userType, userAllowed) {
  return [
    {
      field: "Id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "AppointmentDateTime",
      headerName: "Fecha",
      flex: 1,
      valueFormatter: (params) => {
        let fecha = params.value;
        return dayjs.utc(fecha).format("DD/MM/YYYY HH:mm");
      },
    },
    {
      field: "ProfessionalName",
      headerName: "Profesional",
      flex: 1,
    },
    {
      field: "CareModalityName",
      headerName: "Modalidad de atencion",
      flex: 1,
    },
    {
      field: "ServiceName",
      headerName: "Prestacion",
      flex: 1,
    },
    {
      field: "AssistanceName",
      headerName: "Asistencia",
      flex: 1,
    },
  ];
}

function myAppointments(deleteIconAction, userType, userAllowed) {
  return [
    {
      field: "Id",
      headerName: "ID",
    },
    {
      field: "PatientId",
      headerName: "PatientId",
    },
    {
      field: "AppointmentDateTime",
      headerName: "AppointmentDateTime",
      valueFormatter: (params) => {
        let fecha = params.value;
        return dayjs.utc(fecha).format("DD/MM/YYYY HH:mm");
      },
    },
    {
      field: "ProfessionalName",
      headerName: "ProfessionalName",
    },
    {
      field: "HC",
      headerName: "HC",
    },
    {
      flex: 1,
      renderCell: (params) => {
        return userAllowed ? (
          <Trash3
            color="red"
            size={25}
            onClick={() => deleteIconAction(userType, params.row.Id)}
          />
        ) : null;
      },
    },
  ];
}

function healthInsuranceColumns(deleteIconAction, userType, userAllowed) {
  return [
    {
      field: "Id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "Name",
      headerName: "Descripcion",
      flex: 1,
    },
    {
      field: "Plan",
      headerName: "Plan",
      flex: 1,
    },
    {
      field: "ElectronicPrescriptionProvider",
      headerName: "Provedor Electronico",
      flex: 1,
    },
    {
      flex: 1,
      renderCell: (params) => {
        return userAllowed ? (
          <Trash3
            color="red"
            size={25}
            onClick={() => deleteIconAction(userType, params.row.Id)}
          />
        ) : null;
      },
    },
  ];
}

function healthInsuranceCopaymentColumns(
  deleteIconAction,
  userType,
  userAllowed
) {
  return [
    {
      field: "Id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "CareModalityName",
      headerName: "Modalidad de atencion",
      flex: 1,
    },
    {
      field: "ServiceName",
      headerName: "Prestacion",
      flex: 1,
    },
    {
      field: "DateFrom",
      headerName: "Desde",
      flex: 1,
      valueFormatter: (params) => {
        let fecha = params.value;
        return dayjs.utc(fecha).format("DD/MM/YYYY");
      },
    },
    {
      field: "Cost",
      headerName: "Monto",
      flex: 1,
      valueFormatter: (params) => {
        return `$${params.value}`;
      },
    },
    {
      flex: 1,
      renderCell: (params) => {
        return userAllowed ? (
          <Trash3
            color="red"
            size={25}
            onClick={() => deleteIconAction(userType, params.row.Id)}
          />
        ) : null;
      },
    },
  ];
}

function idServiceCareModality(deleteIconAction, userType, userAllowed) {
  return [
    {
      field: "CareModalityId",
      headerName: "CareModalityId",
      hide: true,
    },
    {
      field: "Id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "CareModalityName",
      headerName: "Modalidad de Atencion",
      flex: 2,
    },
    {
      field: "Name",
      headerName: "Prestacion",
      flex: 1,
    },
    {
      field: "MaxSelfServiceAppointmentsPerWeek",
      headerName: "Max autogestion semana",
      flex: 2,
    },
    {
      field: "MaxSelfServiceAppointmentsPerMonth",
      headerName: "Max autogestion mes",
      flex: 2,
    },
    {
      flex: 1,
      renderCell: (params) => {
        return userAllowed ? (
          <Trash3
            color="red"
            size={25}
            onClick={() => deleteIconAction(userType, params.row.Id)}
          />
        ) : null;
      },
    },
  ];
}

function medicineColumns() {
  return [
    {
      field: "Id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "Name",
      headerName: "Nombre",
      flex: 3,
    },
    {
      field: "Code",
      headerName: "Codigo",
      flex: 2,
    },
    {
      field: "Laboratory",
      headerName: "Laboratorio",
      flex: 2,
    },
    {
      field: "Presentation",
      headerName: "Presentacion",
      flex: 3,
    },
    {
      field: "PresentationShape",
      headerName: "Forma farmaceutica",
      flex: 4,
    },
    {
      field: "Drug",
      headerName: "Droga",
      flex: 3,
    },
  ];
}

function zoomAccountColumns(deleteIconAction, userType, userAllowed) {
  return [
    {
      field: "Id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "ClientId",
      headerName: "Client Id",
      flex: 1,
    },
    {
      field: "ClientSecret",
      headerName: "Client Secret",
      flex: 1,
    },
    {
      field: "SdkKey",
      headerName: "Sdk Key",
      flex: 1,
    },
    {
      field: "SdkSecret",
      headerName: "Sdk Secret",
      flex: 1,
    },
    {
      flex: 1,
      renderCell: (params) => {
        return (
          <Trash3
            color="red"
            size={25}
            onClick={() => deleteIconAction(userType, params.row.Id)}
          />
        );
      },
    },
  ];
}

function prescriptionColumns(deleteIconAction, userType, userAllowed) {
  return [
    {
      field: "Id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "PrescribedDate",
      headerName: "Fecha",
      flex: 1,
    },
    {
      field: "ProfessionalName",
      headerName: "Nombre de Profesional",
      flex: 1,
    },
    {
      field: "PatientName",
      headerName: "Nombre de Paciente",
      flex: 1,
    },
    {
      flex: 1,
      headerName: "Acciones",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Button
              variant="warning"
              onClick={() => fetchPDFUrl(params.row.Id)}
              style={{ cursor: "pointer", margin: "0 10px" }}
            >
              <FiletypePdf title="Descargar receta electronica" />
            </Button>
          </div>
        );
      },
    },
  ];
}

const columsForTable = new Map();
columsForTable.set(Entities.Asistencia, idDescriptionColumns);
columsForTable.set(Entities.Especialidad, idDescriptionColumns);
columsForTable.set(Entities.Localidad, idProvinciaLocalidad);
columsForTable.set(Entities.ModalidadAtencion, idDescriptionColumns);
columsForTable.set(Entities.Paciente, patientColumns);
columsForTable.set(Entities.Pais, idDescriptionColumns);
columsForTable.set(Entities.Parentesco, idDescriptionColumns);
columsForTable.set(Entities.Genero, idDescriptionColumns);
columsForTable.set(Entities.Perfil, idDescriptionColumns);
columsForTable.set(Entities.Profesional, professionalColumns);
columsForTable.set(Entities.Provincia, idPaisProvincia);
columsForTable.set(Entities.Rol, idDescriptionColumns);
columsForTable.set(Entities.Prestacion, idServiceCareModality);
columsForTable.set(Entities.TipoDocumento, idDescriptionColumns);
columsForTable.set(Entities.Usuario, usersColumns);
columsForTable.set(Entities.Agenda, profesionalScheduleColumn);
columsForTable.set(Entities.Familiares, familyColumns);
columsForTable.set(Entities.MisTurnosPasados, myAppointmentPast);
columsForTable.set(Entities.MisTurnos, myAppointments);
columsForTable.set(Entities.ObraSocial, healthInsuranceColumns);
columsForTable.set(Entities.Sede, idDescriptionColumns);
columsForTable.set(Entities.Consultorio, idSedeColumns);
columsForTable.set(Entities.Copago, healthInsuranceCopaymentColumns);
columsForTable.set(Entities.Turno, movementAppointments);
columsForTable.set(Entities.CuentasZoom, zoomAccountColumns);
columsForTable.set(Entities.Recetas, prescriptionColumns);
columsForTable.set(Entities.Medicina, medicineColumns);

export function ColumnsForAPI(userType) {
  const columnsTable = ColumnsForTable(userType);
  return columnsTable.map((column) => column.field);
}

export function ColumnsForTable(userType, deleteIconAction, userAllowed) {
  let columns = columsForTable.get(userType);
  if (typeof columns === "function") {
    columns = columns(deleteIconAction, userType, userAllowed);
  }

  if (columns) {
    return columns;
  } else {
    console.log(new Error("No columns found for this entity"));
  }
}
