import Post from "../../BaseApiController/Post"

const endPoint = "/api/Roles"

async function PostRole(body) {
    const successMessage = "Rol creado con exito.";
    const role = await Post(endPoint, body, null, successMessage)
    return role
}

export default PostRole