import Swal from "sweetalert2";

export default function AlertHandler(status, customMessage) {
  let alertConfig = {
    showConfirmButton: false,
    timer: 1500,
    width: 300,
    position: "top",
  };

  switch (status) {
    case "401":
      alertConfig = { ...alertConfig, icon: "error", title: customMessage };
      break;
    case "404":
        alertConfig = { ...alertConfig, icon: "error", title: customMessage };
      break;
    case "200":
      alertConfig = { ...alertConfig, icon: "success", title: customMessage };
      break;
    case "201":
      alertConfig = { ...alertConfig, icon: "success", title: customMessage };
      break;
    case "204":
      alertConfig = { ...alertConfig, icon: "success", title: customMessage };
      break;
    case "500":
      alertConfig = { ...alertConfig, icon: "info", title: customMessage };
      break;
    case "Success":
      alertConfig = {...alertConfig, icon: "success", title: customMessage };
      break;
    case "Error":
      alertConfig = { ...alertConfig, icon: "error", title: customMessage};
      break;
    default:
      alertConfig = {...alertConfig, icon: "info", title: "Error desconocido :("};
      break;
  }

  Swal.fire(alertConfig);
}
