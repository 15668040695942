import Post from "../../BaseApiController/Post"

const endPoint = "/api/Countries"

async function PostCountry(body) {
    const successMessage = "Pais creado con exito.";
    const country = await Post(endPoint, body, null, successMessage)
    return country
}

export default PostCountry