import { axiosPrivate } from "./axios"
import QueryBuilder from "../../QueryBuilder/QueryBuilder"
import HandleError from "./ErrorHandling"
import AlertHandler from "./AlertHandler";

//const baseURL = window.location.origin

async function Delete(endPoint, queryObj, headers) {
    const query = QueryBuilder(queryObj)
    try {
        const response = await axiosPrivate.delete(endPoint + query, { headers })
    } catch (err) {
        HandleError(err);
    }
}

export default Delete