import GetEntities from "../../../GenericComponents/Entities"

const Entities = GetEntities()

const searchPath = new Map()
searchPath.set(Entities.Asistencia, "/BuscarAsistencias");
searchPath.set(Entities.Especialidad, "/BuscarEspecialidades");
searchPath.set(Entities.Localidad, "/BuscarLocalidades");
searchPath.set(Entities.ModalidadAtencion, "/BuscarModalidadesDeAtencion");
searchPath.set(Entities.Paciente, "/BuscarPacientes");
searchPath.set(Entities.Pais, "/BuscarPaises");
searchPath.set(Entities.Parentesco, "/BuscarParentescos");
searchPath.set(Entities.Perfil, "/BuscarPerfiles");
searchPath.set(Entities.Profesional, "/BuscarProfesionales");
searchPath.set(Entities.Provincia, "/BuscarProvincias");
searchPath.set(Entities.Rol, "/BuscarRoles");
searchPath.set(Entities.Prestacion, "/BuscarPrestaciones");
searchPath.set(Entities.TipoDocumento, "/BuscarTipoDeDocumentos");
searchPath.set(Entities.Usuario, "/BuscarUsuarios")
searchPath.set(Entities.Turno, "/BuscarTurnos")
searchPath.set(Entities.ObraSocial, "/BuscarObrasSociales");
searchPath.set(Entities.Sede, "/BuscarSedes");
searchPath.set(Entities.Consultorio, "/BuscarConsultorios");
searchPath.set(Entities.MiAgenda, "/MiAgenda")
searchPath.set(Entities.AutogestionTurno, "/AutogestionTurno")
searchPath.set(Entities.MisTurnos, "/MisTurnos")
searchPath.set(Entities.Password, "/CambiarContraseña")
searchPath.set(Entities.Test, "/TestEnviarMensaje")
searchPath.set(Entities.Turnos, "/Turnos")
searchPath.set(Entities.MiPerfil, "/MiPerfil")
searchPath.set(Entities.Cuenta, "/Configuracion")
searchPath.set(Entities.ConsultaVirtualPaciente, "/ConsultaVirtualPaciente")
searchPath.set(Entities.ConsultaVirtualMedico, "/ConsultaVirtualMedico")
searchPath.set(Entities.Genero, "/BuscarGeneros")

const newEntityPath = new Map()
newEntityPath.set(Entities.Asistencia, "/CrearAsistencia");
newEntityPath.set(Entities.Especialidad, "/CrearEspecialidad");
newEntityPath.set(Entities.Localidad, "/CrearLocalidad");
newEntityPath.set(Entities.ModalidadAtencion, "/CrearModalidadesDeAtencion");
newEntityPath.set(Entities.Paciente, "/CrearPaciente");
newEntityPath.set(Entities.Pais, "/CrearPais");
newEntityPath.set(Entities.Parentesco, "/CrearParentesco");
newEntityPath.set(Entities.Perfil, "/CrearPerfil");
newEntityPath.set(Entities.Profesional, "/CrearProfesional");
newEntityPath.set(Entities.Provincia, "/CrearProvincia");
newEntityPath.set(Entities.Rol, "/CrearRol");
newEntityPath.set(Entities.Prestacion, "/CrearPrestaciones");
newEntityPath.set(Entities.TipoDocumento, "/CrearTipoDocumento");
newEntityPath.set(Entities.Usuario, "/CrearUsuario");
newEntityPath.set(Entities.ObraSocial, "/CrearObraSocial");
newEntityPath.set(Entities.Sede, "/CrearSede");
newEntityPath.set(Entities.Consultorio, "/CrearConsultorio");
newEntityPath.set(Entities.Genero, "/CrearGenero");

const editEntityPath = new Map()
editEntityPath.set(Entities.Asistencia, "/EditarAsistencia/");
editEntityPath.set(Entities.Especialidad, "/EditarEspecialidad/");
editEntityPath.set(Entities.Localidad, "/EditarLocalidad/");
editEntityPath.set(Entities.ModalidadAtencion, "/EditarModalidadAtencion/");
editEntityPath.set(Entities.Paciente, "/EditarPaciente/");
editEntityPath.set(Entities.Pais, "/EditarPais/");
editEntityPath.set(Entities.Parentesco, "/EditarParentesco/");
editEntityPath.set(Entities.Perfil, "/EditarPerfil/");
editEntityPath.set(Entities.Profesional, "/EditarProfesional/");
editEntityPath.set(Entities.Provincia, "/EditarProvincia/");
editEntityPath.set(Entities.Rol, "/EditarRol/");
editEntityPath.set(Entities.Prestacion, "/EditarPrestacion/");
editEntityPath.set(Entities.TipoDocumento, "/EditarTipoDocumento/");
editEntityPath.set(Entities.Usuario, "/EditarUsuario/");
editEntityPath.set(Entities.ObraSocial, "/EditarObraSocial/");
editEntityPath.set(Entities.Sede, "/EditarSede/");
editEntityPath.set(Entities.Consultorio, "/EditarConsultorio/");
editEntityPath.set(Entities.Genero, "/EditarGenero/");

export function SearchPath(type) {
    const path = searchPath.get(type)
    if (path) {
        return path
    } else {
        console.log(new Error("No path found for this entity"))
    }
}

export function NewEntityPath(type) {
    const path = newEntityPath.get(type)
    if (path) {
        return path
    } else {
        console.log(new Error("No path found for this entity"))
    }
}

export function EditEntityPath(type) {
    const path = editEntityPath.get(type)
    if (path) {
        return path
    } else {
        console.log(new Error("No path found for this entity"))
    }
}