import React, { useState } from 'react';
import { Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import PostSendWhatsapp from '../Api/Controllers/Notifications/PostSendWhatsapp';

const SendMessage = () => {
    const [number, setNumber] = useState("");
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const [emailMessage, setEmailMessage] = useState("");
    const prefixOptions = ['+1', '+54', '+55', '598'];

    let newMessage = {
        cellNumber: number,
        message: message
    }

    const sendMessage = async () => {
        console.log(`Sending message: "${message}" to ${number}`);
        const response = await PostSendWhatsapp(newMessage)
        console.log(response)
    }

    const sendEmail = () => {
        console.log(`Sending email: "${emailMessage}" to ${email}`);
    }

    return (
        <div>
            <InputGroup className="mb-3">
                <Form.Control as="select" onChange={(e) => setNumber(e.target.value + number.substr(number.indexOf(" ")))}>
                    {prefixOptions.map((prefix, index) => (
                        <option key={index}>{prefix}</option>
                    ))}
                </Form.Control>
                <FormControl
                    aria-describedby="prefix"
                    placeholder="Phone Number"
                    value={number}
                    onChange={(e) => setNumber(number.substr(0, number.indexOf(" ")) + e.target.value)}
                />
            </InputGroup>
            <Form.Group className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} value={message} onChange={(e) => setMessage(e.target.value)} />
            </Form.Group>
            <Button variant="primary" onClick={sendMessage}>
                Send Message
            </Button>

            {/* Email Sending */}
            <Form.Group className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Email Message</Form.Label>
                <Form.Control as="textarea" rows={3} value={emailMessage} onChange={(e) => setEmailMessage(e.target.value)} />
            </Form.Group>
            <Button variant="primary" onClick={sendEmail}>
                Send Email
            </Button>
        </div>
    );
}
export default SendMessage;
