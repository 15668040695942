import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import LoadSpinner from './../Spinner/Spinner';

const PersistLogin = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Outlet /> : <LoadSpinner />;
};

export default PersistLogin;
