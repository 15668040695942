import React, { useEffect } from 'react'
import { DataGrid, esES, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { useState, useCallback } from 'react';
import GetSearchTableData from './GetSearchTableData';
import { ColumnsForTable, ColumnsForAPI } from './TableSearchUtils';
import Filtering from './Filtering';
import { useNavigate } from 'react-router-dom'
import Popup from '../GenericComponents/Popup';
import { GetEntityId } from '../GenericComponents/EntityIds';
import { EditEntityPath } from '../Api/Controllers/Common/Paths';

const fetchData = async (props) => {
  const json = await GetSearchTableData(props.userType, props.obj);
  if (typeof json === "undefined" || json["@odata.count"] === 0) {
    props.setData([]);
    props.setTotalFilas(0);
  } else {
    if (json.value !== undefined) {
      props.setData(json.value);
      props.setTotalFilas(json["@odata.count"]);
    }
    else {
      props.setData(json);
      props.setTotalFilas(json.length);
    }
  }

}

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}

function TableSearch({
  userType,
  parentEntity,
  setCheckRows,
  setRefresh,
  refresh,
  entityId,
  popUp,
  orderBy = [],
  checkboxSelection = true,
  columnClickEnabled = true,
  handleSelect,
  userAllowed = true,
  deleteIconAction
}) {

  const column = ColumnsForTable(userType, deleteIconAction, userAllowed)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [totalFilas, setTotalFilas] = useState(0)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [filter, setFilter] = useState({})
  const [popUpShow, setPopUpShow] = useState(false);
  const [popUpentityId, setPopUpentityId] = useState("")
  let navigate = useNavigate();
  const entityfieldid = GetEntityId(userType)
  const editPath = EditEntityPath(userType)
  const columsForApi = ColumnsForAPI(userType)
  const [sortModel, setSortModel] = useState([]);

  const handleRowClick = (params) => {
    if (popUp === true) {
      setPopUpentityId(params.id)
      setPopUpShow(true)
    } else {
      if(editPath === undefined)
        return;
      let id = params.id
      navigate(`${editPath}${id}`, { state: { id: id } });
    }
    handleSelect(params.row);
  };

  const handleCheckRow = (checkedRow) => {
    const aux = checkedRow
    setCheckRows(aux)
  }

  useEffect(() => {

    const sortModelString = sortModel.map(item => `${item.field} ${item.sort}`).join(', ');

    const obj = {
      count: true,
      top: pageSize,
      skip: (pageSize * (page - 1)),
      filter: filter,
      select: columsForApi,
      orderBy: sortModelString.length > 0 ? sortModelString : orderBy
    }  

    if (entityId) {
      obj.filter = { [entityfieldid]: { eq: { type: 'guid', value: entityId } } };
    }
    fetchData({ setIsLoading, setData, setTotalFilas, obj, userType, parentEntity });
  }, [pageSize, page, filter, refresh, deleteIconAction, sortModel])

  const onFilterChange = useCallback((filterModel) => {
    setPage(1)
    const quickSearch = filterModel.quickFilterValues[0];
    if (quickSearch === '' || quickSearch === undefined) {
      setFilter({});
    } else {
      const newFilter = Filtering(userType, quickSearch.toLowerCase());
      setFilter(newFilter);
    }
  }, []);

  return (
    <><DataGrid
      autoHeight
      onRowDoubleClick={columnClickEnabled ? handleRowClick : null}
      rows={data}
      getRowId={(row) => row.Id ? row.Id : row.id}
      rowCount={totalFilas}
      loading={isLoading}
      rowsPerPageOptions={[10, 30, 50, 70, 100]}
      filterMode="server"
      onFilterModelChange={onFilterChange}
      pagination
      page={page - 1}
      pageSize={pageSize}
      paginationMode="server"
      onPageChange={(newPage) => { setPage(newPage + 1); }}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      columns={column}
      localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      components={{ Toolbar: QuickSearchToolbar }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      checkboxSelection={checkboxSelection}
      onSelectionModelChange={handleCheckRow}
      disableSelectionOnClick
      disableColumnFilter
      disableColumnSelector
      experimentalFeatures={{ newEditingApi: true }}
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
    />
      {popUp === true && (<Popup
        show={popUpShow}
        onHide={() => { setPopUpShow(false); setRefresh(!refresh) }}
        entityId={popUpentityId}
        entityfieldid={entityId}
        popupentity={userType}
        setRefresh={setRefresh}
        refresh={refresh}
        userAllowed={userAllowed}
      />)}
    </>
  )
}

export default TableSearch