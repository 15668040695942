import Put from "../../BaseApiController/Put"

const endPoint = "/api/Specialties"

async function PutSpecialty(queryObj, body) {
    const successMessage = "Especialidad modificada con exito.";
    const response = await Put(endPoint, queryObj, body, null, successMessage)
    return response
}

export default PutSpecialty