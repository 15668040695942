import { ListGroup } from "react-bootstrap";
import { useState, useMemo } from "react";

function Listview({ items, handleClick }) {
    const [hoveredIndex, setHoveredIndex] = useState(-1);

    const handleSuggestionHover = (index) => {
        setHoveredIndex(index);
    };

    const handleSuggestionLeave = () => {
        setHoveredIndex(-1);
    };

    const sortedItems = useMemo(() => {
        return items.slice().sort((a, b) => {
            if (a.IsWorking === b.IsWorking) {
                if (a.LastName.toLowerCase() < b.LastName.toLowerCase()) return -1;
                if (a.LastName.toLowerCase() > b.LastName.toLowerCase()) return 1;
                if (a.Name.toLowerCase() < b.Name.toLowerCase()) return -1;
                if (a.Name.toLowerCase() > b.Name.toLowerCase()) return 1;
                return 0;
            }
            return a.IsWorking ? -1 : 1;
        });
    }, [items]);

    return (
        <div style={{ height: "300px", overflowY: "scroll" }}>
            <ListGroup variant="flush">
                {sortedItems.map((item, index) => (
                    <div key={index} onClick={() => handleClick(item)}>
                        <ListGroup.Item
                            onMouseEnter={() => handleSuggestionHover(index)}
                            onMouseLeave={handleSuggestionLeave}
                            style={{
                                cursor: 'pointer',
                                textDecoration: index === hoveredIndex ? 'underline' : 'none',
                                fontSize: '14px',
                                padding: '8px 10px',
                                backgroundColor: item.IsWorking ? '#A0E983' : '#D2D4C9'
                            }}
                        >
                            {item.Name} {item.LastName}
                        </ListGroup.Item>
                    </div>
                ))}
            </ListGroup>
        </div>
    );
}

export default Listview;
