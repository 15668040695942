import { useState, useContext } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import UserProfile from './UserProfile';
import FormUser from './FormUser';
import { UserOperationsAllowedContext } from '../Dashboard/UserOperationsAllowedContext';

const User = ({ requiredOperations = [] }) => {
    let { userId } = useParams();
    const [activeTab, setActiveTab] = useState('formUser');
    const { userOperationsAllowed, hasAllRequiredOperations } = useContext(UserOperationsAllowedContext);

    const handleSelect = (key) => {
        setActiveTab(key);
    };

    const userAllowed = hasAllRequiredOperations({
        requiredOperations,
        userOperations: userOperationsAllowed
    });

    return (
        <Card>
            <Tabs
                activeKey={activeTab}
                onSelect={handleSelect}
                id="justify-tab-example"
                className="mb-3"
                justify
            >
                <Tab eventKey="formUser" title="Datos Personales">
                    {activeTab === 'formUser' &&
                        <Card.Body>
                            <FormUser userId={userId} userAllowed={userAllowed} />
                        </Card.Body>
                    }
                </Tab>
                <Tab eventKey="userProfile" title="Perfil de Usuario" disabled={userId === undefined} >
                    {activeTab === 'userProfile' &&
                        <Card.Body>
                            <UserProfile userId={userId} userAllowed={userAllowed} />
                        </Card.Body>}
                </Tab>
            </Tabs>
        </Card>
    );
}

export default User;
