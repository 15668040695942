import Swal from "sweetalert2";

function SuccessEvent (customMessage) {

    let alertConfig = {
        showConfirmButton: false,
        width: 500,
        position: "top",
        icon: "success",
        title: customMessage,
        timer: 1000
    };

    Swal.fire(alertConfig)
}

export default SuccessEvent