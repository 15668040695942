import React from "react";
import {
  PlusSquare,
  Save2Fill,
  ArrowLeftSquare,
  Trash3,
  ArrowClockwise,
  XCircle,
  PencilSquare,
  Whatsapp,
} from "react-bootstrap-icons";
import {
  Button,
  Container,
  ButtonToolbar,
  ButtonGroup,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { SearchPath, NewEntityPath } from "../Api/Controllers/Common/Paths";
import ActionHandler from "./ActionsHandler";

function Titulo(props) {
  const { etiqueta, texto } = props;
  const aux = React.createElement(etiqueta, null, texto);
  return aux;
}

const Toolbar = ({
  title,
  entity,
  setPopUpShow,
  popUp,
  deleteAction,
  saveAction,
  setRefresh,
  refresh,
  showBackButton,
  showRefreshButton,
  showSaveButton,
  showDeleteButton,
  showNewButton,
  showCloseButton,
  actionClose,
  refSaveButton,
  newAction,
  showNewButtonWithAction,
  disabled,
  showEditButton,
  actionEdit,
  titleTag = "h2",
  showActions,
  checkedRows = [],
  reSendNotification,
  actionReSendNotification,
}) => {
  let backPath = "";
  let newPath = "";
  if (entity) {
    backPath = SearchPath(entity);
    newPath = NewEntityPath(entity);
  }
  const actions = ActionHandler({ entity });

  return (
    <ButtonToolbar
      className="justify-content-between"
      aria-label="Toolbar with Button groups"
      style={{ alignItems: "center" }}
    >
      <ButtonGroup>
        <Titulo etiqueta={titleTag} texto={title} />
      </ButtonGroup>
      <ButtonGroup>
        <Container className="float-end">
          {showBackButton === true && (
            <Link to={backPath}>
              <Button variant="secondary" disabled={disabled}>
                <ArrowLeftSquare title="Back" />
              </Button>
            </Link>
          )}{" "}
          {showSaveButton === true && (
            <Button variant="success" onClick={saveAction} disabled={disabled}>
              <Save2Fill title="Save" />
            </Button>
          )}{" "}
          {showNewButton === true && !popUp && (
            <Link to={newPath}>
              <Button variant="success" disabled={disabled}>
                <PlusSquare title="New" />
              </Button>
            </Link>
          )}{" "}
          {popUp === true && showNewButton === true && (
            <Button
              variant="success"
              onClick={() => {
                setPopUpShow(true);
              }}
              disabled={disabled}
            >
              <PlusSquare title="New" />
            </Button>
          )}
          {showDeleteButton === true && (
            <Button variant="danger" onClick={deleteAction} disabled={disabled}>
              <Trash3 title="Delete" />
            </Button>
          )}{" "}
          {showRefreshButton === true && (
            <Button
              variant="primary"
              onClick={() => {
                setRefresh(!refresh);
              }}
              disabled={disabled}
            >
              <ArrowClockwise title="Refresh" />
            </Button>
          )}{" "}
          {showCloseButton === true && (
            <Button variant="danger" onClick={actionClose} disabled={disabled}>
              <XCircle title="Close" />
            </Button>
          )}{" "}
          {showNewButtonWithAction === true && (
            <Button variant="success" onClick={newAction}>
              <PlusSquare title="New" />
            </Button>
          )}{" "}
          {showEditButton === true && (
            <Button variant="info" onClick={actionEdit}>
              <PencilSquare title="Edit" />
            </Button>
          )}{" "}
          {reSendNotification == true && (
            <Button variant="warning" onClick={actionReSendNotification}>
              <Whatsapp title="reSendNotification" />
            </Button>
          )}{" "}
          {actions && showActions && (
            <Dropdown style={{ display: "inline-block" }}>
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                Acciones
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {actions.map((action) => (
                  <Dropdown.Item
                    onClick={() =>
                      action.action({ obj: checkedRows, refresh, setRefresh })
                    }
                    key={action.label}
                  >
                    {action.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Container>
      </ButtonGroup>
    </ButtonToolbar>
  );
};

export default Toolbar;
