class Service {
    constructor(Id, Name, CareModalityId, CareModalityName, MaxSelfServiceAppointmentsPerWeek, MaxSelfServiceAppointmentsPerMonth){
        this.Id = Id;
        this.Name = Name;
        this.CareModalityId = CareModalityId;
        this.CareModalityName = CareModalityName;
        this.MaxSelfServiceAppointmentsPerWeek = MaxSelfServiceAppointmentsPerWeek;
        this.MaxSelfServiceAppointmentsPerMonth = MaxSelfServiceAppointmentsPerMonth;
    }
}

export default Service