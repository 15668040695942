import Put from "../../BaseApiController/Put"

const endPoint = "/api/Rooms"

async function PutRoom(queryObj, body) {
    const successMessage = "Consultorio modificado con exito.";
    const response = await Put(endPoint, queryObj, body, null, successMessage)
    return response
}

export default PutRoom