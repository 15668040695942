class ProfesionalSchedule {
    constructor(Id, ProfessionalId, DayOfWeek, From, To, RoomId, RoomName, ServiceIds, CareModalityId){
        this.Id = Id;
        this.ProfessionalId =ProfessionalId;
        this.DayOfWeek = DayOfWeek;
        this.From = From;
        this.To = To;
        this.RoomId = RoomId;
        this.RoomName = RoomName;
        this.ServiceIds = ServiceIds;
        this.CareModalityId = CareModalityId;
    }
}

export default ProfesionalSchedule