import Get from "../../BaseApiController/Get"

const endPoint = "/api/Appointments/GetAppointmentsWithSchedule/{date}/{professionalId}"

async function GetAppointmentWithSchedule(date, professionalId) {
    let ensambledEndPoint = endPoint.replace("{date}", date).replace("{professionalId}", professionalId)
    const response = await Get(ensambledEndPoint)
    return response
}

export default GetAppointmentWithSchedule