const GetEntities = () => {
    return (
        {
            Pais: "Pais",
            TipoDocumento: "TipoDocumento",
            Paciente: "Paciente",
            Rol: "Rol",
            Familiares: "Familiares",
            Profesional: "Profesional",
            Agenda: "Agenda",
            Parentesco: "Parentesco",
            Perfil: "Perfil",
            Provincia: "Provincia",
            Localidad: "Localidad",
            Asistencia: "Asistencia",
            Prestacion: "Prestacion",
            ModalidadAtencion: "ModalidadAtencion",
            Especialidad: "Especialidad",
            Usuario: "Usuario",
            Turno: "Turno",
            AgendaProfesional: "AgendaProfesional",
            AgendaProfesionalConTurno: "AgendaProfesionalConTurno",
            Sede: "Sede",
            MiAgenda: "MiAgenda",
            AutogestionTurno: "AutogestionTurno",
            MisTurnosPasados: "MisTurnosPasados",
            MisTurnosFuturos: "MisTurnosFuturos",
            MisTurnos: "MisTurnos",
            Password: "Password",
            HorarioGeneralProfesional: "HorarioGeneralProfesional",
            HomeKPI: "HomeKPI",
            Test: "Test",
            Evolucion: "Evolucion",
            ObraSocial: "ObraSocial",
            ObraSocialFarmaLink: "ObraSocialFarmaLink",
            ProvedorElectronico: "ProvedorElectronico",
            Copago: "Copago",
            Consultorio: "Consultorio",
            Turnos: "Turnos",
            MiPerfil: "MiPerfil",
            Cuenta: "Cuenta",
            CuentasZoom: "CuentasZoom",
            ConsultaVirtualPaciente: "ConsultaVirtualPaciente",
            ConsultaVirtualMedico: "ConsultaVirtualMedico",
            MultiplesEvoluciones: "MultiplesEvoluciones",
            ResendAppointmentNotification: "ResendAppointmentNotification",
            Recetas: "Recetas",
            Medicina: "Medicina",
            ResetPassword: "ResetPassword",
            Genero: "Genero",
        }
    )
}

export default GetEntities