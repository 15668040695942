import Post from "../../BaseApiController/Post"

const endPoint = "/api/Assistances"

async function PostAssistance(body) {
    const successMessage = "Asistencia creada con exito.";
    const response = await Post(endPoint, body, null, successMessage)
    return response
}

export default PostAssistance