import React, { useState, useEffect } from "react";
import { Row, Col, Card, Container, ListGroup } from "react-bootstrap";
import { Headset, Clipboard2Pulse } from "react-bootstrap-icons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import GetEntities from "../GenericComponents/Entities";
import { GetEndPoint } from "../Api/Controllers/Common/EndPoints";
import PutAppointmentHC from "../Api/Controllers/Appointments/PutAppointmentHC";
import Popup from "../GenericComponents/Popup";
import FillEntity from "../GenericComponents/FillEntity";
import { EditEntityPath } from "../Api/Controllers/Common/Paths";
import { Link, useNavigate } from "react-router-dom";
import PutAppointmentRegisterOnlineConnection from "../Api/Controllers/Appointments/PutRegisterOnlineConnection";
import "./MyCalendar.css";
import SpanishDateCalendar from "../GenericComponents/SpanishDateCalendar";

const Entity = GetEntities();
dayjs.extend(utc);

const MyCalendar = () => {
  const fechaActual = new Date().toISOString();
  const [date, setDate] = useState(dayjs(fechaActual));
  const currentDate = date.format("YYYY-MM-DD");
  const [refresh, setRefresh] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [text, setText] = useState({});
  const [showModalEvolucion, setShowModalEvolucion] = useState(false);
  const [showModalTurno, setShowModalTurno] = useState(false);
  const navigate = useNavigate();
  const [showMultipleEvolucionModal, setShowMultipleEvolucionModal] =
    useState(false);
  const [multipleSchedules, setMultipleSchedules] = useState([]);

  const fetchData = async () => {
    const apiMyCalendar = GetEndPoint(Entity.MiAgenda);
    const response = await apiMyCalendar(currentDate);
    const myCalendar = response.map((a) => FillEntity(Entity.MiAgenda, a));
    setAppointments(myCalendar);
  };

  useEffect(() => {
    fetchData();
  }, [date, refresh]);

  const handleSaveClick = async (event) => {
    if (selectedSchedule && text[selectedSchedule.Id] !== null) {
      const key = { type: "guid", value: selectedSchedule.Id };
      const body = { HC: text[selectedSchedule.Id] };
      try {
        await PutAppointmentHC(key, body);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
    setShowModalEvolucion(false);
  };

  const handleClipboardClick = (clickedSchedule) => {
    const clickedTime = dayjs
      .utc(clickedSchedule.AppointmentDateTime)
      .format("HH:mm");
    const sameTimeSchedules = appointments.filter((schedule) => {
      return (
        dayjs.utc(schedule.AppointmentDateTime).format("HH:mm") === clickedTime
      );
    });

    if (sameTimeSchedules.length > 1) {
      setMultipleSchedules(sameTimeSchedules);
      setShowMultipleEvolucionModal(true);
    } else {
      setSelectedSchedule(clickedSchedule);
      setShowModalEvolucion(true);
    }
  };

  const handleModalCloseEvolucion = () => {
    setSelectedSchedule(null);
    setShowModalEvolucion(false);
  };

  const RegisterOnlineConnection = async (appointmentId) => {
    const key = { type: "guid", value: appointmentId };
    try {
      await PutAppointmentRegisterOnlineConnection(key, null);
    } catch (error) {
      console.log(error);
    }
  }

  const handleHeadsetClick = (clickedSchedule) => {
    const clickedTime = dayjs
      .utc(clickedSchedule.AppointmentDateTime)
      .format("HH:mm");
    const sameTimeSchedules = appointments.filter((schedule) => {
      return (
        dayjs.utc(schedule.AppointmentDateTime).format("HH:mm") === clickedTime
      );
    });

    const url = clickedSchedule.OnlineMeetingLink;
    window.open(url, '_blank');
    RegisterOnlineConnection(clickedSchedule.Id)
  };

  return (
    <Container>
      <Card>
        <Card.Header>
          <h2>Mi Agenda</h2>
        </Card.Header>
        <Card.Body>
          <Row className="d-flex align-items-center justify-content-center">
            <Card style={{ width: "320px" }}>
              <SpanishDateCalendar
                value={date}
                onChange={(newDate) => setDate(newDate)}
              />
            </Card>
            <Row className="g-2">
              {appointments.length > 0 ? (
                <h4>Mis Turnos para el día {date.format("DD/MM")}</h4>
              ) : (
                <h4>No hay turnos para el día {date.format("DD/MM")}</h4>
              )}
            </Row>
          </Row>
          <Row className="g-2">
            <ListGroup variant="flush">
              {appointments.map((schedule, index) => (
                <div key={index}>
                  <Card className="mb-2">
                    <Card.Body className="p-2">
                      <Row className="d-flex align-items-center mb-3">
                        <Col xs={3}>
                          <Card.Text>
                            Hora:{" "}
                            <strong>
                              {schedule.AppointmentDateTime
                                ? dayjs
                                  .utc(schedule.AppointmentDateTime)
                                  .format("HH:mm")
                                : "NO INGRESADO"}
                            </strong>
                          </Card.Text>
                        </Col>
                        <Col xs={9}>
                          <Row>
                            <Col xs={4}>
                              <Card.Text>
                                {"Paciente: "}
                                <Link
                                  to={`${EditEntityPath(Entity.Paciente) +
                                    schedule.PatientId
                                    }?tab=HC`}
                                >
                                  <strong>
                                    {schedule.PatientName || "NO INGRESADO"}
                                  </strong>
                                </Link>
                              </Card.Text>
                            </Col>
                            <Col xs={6}>
                              <Card.Text>
                                Modalidad de atención:{" "}
                                <strong>
                                  {schedule.CareModalityName || "NO INGRESADO"}
                                </strong>
                              </Card.Text>
                            </Col>
                            <Col xs={2} className="d-flex justify-content-end">
                              {!schedule.HC && (
                                <Clipboard2Pulse
                                  onClick={() => handleClipboardClick(schedule)}
                                  size={20}
                                  className="mx-2"
                                />
                              )}
                              {schedule.IsOnline && (
                                <Col
                                  xs={12}
                                  sm={6}
                                  md="auto"
                                  className="d-flex justify-content-end align-items-center"
                                >
                                  <Headset
                                    size={20}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleHeadsetClick(schedule)}
                                  />
                                </Col>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-center mt-3">
                        <Col xs={3}>
                          <Card.Text>
                            Sede:{" "}
                            <strong>
                              {schedule.SiteName || "NO INGRESADO"}
                            </strong>
                          </Card.Text>
                        </Col>
                        <Col xs={9}>
                          <Row>
                            <Col xs={4}>
                              <Card.Text>
                                Asistencia:{" "}
                                <strong>
                                  {schedule.AssistanceName || "NO INGRESADO"}
                                </strong>
                              </Card.Text>
                            </Col>
                            <Col xs={4}>
                              <Card.Text>
                                Prestacion:{" "}
                                <strong>
                                  {schedule.ServiceName || "NO INGRESADO"}
                                </strong>
                              </Card.Text>
                            </Col>
                            <Col xs={4}>
                              <Card.Text>
                                Consultorio:{" "}
                                <strong>
                                  {schedule.RoomName || "NO INGRESADO"}
                                </strong>
                              </Card.Text>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Popup
                    popupentity={Entity.Evolucion}
                    showModalEvolucion={
                      showModalEvolucion && selectedSchedule === schedule
                    }
                    handleModalClose={handleModalCloseEvolucion}
                    handleSaveClick={handleSaveClick}
                    schedule={schedule}
                    text={text}
                    setText={setText}
                  />
                  {selectedSchedule === schedule && showModalTurno && (
                    <Popup
                      show={selectedSchedule !== null}
                      onHide={() => {
                        setSelectedSchedule(null);
                        setRefresh(!refresh);
                      }}
                      popupentity={Entity.Turno}
                      entity={selectedSchedule}
                    />
                  )}
                </div>
              ))}
            </ListGroup>
            {showMultipleEvolucionModal && (
              <Popup
                popupentity={Entity.MultiplesEvoluciones}
                showMultipleEvolucionModal={showMultipleEvolucionModal}
                handleModalCloseMultipleEvolucion={() => {
                  setShowMultipleEvolucionModal(false);
                  setMultipleSchedules(null);
                }}
                schedules={multipleSchedules}
                fetchData={fetchData}
                setShowMultipleEvolucionModal={setShowMultipleEvolucionModal}
              />
            )}
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default MyCalendar;
