import Put from "../../BaseApiController/Put"

const endPoint = "/api/RelatedPeoples"

async function PutRelatedPeople(queryObj, body) {
    const successMessage = "Familiar modificado con exito.";
    const people = await Put(endPoint, queryObj, body, null, successMessage)
    return people
}

export default PutRelatedPeople