import { useState, useContext, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Card } from "react-bootstrap";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import { useParams } from "react-router-dom";
import CardTableSearch from "../TableSearch/CardTableSearch";
import ProfesionalSpecialties from './ProfessionalSpecialties';
import GetEntities from '../GenericComponents/Entities';
import { UserOperationsAllowedContext } from '../Dashboard/UserOperationsAllowedContext';

const scheduleEntity = GetEntities().Agenda

const Profesional = ({ requiredOperations = [] }) => {
    let { professionalId } = useParams();
    const savedTab = localStorage.getItem('activeProfessionalTab') || 'formProfessional';
    const [activeTab, setActiveTab] = useState(savedTab);
    const { userOperationsAllowed, hasAllRequiredOperations } = useContext(UserOperationsAllowedContext);

    const handleSelect = (key) => {
        localStorage.setItem('activeProfessionalTab', key);
        setActiveTab(key);
    }

    const userAllowed = hasAllRequiredOperations({
        requiredOperations,
        userOperations: userOperationsAllowed
    });

    useEffect(() => {
        return () => {
            localStorage.removeItem('activeProfessionalTab');
        };
    }, []);

    return (
        <Card>
            <Tabs
                activeKey={activeTab}
                onSelect={handleSelect}
                id="justify-tab-example"
                className="mb-3"
                justify
            >
                <Tab eventKey="formProfessional" title="Datos Personales">
                    {activeTab === 'formProfessional' &&
                        <Card.Body>
                            <PersonalInformation professionalId={professionalId} userAllowed={userAllowed} />
                        </Card.Body>
                    }
                </Tab>
                <Tab eventKey="specialties" title="Datos Profesionales" disabled={professionalId === undefined} >
                    {activeTab === 'specialties' &&
                        <Card.Body>
                            <ProfesionalSpecialties professionalId={professionalId} userAllowed={userAllowed} />
                        </Card.Body>}
                </Tab>
                <Tab eventKey="agenda" title="Agenda" disabled={professionalId === undefined} >
                    {activeTab === 'agenda' &&
                        <Card.Body>
                            <CardTableSearch
                                title="Agenda"
                                entity={scheduleEntity}
                                columns={scheduleEntity}
                                key={scheduleEntity}
                                entityId={professionalId}
                                popUp={true}
                                orderBy={['DayOfWeek asc']}
                                requiredOperations={requiredOperations}
                            />
                        </Card.Body>}
                </Tab>
            </Tabs>
        </Card>
    );
}

export default Profesional;
