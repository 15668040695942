import React, { useState, useEffect, useRef, useContext } from "react";
import FormattedTextBox from "./FormattedTextBox";
import { Form, Container, Card } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { NewEntityPath } from "../Api/Controllers/Common/Paths";
import {
  GetEndPoint,
  PutEndPoint,
  DeleteEndPoint,
  PostEndPoint,
} from "../Api/Controllers/Common/EndPoints";
import Toolbar from "./ToolBar";
import FormattedDropdown from "./FormattedDropdown";
import { EditEntityPath } from "../Api/Controllers/Common/Paths";
import DeleteWarning from "../GenericComponents/DeleteWarning";
import { UserOperationsAllowedContext } from "../Dashboard/UserOperationsAllowedContext";
import { useUnsavedChanges } from "../Context/UnsavedChangesContext";

const IdNameForm = ({
  type,
  parentType,
  title,
  isPopUp,
  action,
  selectedParentIdParentIdparam,
  requiredOperations = [],
}) => {
  let { id } = useParams();
  const newEntityPath = NewEntityPath(type);
  let navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [entityName, setEntityName] = useState("");
  const [parentEntityName, setParentEntityName] = useState(0);
  const [selectedParentId, setSelectedParentId] = useState(
    selectedParentIdParentIdparam ?? ""
  );
  const [hasParent, setHasParent] = useState(false);
  const [validated, setValidated] = useState(false);
  const form = useRef(null);
  const editEntityPath = EditEntityPath(type);
  const [isLoading, setIsLoading] = useState(false);
  const { userOperationsAllowed, hasAllRequiredOperations } = useContext(
    UserOperationsAllowedContext
  );
  const { setHasUnsavedChanges } = useUnsavedChanges();

  const handleChange = (event) => {
    setEntityName(event.target.value);
    setHasUnsavedChanges(true);
  };

  const fetchIdNameData = async (id, type) => {
    const key = { type: "guid", value: id };
    const api = GetEndPoint(type);
    const response = await api({ key });
    if (parentType !== undefined) {
      setParentEntityName(response.ParentId);
    }
    setEntityName(response.Name);
  };

  const deleteEntity = async () => {
    const key = { type: "guid", value: id };
    const api = DeleteEndPoint(type);
    await api({ key });
    navigate(newEntityPath);
    id = undefined;
    setRefresh(!refresh);
  };

  const handleSaveClick = async (event) => {
    setIsLoading(true);
    setValidated(form.current.checkValidity() === false);
    if (form.current.checkValidity() === false) {
      event.stopPropagation();
    } else {
      let body = { name: entityName };
      if (hasParent) {
        body = {
          name: entityName,
          parentId: selectedParentId ? selectedParentId : parentEntityName,
        };
      }
      if (id !== undefined) {
        const key = { type: "guid", value: id };
        const api = PutEndPoint(type);
        await api({ key }, body);
      } else {
        const api = PostEndPoint(type);
        const response = await api(body);
        if (isPopUp) {
          setEntityName("");
          action();
        } else {
          const newEntityId = response.Id;
          navigate(`${editEntityPath}${newEntityId}`, {
            state: { id: newEntityId },
          });
        }
      }
    }
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
    setHasUnsavedChanges(false);
  };

  useEffect(() => {
    if (parentType === undefined) {
      setHasParent(false);
    } else {
      setHasParent(true);
    }
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      fetchIdNameData(id, type);
    } else {
      setEntityName("");
      setParentEntityName("");
    }
    setValidated(false);
  }, [refresh]);

  const userAllowed = hasAllRequiredOperations({
    requiredOperations,
    userOperations: userOperationsAllowed,
  });

  return (
    <Container>
      <Card>
        <Card.Header>
          {!id ? (
            <Toolbar
              title={"Crear " + title}
              entity={type}
              saveAction={handleSaveClick}
              setRefresh={setRefresh}
              refresh={refresh}
              showBackButton={!isPopUp}
              showSaveButton={userAllowed}
              showCloseButton={isPopUp}
              actionClose={action}
              disabled={isLoading}
            />
          ) : (
            <Toolbar
              title={"Editar " + title}
              entity={type}
              deleteAction={() => DeleteWarning(deleteEntity)}
              saveAction={handleSaveClick}
              setRefresh={setRefresh}
              refresh={refresh}
              showBackButton={!isPopUp}
              showSaveButton={userAllowed}
              showNewButton={userAllowed}
              showDeleteButton={userAllowed}
              showRefreshButton={true}
              disabled={isLoading}
            />
          )}
        </Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} ref={form}>
            <FormattedTextBox
              label="Nombre"
              type="text"
              placeholder="Ingrese el nombre"
              name="name"
              value={entityName}
              handleChange={handleChange}
              required={true}
              disabled={!userAllowed}
            />
          </Form>
        </Card.Body>
        {hasParent && (
          <Card.Body>
            <FormattedDropdown
              apiEndpoint={parentType}
              label={parentType}
              required={true}
              name="GenericId"
              value={selectedParentId || parentEntityName}
              onChange={(value) => {
                setSelectedParentId(value);
              }}
              disabled={!userAllowed}
            />
          </Card.Body>
        )}
      </Card>
    </Container>
  );
};

export default IdNameForm;
