import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Container, ListGroup, Form } from "react-bootstrap";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import GetEntities from '../GenericComponents/Entities';
import { GetEndPoint } from "../Api/Controllers/Common/EndPoints"
import NewScheduleRow from '../Appointment/NewScheduleRow';
import FillEntity from '../GenericComponents/FillEntity';
import SpanishDateCalendar from '../GenericComponents/SpanishDateCalendar';

const Entity = GetEntities()
dayjs.extend(utc);

const SelfserviceAppointment = () => {
    const fechaActual = dayjs().add(1, 'day').toISOString();
    const [date, setDate] = useState(dayjs(fechaActual));
    const [patient, setPatient] = useState(null);
    const [professionals, setProfessionals] = useState([]);
    const [selectedProfessionalId, setSelectedProfessionalId] = useState('');
    const currentDate = date.format('YYYY-MM-DD');
    const [refresh, setRefresh] = useState(false);
    const [appointments, setAppointments] = useState([]);
    const patientId = JSON.parse(localStorage.getItem("myId"));

    useEffect(() => {
        const fetchData = async () => {
            const apiGetPatient = GetEndPoint(Entity.Paciente);
            const key = { type: "guid", value: patientId };
            const response = await apiGetPatient({ key });
            const responsePatient = FillEntity(Entity.Paciente, response);
            setPatient(responsePatient);
            if (responsePatient.GeneralPractitionersIds) {
                await fetchProfessionals(responsePatient.GeneralPractitionersIds);
            }
        };
        fetchData();
    }, [date, refresh, patientId]);

    const fetchProfessionals = async (generalPractitionerIds) => {
        const apiGetProfessional = GetEndPoint(Entity.Profesional);
        const promises = generalPractitionerIds.map(async id => {
            const key = { type: "guid", value: id };
            const response = await apiGetProfessional({ key });
            return FillEntity(Entity.Profesional, response);
        });
        const fetchedProfessionals = await Promise.all(promises);
        setProfessionals(fetchedProfessionals);
    };

    const fetchGeneralPractitionerSchedule = async (generalPractitionerId) => {
        const apiGeneralPractitionerSchedule = GetEndPoint(Entity.HorarioGeneralProfesional);
        const response = await apiGeneralPractitionerSchedule(currentDate, generalPractitionerId);
        const generalPractitionerSchedule = response.map(a => FillEntity(Entity.HorarioGeneralProfesional, a));
        setAppointments(generalPractitionerSchedule);
    };

    useEffect(() => {
        if (professionals.length > 0 && !selectedProfessionalId) {
            const firstProfessionalId = professionals[0].Id;
            setSelectedProfessionalId(firstProfessionalId);
        }
    }, [professionals, selectedProfessionalId]);

    useEffect(() => {
        if (selectedProfessionalId) {
            fetchGeneralPractitionerSchedule(selectedProfessionalId);
        }
    }, [selectedProfessionalId, date]);

    return (
        <Container>
            <Card>
                <Card.Header>
                    <h2>Turnos</h2>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <Card style={{ width: '320px' }}>
                                <SpanishDateCalendar
                                    value={date}
                                    onChange={(newDate) => setDate(newDate)}
                                    shouldDisableDate={date => dayjs(date).isBefore(dayjs(), 'day')}
                                />
                            </Card>
                        </Col>
                        <Col>
                            <Form.Group controlId="professionalSelect">
                                <Form.Label>Seleccione un médico tratante:</Form.Label>
                                <Form.Select
                                    value={selectedProfessionalId}
                                    onChange={(e) => setSelectedProfessionalId(e.target.value)}
                                    disabled={professionals.length === 0}
                                >
                                    {professionals.map((professional) => (
                                        <option key={professional.Id} value={professional.Id}>
                                            {professional.FullName}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="g-2">
                        {appointments.length > 0 ? (
                            <h4>Turnos disponibles para el día: {dayjs(date).format('DD/MM/YYYY')}</h4>
                        ) : (
                            <h4>No hay turnos disponibles para el día: {dayjs(date).format('DD/MM/YYYY')}</h4>
                        )}
                    </Row>
                    <Row>
                        <ListGroup variant="flush">
                            {appointments.map((schedule, index) => (
                                schedule.IsAvailable && (
                                    <ListGroup.Item key={index}>
                                        <NewScheduleRow
                                            date={schedule.AppointmentDateTime}
                                            professional={professionals.find(pro => pro.Id === selectedProfessionalId)}
                                            refresh={refresh}
                                            setRefresh={setRefresh}
                                            professionalView={false}
                                            isSelfService={true}
                                            selfServicePatient={patient}
                                        />
                                    </ListGroup.Item>
                                )
                            ))}
                        </ListGroup>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default SelfserviceAppointment