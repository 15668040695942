import { Outlet } from 'react-router-dom';
import { UserOperationsAllowedContext } from './UserOperationsAllowedContext';
import { useContext } from 'react';

const ProtectedRoute = ({ requiredOperations }) => {
    const { userOperationsAllowed, hasAllRequiredOperations } = useContext(UserOperationsAllowedContext);
    const userAllowed = hasAllRequiredOperations({
        requiredOperations,
        userOperations: userOperationsAllowed
    });

    if (userAllowed) {
        return <Outlet />;
    }
    return null;

};

export default ProtectedRoute
