class HealthInsuranceCopayment {
    constructor(Id, DateFrom, ServiceId, ServiceName, CareModalityId, 
                CareModalityName, Cost, HealthInsuranceId) {
        this.Id = Id;
        this.DateFrom = DateFrom;
        this.ServiceId = ServiceId;
        this.ServiceName = ServiceName;
        this.CareModalityId = CareModalityId;
        this.CareModalityName = CareModalityName;
        this.Cost = Cost;
        this.HealthInsuranceId = HealthInsuranceId;
    }
}

export default HealthInsuranceCopayment