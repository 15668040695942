class Account {
    constructor(Id, Name, SettingLiveConnectAccessKey, SettingLiveConnectChannelId, SettingLiveConnectPrivateKey, Url, GoogleCloudServiceAccountCredential, WebSiteUrl, PhoneNumber, Email, FullAddressInformation, LegalName){
        this.Id = Id;
        this.Name =Name;
        this.SettingLiveConnectAccessKey = SettingLiveConnectAccessKey;
        this.SettingLiveConnectChannelId = SettingLiveConnectChannelId;
        this.SettingLiveConnectPrivateKey = SettingLiveConnectPrivateKey;
        this.Url = Url;
        this.GoogleCloudServiceAccountCredential = GoogleCloudServiceAccountCredential;
        this.WebSiteUrl = WebSiteUrl;
        this.PhoneNumber = PhoneNumber;
        this.Email = Email;
        this.FullAddressInformation = FullAddressInformation;
        this.LegalName = LegalName;
    }
}

export default Account