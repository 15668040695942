import Get from "../../BaseApiController/Get"

const endPoint = "/api/Appointments/GetMyCalendar/{date}"

async function GetMyCalendar(date) {
    let ensambledEndPoint = endPoint.replace("{date}", date);
    const response = await Get(ensambledEndPoint)
    return response
}

export default GetMyCalendar