import React, { useState, useEffect } from "react";
import { Row, Card, Container } from "react-bootstrap";
import GetEntities from "../GenericComponents/Entities";
import { GetEndPoint } from "../Api/Controllers/Common/EndPoints";
import PersonalInformationForm from "../GenericComponents/PersonalInformationForm";
import FillEntity from "../GenericComponents/FillEntity";

const Entity = GetEntities();

const MyProfile = () => {

    const [user, setUser] = useState({});
    const userId = JSON.parse(localStorage.getItem("myId"));

    const fetchUser = async () => {
        const key = { type: "guid", value: userId }
        const apiGetUser = GetEndPoint(Entity.Usuario)
        const response = await apiGetUser({ key })
        const responseUser = FillEntity(Entity.Usuario, response)
        setUser(responseUser)
    }

    useEffect(() => {
        fetchUser();
    }, []);

    return (
        <Container>
            <Card>
                <Card.Header>
                    <h2>Mi Perfil</h2>
                </Card.Header>
                <Card.Body>
                        <Container>
                            <Row>
                                <PersonalInformationForm userId={userId} user={user} userType={Entity.Usuario} disabled={true} setUser={setUser} />
                            </Row>
                        </Container>
                </Card.Body>
            </Card>
        </Container>
    )

}

export default MyProfile