import React, { useState, useEffect } from 'react';
import { UserOperationsAllowedContext } from './UserOperationsAllowedContext';
import GetCurrentUser from '../Api/Controllers/Security/GetCurrentUser';
import FillEntity from '../GenericComponents/FillEntity';
import GetEntities from '../GenericComponents/Entities';

const Entity = GetEntities();

export const UserOperationsAllowedProvider = ({ children }) => {
    const [userOperationsAllowed, setUserOperationsAllowed] = useState([]);

    const fetchUser = async () => {
        try {
            const response = await GetCurrentUser();
            const responseUser = FillEntity(Entity.Usuario, response.data);
            setUserOperationsAllowed(responseUser?.Operations);
        } catch (error) {
            console.error(error);
        }
    };

    const hasAllRequiredOperations = ({ requiredOperations, userOperations }) => {
        if (requiredOperations == null || userOperations == null) {
            console.warn("hasAllRequiredOperations recibió un valor nulo o indefinido");
            return false;
        }

        return requiredOperations.every(op => userOperations.includes(op));
    }

    useEffect(() => {
        fetchUser();
    }, []);

    return (
        <UserOperationsAllowedContext.Provider value={{ userOperationsAllowed, hasAllRequiredOperations }}>
            {children}
        </UserOperationsAllowedContext.Provider>
    );
};

