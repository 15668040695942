import * as React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom'
import ErrorPage from '../CustomErrorPage/ErrorPage';
import CardTableSearch from './../../components/TableSearch/CardTableSearch';
import ServiceForm from '../Services/ServiceForm';
import PersistLogin from '../Login/PersistLogin';
import Role from './../../components/Role/Role';
import IdNameForm from './../../components/GenericComponents/IdNameForm';
import HealthInsuranceIdNameForm from '../GenericComponents/HealthInsuranceIdNameForm';
import Patient from '../Patient/Patient';
import Profesional from '../Professional/Professional';
import Profile from '../Profiles/Profile';
import User from '../User/User';
import GetEntities from '../GenericComponents/Entities';
import Home from '../Home/Home';
import { NewEntityPath, SearchPath, EditEntityPath } from '../Api/Controllers/Common/Paths';
import Appointment from '../Appointment/Appointment';
import MyCalendar from '../MyCalendar/MyCalendar'
import MyAppointment from '../MyAppointments/MyAppointment';
import ChangePassword from '../ChangePassword/ChangePassword';
import SelfserviceAppointment from '../SelfserviceAppointment/SelfserviceAppointment';
import SendMessage from '../TestWppAndMail/SendMessage';
import AllAppointments from '../Appointment/AllAppointments';
import MyProfile from '../MyProfile/MyProfile';
import ProtectedRoute from './ProtectedRoute';
import GetUserOperationsAllowed from '../GenericComponents/userOperationsAllowed';
import ConfigurationPage from '../Configuration/ConfigurationPage';
import PatientHelp from '../Help/PatientHelp'

const Entities = GetEntities();
const Operations = GetUserOperationsAllowed();

export default function Content() {

  const location = useLocation();
  React.useEffect(() => {
  }, [location]);

  return (

    <div className="Dashboard">
      <Routes>

        {/*Proteced Routes*/}
        <Route element={<PersistLogin key={location.pathname} />}>

          <Route path='/Home' element={<Home />} />

          <Route path={SearchPath(Entities.Cuenta)} element={<ConfigurationPage />} />
          <Route path={SearchPath(Entities.MiPerfil)} element={<MyProfile />} />
          <Route path={SearchPath(Entities.Test)} element={<SendMessage />} />
          <Route path={SearchPath(Entities.AutogestionTurno)} element={<SelfserviceAppointment />} />
          <Route path='/AyudaPaciente' element={<PatientHelp />} />
          <Route path={SearchPath(Entities.MisTurnos)} element={<MyAppointment />} />
          <Route path={SearchPath(Entities.Password)} element={<ChangePassword />} />

          {/* ROL */}
          <Route path={SearchPath(Entities.Rol)} element={<ProtectedRoute requiredOperations={[Operations.SearchRole]} />}>
            <Route index element={<CardTableSearch title="Roles" entity={Entities.Rol} key={Entities.Rol} requiredOperations={[Operations.ManageRole]} />} />
          </Route>
          <Route path={EditEntityPath(Entities.Rol) + ':rolId'} element={<ProtectedRoute requiredOperations={[Operations.SearchRole]} />}>
            <Route index element={<Role requiredOperations={[Operations.ManageRole]} />} />
          </Route>
          <Route path={NewEntityPath(Entities.Rol)} element={<ProtectedRoute requiredOperations={[Operations.SearchRole]} />}>
            <Route index element={<Role requiredOperations={[Operations.ManageRole]} />} />
          </Route>

          {/* PACIENTE */}
          <Route path={SearchPath(Entities.Paciente)} element={<ProtectedRoute requiredOperations={[Operations.SearchPatient]} />}>
            <Route index element={<CardTableSearch title="Pacientes" entity={Entities.Paciente} key={Entities.Paciente} requiredOperations={[Operations.ManagePatient]} />} />
          </Route>
          <Route path={NewEntityPath(Entities.Paciente)} element={<ProtectedRoute requiredOperations={[Operations.SearchPatient]} />}>
            <Route index element={<Patient requiredOperations={[Operations.ManagePatient]} />} />
          </Route>
          <Route path={EditEntityPath(Entities.Paciente) + ':patientId'} element={<ProtectedRoute requiredOperations={[Operations.SearchPatient]} />}>
            <Route index element={<Patient requiredOperations={[Operations.ManagePatient]} />} />
          </Route>

          {/* PAIS */}
          <Route path={NewEntityPath(Entities.Pais)} element={<ProtectedRoute requiredOperations={[Operations.SearchCountry]} />}>
            <Route index element={<IdNameForm type={Entities.Pais} title="Pais" requiredOperations={[Operations.ManageCountry]} />} />
          </Route>
          <Route path={SearchPath(Entities.Pais)} element={<ProtectedRoute requiredOperations={[Operations.SearchCountry]} />}>
            <Route index element={<CardTableSearch title="Paises" entity={Entities.Pais} key={Entities.Pais} requiredOperations={[Operations.ManageCountry]} />} />
          </Route>
          <Route path={EditEntityPath(Entities.Pais) + ':id'} element={<ProtectedRoute requiredOperations={[Operations.SearchCountry]} />}>
            <Route index element={<IdNameForm type={Entities.Pais} title="Pais" requiredOperations={[Operations.ManageCountry]} />} />
          </Route>

          {/* OBRA SOCIAL */}
          <Route path={NewEntityPath(Entities.ObraSocial)} element={<ProtectedRoute requiredOperations={[Operations.SearchHealthInsurance]} />}>
            <Route index element={<HealthInsuranceIdNameForm type={Entities.ObraSocial} title="Obra Social" requiredOperations={[Operations.ManageHealthInsurance]} />} />
          </Route>
          <Route path={SearchPath(Entities.ObraSocial)} element={<ProtectedRoute requiredOperations={[Operations.SearchHealthInsurance]} />}>
            <Route index element={<CardTableSearch title="Obras Sociales" entity={Entities.ObraSocial} key={Entities.ObraSocial} requiredOperations={[Operations.ManageHealthInsurance]} />} />
          </Route>
          <Route path={EditEntityPath(Entities.ObraSocial) + ':id'} element={<ProtectedRoute requiredOperations={[Operations.SearchHealthInsurance]} />}>
            <Route index element={<HealthInsuranceIdNameForm type={Entities.ObraSocial} title="Obra Social" requiredOperations={[Operations.ManageHealthInsurance]} />} />
          </Route>

          {/* PROVINCIA */}
          <Route path={SearchPath(Entities.Provincia)} element={<ProtectedRoute requiredOperations={[Operations.SearchProvince]} />}>
            <Route index element={<CardTableSearch title="Provincias" entity={Entities.Provincia} parentEntity={Entities.Pais} key={Entities.Provincia} requiredOperations={[Operations.ManageProvince]} />} />
          </Route>
          <Route path={NewEntityPath(Entities.Provincia)} element={<ProtectedRoute requiredOperations={[Operations.SearchProvince]} />}>
            <Route index element={<IdNameForm type={Entities.Provincia} parentType={Entities.Pais} title="Provincia" requiredOperations={[Operations.ManageProvince]} />} />
          </Route>
          <Route path={EditEntityPath(Entities.Provincia) + ':id'} element={<ProtectedRoute requiredOperations={[Operations.SearchProvince]} />}>
            <Route index element={<IdNameForm type={Entities.Provincia} parentType={Entities.Pais} title="Provincia" requiredOperations={[Operations.ManageProvince]} />} />
          </Route>

          {/* LOCALIDAD */}
          <Route path={SearchPath(Entities.Localidad)} element={<ProtectedRoute requiredOperations={[Operations.SearchTown]} />}>
            <Route index element={<CardTableSearch title="Localidades" entity={Entities.Localidad} parentEntity={Entities.Provincia} key={Entities.Localidad} requiredOperations={[Operations.ManageTown]} />} />
          </Route>
          <Route path={NewEntityPath(Entities.Localidad)} element={<ProtectedRoute requiredOperations={[Operations.SearchTown]} />}>
            <Route index element={<IdNameForm type={Entities.Localidad} parentType={Entities.Provincia} title={Entities.Localidad} requiredOperations={[Operations.ManageTown]} />} />
          </Route>
          <Route path={EditEntityPath(Entities.Localidad) + ':id'} element={<ProtectedRoute requiredOperations={[Operations.SearchTown]} />}>
            <Route index element={<IdNameForm type={Entities.Localidad} parentType={Entities.Provincia} title="Localidad" requiredOperations={[Operations.ManageTown]} />} />
          </Route>

          {/* TIPO DE DOCUMENTO */}
          <Route path={SearchPath(Entities.TipoDocumento)} element={<ProtectedRoute requiredOperations={[Operations.SearchDocumentType]} />}>
            <Route index element={<CardTableSearch title="Tipo de Documentos" entity={Entities.TipoDocumento} key={Entities.TipoDocumento} requiredOperations={[Operations.ManageDocumentType]} />} />
          </Route>
          <Route path={EditEntityPath(Entities.TipoDocumento) + ':id'} element={<ProtectedRoute requiredOperations={[Operations.SearchDocumentType]} />}>
            <Route index element={<IdNameForm type={Entities.TipoDocumento} title="Tipo de documento" requiredOperations={[Operations.ManageDocumentType]} />} />
          </Route>
          <Route path={NewEntityPath(Entities.TipoDocumento)} element={<ProtectedRoute requiredOperations={[Operations.SearchDocumentType]} />}>
            <Route index element={<IdNameForm type={Entities.TipoDocumento} title="Tipo de documento" requiredOperations={[Operations.ManageDocumentType]} />} />
          </Route>

          {/* PROFESIONAL */}
          <Route path={SearchPath(Entities.Profesional)} element={<ProtectedRoute requiredOperations={[Operations.SearchProfessional]} />}>
            <Route index element={<CardTableSearch title="Profesionales" entity={Entities.Profesional} key={Entities.Profesional} showActionsDropdown={true} requiredOperations={[Operations.ManageProfessional]} orderBy={['IsActive desc']} />} />
          </Route>
          <Route path={NewEntityPath(Entities.Profesional)} element={<ProtectedRoute requiredOperations={[Operations.SearchProfessional]} />}>
            <Route index element={<Profesional requiredOperations={[Operations.ManageProfessional]} />} />
          </Route>
          <Route path={EditEntityPath(Entities.Profesional) + ':professionalId'} element={<ProtectedRoute requiredOperations={[Operations.SearchProfessional]} />}>
            <Route index element={<Profesional requiredOperations={[Operations.ManageProfessional]} />} />
          </Route>

          {/* PARENTEZCO */}
          <Route path={SearchPath(Entities.Parentesco)} element={<ProtectedRoute requiredOperations={[Operations.SearchRelationship]} />}>
            <Route index element={<CardTableSearch title="Parentescos" entity={Entities.Parentesco} key={Entities.Parentesco} requiredOperations={[Operations.ManageRelationship]} />} />
          </Route>
          <Route path={EditEntityPath(Entities.Parentesco) + ':id'} element={<ProtectedRoute requiredOperations={[Operations.SearchRelationship]} />}>
            <Route index element={<IdNameForm type={Entities.Parentesco} title="Parentescos" requiredOperations={[Operations.ManageRelationship]} />} />
          </Route>
          <Route path={NewEntityPath(Entities.Parentesco)} element={<ProtectedRoute requiredOperations={[Operations.SearchRelationship]} />}>
            <Route index element={<IdNameForm type={Entities.Parentesco} title="Parentescos" requiredOperations={[Operations.ManageRelationship]} />} />
          </Route>

          { /* Genero */}
          <Route path={SearchPath(Entities.Genero)}>
            <Route index element={<CardTableSearch title="Genero" entity={Entities.Genero} key={Entities.Genero} />} />
          </Route>
          <Route path={EditEntityPath(Entities.Genero) + ':id'}>
            <Route index element={<IdNameForm type={Entities.Genero} title="Genero" />} />
          </Route>
          <Route path={NewEntityPath(Entities.Genero)}>
            <Route index element={<IdNameForm type={Entities.Genero} title="Genero" />} />
          </Route>

          {/* PERFIL */}
          <Route path={SearchPath(Entities.Perfil)} element={<ProtectedRoute requiredOperations={[Operations.SearchProfile]} />}>
            <Route index element={<CardTableSearch title="Perfiles" entity={Entities.Perfil} key={Entities.Perfil} requiredOperations={[Operations.ManageProfile]} />} />
          </Route>
          <Route path={EditEntityPath(Entities.Perfil) + ':profileId'} element={<ProtectedRoute requiredOperations={[Operations.SearchProfile]} />}>
            <Route index element={<Profile requiredOperations={[Operations.ManageProfile]} />} />
          </Route>
          <Route path={NewEntityPath(Entities.Perfil)} element={<ProtectedRoute requiredOperations={[Operations.SearchProfile]} />}>
            <Route index element={<Profile requiredOperations={[Operations.ManageProfile]} />} />
          </Route>

          {/* ASISTENCIA */}
          <Route path={NewEntityPath(Entities.Asistencia)} element={<ProtectedRoute requiredOperations={[Operations.SearchAssistance]} />}>
            <Route index element={<IdNameForm type={Entities.Asistencia} title="Asistencia" requiredOperations={[Operations.ManageAssistance]} />} />
          </Route>
          <Route path={SearchPath(Entities.Asistencia)} element={<ProtectedRoute requiredOperations={[Operations.SearchAssistance]} />}>
            <Route index element={<CardTableSearch title="Asistencias" entity={Entities.Asistencia} key={Entities.Asistencia} requiredOperations={[Operations.ManageAssistance]} />} />
          </Route>
          <Route path={EditEntityPath(Entities.Asistencia) + ':id'} element={<ProtectedRoute requiredOperations={[Operations.SearchAssistance]} />}>
            <Route index element={<IdNameForm type={Entities.Asistencia} title="Asistencia" requiredOperations={[Operations.ManageAssistance]} />} />
          </Route>

          {/* SEDE */}
          <Route path={NewEntityPath(Entities.Sede)} element={<ProtectedRoute requiredOperations={[Operations.SearchSite]} />}>
            <Route index element={<IdNameForm type={Entities.Sede} title="Sede" requiredOperations={[Operations.ManageSite]} />} />
          </Route>
          <Route path={SearchPath(Entities.Sede)} element={<ProtectedRoute requiredOperations={[Operations.SearchSite]} />}>
            <Route index element={<CardTableSearch title="Sedes" entity={Entities.Sede} key={Entities.Sede} requiredOperations={[Operations.ManageSite]} />} />
          </Route>
          <Route path={EditEntityPath(Entities.Sede) + ':id'} element={<ProtectedRoute requiredOperations={[Operations.SearchSite]} />}>
            <Route index element={<IdNameForm type={Entities.Sede} title="Sede" requiredOperations={[Operations.ManageSite]} />} />
          </Route>

          {/* CONSULTORIO */}
          <Route path={SearchPath(Entities.Consultorio)} element={<ProtectedRoute requiredOperations={[Operations.SearchRoom]} />}>
            <Route index element={<CardTableSearch title="Consultorios" entity={Entities.Consultorio} parentEntity={Entities.Sede} key={Entities.Consultorio} requiredOperations={[Operations.ManageRoom]} />} />
          </Route>
          <Route path={NewEntityPath(Entities.Consultorio)} element={<ProtectedRoute requiredOperations={[Operations.SearchRoom]} />}>
            <Route index element={<IdNameForm type={Entities.Consultorio} parentType={Entities.Sede} title="Consultorio" requiredOperations={[Operations.ManageRoom]} />} />
          </Route>
          <Route path={EditEntityPath(Entities.Consultorio) + ':id'} element={<ProtectedRoute requiredOperations={[Operations.SearchRoom]} />}>
            <Route index element={<IdNameForm type={Entities.Consultorio} parentType={Entities.Sede} title="Consultorio" requiredOperations={[Operations.ManageRoom]} />} />
          </Route>

          {/* PRESTACION */}
          <Route path={NewEntityPath(Entities.Prestacion)} element={<ProtectedRoute requiredOperations={[Operations.SearchService]} />}>
            <Route index element={<ServiceForm requiredOperations={[Operations.ManageService]} />} />
          </Route>
          <Route path={SearchPath(Entities.Prestacion)} element={<ProtectedRoute requiredOperations={[Operations.SearchService]} />}>
            <Route index element={<CardTableSearch title="Prestaciones" entity={Entities.Prestacion} key={Entities.Prestacion} parentEntity={Entities.ModalidadAtencion} requiredOperations={[Operations.ManageService]} />} />
          </Route>
          <Route path={EditEntityPath(Entities.Prestacion) + ':id'} element={<ProtectedRoute requiredOperations={[Operations.SearchService]} />}>
            <Route index element={<ServiceForm requiredOperations={[Operations.ManageService]} />} />
          </Route>

          {/* MODALIDAD DE ATENCION */}
          <Route path={NewEntityPath(Entities.ModalidadAtencion)} element={<ProtectedRoute requiredOperations={[Operations.SearchCareModality]} />}>
            <Route index element={<IdNameForm type={Entities.ModalidadAtencion} title="Modalidad de Atencion" requiredOperations={[Operations.ManageCareModality]} />} />
          </Route>
          <Route path={SearchPath(Entities.ModalidadAtencion)} element={<ProtectedRoute requiredOperations={[Operations.SearchCareModality]} />}>
            <Route index element={<CardTableSearch title="Modalidad de Atencion" entity={Entities.ModalidadAtencion} key={Entities.ModalidadAtencion} requiredOperations={[Operations.ManageCareModality]} />} />
          </Route>
          <Route path={EditEntityPath(Entities.ModalidadAtencion) + ':id'} element={<ProtectedRoute requiredOperations={[Operations.SearchCareModality]} />}>
            <Route index element={<IdNameForm type={Entities.ModalidadAtencion} title="Modalidad de Atencion" requiredOperations={[Operations.ManageCareModality]} />} />
          </Route>

          {/* ESPECIALDIAD */}
          <Route path={NewEntityPath(Entities.Especialidad)} element={<ProtectedRoute requiredOperations={[Operations.SearchSpecialty]} />}>
            <Route index element={<IdNameForm type={Entities.Especialidad} title="Especialidad" requiredOperations={[Operations.ManageSpecialty]} />} />
          </Route>
          <Route path={SearchPath(Entities.Especialidad)} element={<ProtectedRoute requiredOperations={[Operations.SearchSpecialty]} />}>
            <Route index element={<CardTableSearch title="Especialidad" entity={Entities.Especialidad} key={Entities.Especialidad} requiredOperations={[Operations.ManageSpecialty]} />} />
          </Route>
          <Route path={EditEntityPath(Entities.Especialidad) + ':id'} element={<ProtectedRoute requiredOperations={[Operations.SearchSpecialty]} />}>
            <Route index element={<IdNameForm type={Entities.Especialidad} title="Especialidad" requiredOperations={[Operations.ManageSpecialty]} />} />
          </Route>

          {/* USUARIO */}
          <Route path={SearchPath(Entities.Usuario)} element={<ProtectedRoute requiredOperations={[Operations.SearchUser]} />}>
            <Route index element={<CardTableSearch title="Usuarios" entity={Entities.Usuario} key={Entities.Usuario} requiredOperations={[Operations.ManageUser]} />} />
          </Route>
          <Route path={EditEntityPath(Entities.Usuario) + ':userId'} element={<ProtectedRoute requiredOperations={[Operations.SearchUser]} />}>
            <Route index element={<User requiredOperations={[Operations.ManageUser]} />} />
          </Route>
          <Route path={NewEntityPath(Entities.Usuario)} element={<ProtectedRoute requiredOperations={[Operations.SearchUser]} />}>
            <Route index element={<User requiredOperations={[Operations.ManageUser]} />} />
          </Route>

          {/* TURNO */}
          <Route path={SearchPath(Entities.Turno)} element={<ProtectedRoute requiredOperations={[Operations.SearchAppointment]} />}>
            <Route index element={<Appointment requiredOperations={[Operations.ManageAppointment]} />} />
          </Route>
          <Route path={SearchPath(Entities.Turno)} element={<ProtectedRoute requiredOperations={[Operations.SearchAppointment]} />}>
            <Route index element={<Appointment requiredOperations={[Operations.ManageAppointment]} />} />
          </Route>

          <Route path={SearchPath(Entities.MiAgenda)} element={<MyCalendar />} />
          <Route path={SearchPath(Entities.Turnos)} element={<AllAppointments />} />
        </Route>
        {/*Catchall Route*/}
        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </div>
  );
}