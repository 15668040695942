import Put from "../../BaseApiController/Put"

const endPoint = "/api/Professionals"

async function PutProfessional(queryObj, body) {
    const successMessage = "Profesional modificado con exito.";
    const professional = await Put(endPoint, queryObj, body, null, successMessage)
    return professional
}

export default PutProfessional