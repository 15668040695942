import Put from "../../BaseApiController/Put"

const endPoint = "/api/Services"

async function PutServices(queryObj, body) {
    const successMessage = "Prestacion modificada con exito.";
    const response = await Put(endPoint, queryObj, body, null, successMessage)
    return response
}

export default PutServices