import React, { useState, useRef, useEffect } from "react";
import { Form, Container, Card, Spinner, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PostUploadedFiles from "../Api/Controllers/UploadedFiles/PostUploadedFiles";
import DeleteUser from "../Api/Controllers/Users/DeleteUser";
import PutUser from "../Api/Controllers/Users/PutUser";
import PostUser from "../Api/Controllers/Users/PostUser";
import DeleteUploadedFiles from "../Api/Controllers/UploadedFiles/DeleteUploadedFiles";
import Toolbar from "../GenericComponents/ToolBar";
import GetUser from "../Api/Controllers/Users/GetUser";
import GetProfile from "../Api/Controllers/Profiles/GetProfile";
import PersonalInformationForm from "../GenericComponents/PersonalInformationForm";
import AttachmentFiles from "../GenericComponents/AttachmentFiles";
import { NewEntityPath, EditEntityPath } from "../Api/Controllers/Common/Paths";
import GetEntities from "../GenericComponents/Entities";
import DeleteWarning from "../GenericComponents/DeleteWarning";
import FillEntity from "../GenericComponents/FillEntity";
import { PostEndPoint } from "../Api/Controllers/Common/EndPoints";
import Warning from "../GenericComponents/Warning";

const entity = GetEntities().Usuario;
const newEntityPath = NewEntityPath(entity);
const editEntityPath = EditEntityPath(entity);
const resetPasswordEndpoint = PostEndPoint(GetEntities().ResetPassword);

const getEmptyUser = () => {
  return {
    Profile: "",
  };
};

const FormUser = ({ userId, userAllowed }) => {
  let navigate = useNavigate();
  const [user, setUser] = useState(getEmptyUser());
  const [refresh, setRefresh] = useState(true);
  const [preview, setPreview] = useState("");
  const [imgProfile, setimgProfile] = useState("");
  const [validated, setValidated] = useState(false);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [refreshDropDown, setrefreshDropDown] = useState(false);
  const [woSavingAttachmentFiles, setWoSavingAttachmentFiles] = useState([]);
  const [deleteAttachmentFiles, setDeleteAttachmentFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const form = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUser = async () => {
    try {
      setLoading(true);
      const key = { type: "guid", value: userId };
      const json = await GetUser({ key });
      const userResponse = FillEntity(entity, json);
      setUser(userResponse);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async () => {
    const key = { type: "guid", value: userId };
    await DeleteUser({ key });
    navigate(newEntityPath);
    userId = undefined;
    setRefresh(!refresh);
  };

  const deleteAttachments = () => {
    deleteAttachmentFiles.map(async (file) => {
      const obj = { relatedEntityId: file };
      await DeleteUploadedFiles(obj);
    });
  };

  const saveAttachments = async (id) => {
    const obj = { relatedEntityId: id, uploadedFileType: 1 };
    let formData = new FormData();
    woSavingAttachmentFiles.map((file) => formData.append("files", file));

    await PostUploadedFiles(obj, formData);
    setWoSavingAttachmentFiles([]);
    setRefresh(!refresh);
  };

  const saveImgProfile = async (id) => {
    const obj = { relatedEntityId: id, uploadedFileType: 0 };
    let formData = new FormData();
    formData.append("files", imgProfile);
    await PostUploadedFiles(obj, formData);
  };

  const handleSaveClick = async (event) => {
    setIsLoading(true);
    try {
      setValidated(form.current.checkValidity() === false);
      if (form.current.checkValidity() === false) {
        event.stopPropagation();
      } else {
        setLoading(true);
        if (userId !== undefined) {
          const key = { type: "guid", value: userId };
          await PutUser({ key }, user);
          if (imgProfile) {
            await saveImgProfile(userId);
          }
          if (woSavingAttachmentFiles) {
            await saveAttachments(userId);
          }
          if (deleteAttachmentFiles) {
            await deleteAttachments();
          }
        } else {
          const newUser = await PostUser(user);
          const id = newUser.Id;
          if (imgProfile) {
            await saveImgProfile(id);
          }
          if (woSavingAttachmentFiles) {
            await saveAttachments(id);
          }
          if (newUser) {
            navigate(`${editEntityPath}${id}`, { state: { id: id } });
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
  };

  const fetchProfiles = async () => {
    const response = await GetProfile();
    const profiles = response.value.map((profile) => {
      return {
        Name: profile.Name,
        Id: profile.Id,
      };
    });
    setProfiles(profiles);
  };

  const fillDropdowns = () => {
    fetchProfiles();
  };

  useEffect(() => {
    if (userId !== undefined) {
      fetchUser();
    } else {
      setUser(getEmptyUser());
    }
  }, [refresh]);

  useEffect(() => {
    fillDropdowns();
  }, [refreshDropDown]);

  if (loading) {
    return <Spinner animation="border" variant="secondary" />;
  }

  return (
    <Container>
      <Card>
        <Card.Header>
          {!userId ? (
            <Toolbar
              title="Crear Usuario"
              entity={entity}
              saveAction={handleSaveClick}
              setRefresh={setRefresh}
              refresh={refresh}
              showBackButton={true}
              showSaveButton={userAllowed}
              key="personalInfo"
              disabled={isLoading}
            />
          ) : (
            <Toolbar
              title="Editar Usuario"
              entity={entity}
              deleteAction={() => DeleteWarning(deleteUser)}
              saveAction={handleSaveClick}
              setRefresh={setRefresh}
              refresh={refresh}
              showBackButton={true}
              showSaveButton={userAllowed}
              showNewButton={userAllowed}
              showDeleteButton={userAllowed}
              showRefreshButton={true}
              key="personalInfo"
              disabled={isLoading}
            />
          )}
        </Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} key="formUser" ref={form}>
            <Container>
              <Row>
                <PersonalInformationForm
                  userId={userId}
                  user={user}
                  setUser={setUser}
                  preview={preview}
                  setPreview={setPreview}
                  validated={validated}
                  setValidated={setValidated}
                  refresh={refresh}
                  setimgProfile={setimgProfile}
                  setRefresh={setRefresh}
                  userType="Usuario"
                  disabled={!userAllowed}
                />
              </Row>
            </Container>
          </Form>
        </Card.Body>
        <Card.Body>
          <AttachmentFiles
            userId={userId}
            attachmentFiles={attachmentFiles}
            setAttachmentFiles={setAttachmentFiles}
            woSavingAttachmentFiles={woSavingAttachmentFiles}
            setWoSavingAttachmentFiles={setWoSavingAttachmentFiles}
            deleteAttachmentFiles={deleteAttachmentFiles}
            setDeleteAttachmentFiles={setDeleteAttachmentFiles}
            refresh={refresh}
            disabled={!userAllowed}
            uploadedFileType={1}
          />
        </Card.Body>
        <Card.Body>
          <Button
            variant="warning"
            onClick={() =>
              Warning(
                () => resetPasswordEndpoint(userId),
                "¿Estas seguro que queres restablecer la Contraseña?",
                "Restablecer Contraseña"
              )
            }
          >
            Restablecer Contraseña
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default FormUser;
