class MyAppointmentsFuture {
    constructor(id, patientName, patientId, professionalId, professionalName, careModalityId, careModalityName,
        serviceId, serviceName, healthInsuranceId, healthInsuranceName, healthInsuranceNumber, appointmentDateTime,
        siteId, siteName, assistanceId, assistanceName, roomId, roomName, hc, isOnline, zoomEmail, meetingNumber, accessCode, OnlineMeetingLink) {
        this.Id = id;
        this.PatientName = patientName;
        this.PatientId = patientId;
        this.ProfessionalId = professionalId;
        this.ProfessionalName = professionalName;
        this.CareModalityId = careModalityId;
        this.CareModalityName = careModalityName;
        this.ServiceId = serviceId;
        this.ServiceName = serviceName;
        this.HealthInsuranceId = healthInsuranceId;
        this.HealthInsuranceName = healthInsuranceName;
        this.HealthInsuranceNumber = healthInsuranceNumber;
        this.AppointmentDateTime = appointmentDateTime;
        this.SiteId = siteId;
        this.SiteName = siteName;
        this.AssistanceId = assistanceId;
        this.AssistanceName = assistanceName;
        this.RoomId = roomId;
        this.RoomName = roomName;
        this.HC = hc;
        this.IsOnline = isOnline;
        this.ZoomEmail = zoomEmail;
        this.MeetingNumber = meetingNumber;
        this.AccessCode = accessCode;
        this.OnlineMeetingLink = OnlineMeetingLink;
    }
}

export default MyAppointmentsFuture