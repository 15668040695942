const GetUserOperationsAllowed = () => {
  return {
    SearchPatient: "SearchPatient",
    ManagePatient: "ManagePatient",
    SearchAppointment: "SearchAppointment",
    ManageAppointment: "ManageAppointment",
    SearchUser: "SearchUser",
    ManageUser: "ManageUser",
    SearchRole: "SearchRole",
    ManageRole: "ManageRole",
    SearchProfile: "SearchProfile",
    ManageProfile: "ManageProfile",
    SearchProfessional: "SearchProfessional",
    ManageProfessional: "ManageProfessional",
    SearchCountry: "SearchCountry",
    ManageCountry: "ManageCountry",
    SearchDocumentType: "SearchDocumentType",
    ManageDocumentType: "ManageDocumentType",
    SearchProvince: "SearchProvince",
    ManageProvince: "ManageProvince",
    SearchTown: "SearchTown",
    ManageTown: "ManageTown",
    SearchRelationship: "SearchRelationship",
    ManageRelationship: "ManageRelationship",
    SearchSpecialty: "SearchSpecialty",
    ManageSpecialty: "ManageSpecialty",
    SearchCareModality: "SearchCareModality",
    ManageCareModality: "ManageCareModality",
    SearchSite: "SearchSite",
    ManageSite: "ManageSite",
    SearchService: "SearchService",
    ManageService: "ManageService",
    SearchAssistance: "SearchAssistance",
    ManageAssistance: "ManageAssistance",
    SearchProfessionalSchedule: "SearchProfessionalSchedule",
    ManageProfessionalSchedule: "ManageProfessionalSchedule",
    SearchHealthInsurance: "SearchHealthInsurance",
    ManageHealthInsurance: "ManageHealthInsurance",
    SearchHealthInsuranceCoPayment: "SearchHealthInsuranceCoPayment",
    ManageHealthInsuranceCoPayment: "ManageHealthInsuranceCoPayment",
    SearchRoom: "SearchRoom",
    ManageRoom: "ManageRoom",
    GetStatistics: "GetStatistics",
    ManagePrescription: "ManagePrescription",
    SearchPrescription: "SearchPrescription"
  };
};

export default GetUserOperationsAllowed;
