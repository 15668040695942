import { useState, useEffect, useContext, createContext } from 'react';
import TokenService from './TokenService';
import HandleError from '../components/Api/BaseApiController/ErrorHandling';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authenticate = async () => {
      try {
        const authValid = await TokenService.initAuth();
        setIsAuthenticated(authValid);
      } catch (error) {
        console.error(error);
        HandleError(401);
      }
    };

    authenticate();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
