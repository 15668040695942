import GetEntities from "./Entities";
import { PatchEndPoint } from "../Api/Controllers/Common/EndPoints";

const Entity = GetEntities();

const Actions = new Map();
Actions.set(Entity.Profesional,
    [
        {
            label: "Activar",
            action: async ({ obj, refresh, setRefresh }) => {
                for (const professionalId of obj) {
                    const patchBody = [{
                        op: "replace",
                        path: "/IsActive",
                        value: true
                    }];
                    const key = { type: "guid", value: professionalId };
                    const patchApi = PatchEndPoint(Entity.Profesional);
                    const responseBody = await patchApi({ key }, patchBody);
                    if (!responseBody) {
                        console.warn("Hubo un error al activar el profesional " + professionalId);
                    }
                };
                setRefresh(!refresh);
            }
        },
        {
            label: "Inactivar",
            action: async ({ obj, refresh, setRefresh }) => {
                for (const professionalId of obj) {
                    const patchBody = [{
                        op: "replace",
                        path: "/IsActive",
                        value: false
                    }];
                    const key = { type: "guid", value: professionalId };
                    const patchApi = PatchEndPoint(Entity.Profesional);
                    const responseBody = await patchApi({ key }, patchBody);
                    if (!responseBody) {
                        console.warn("Hubo un error al inactivar el profesional " + professionalId);
                    }
                };
                setRefresh(!refresh);
            }
        }
    ]
)

const ActionHandler = ({ entity }) => {
    const actions = Actions.get(entity);
    return actions;
}

export default ActionHandler;