import React, { useState, useRef } from "react";
import { Form, Container, Card, Spinner, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PostPatient from "../../Api/Controllers/Patients/PostPatient";
import PostUploadedFiles from "../../Api/Controllers/UploadedFiles/PostUploadedFiles";
import PutPatient from "../../Api/Controllers/Patients/PutPatient";
import DeletePatient from "../../Api/Controllers/Patients/DeletePatient";
import DeleteUploadedFiles from "../../Api/Controllers/UploadedFiles/DeleteUploadedFiles";
import Toolbar from "../../GenericComponents/ToolBar";
import AttachmentFiles from "../../GenericComponents/AttachmentFiles";
import PersonalInformationForm from "../../GenericComponents/PersonalInformationForm";
import GetEntities from "../../GenericComponents/Entities";
import {
  EditEntityPath,
  NewEntityPath,
} from "../../Api/Controllers/Common/Paths";
import DeleteWarning from "../../GenericComponents/DeleteWarning";
import { useUnsavedChanges } from "../../Context/UnsavedChangesContext";
import { PostEndPoint } from "../../Api/Controllers/Common/EndPoints";
import Warning from "../../GenericComponents/Warning";

const entity = GetEntities().Paciente;
const newEntityPath = NewEntityPath(entity);
const editEntityPath = EditEntityPath(entity);
const resetPasswordEndpoint = PostEndPoint(GetEntities().ResetPassword);

const PersonalInformation = ({ patientId, userAllowed = true }) => {
  let navigate = useNavigate();
  const [patient, setPatient] = useState("");
  const [refresh, setRefresh] = useState(true);
  const [preview, setPreview] = useState("");
  const [imgProfile, setimgProfile] = useState("");
  const [validated, setValidated] = useState(false);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [woSavingAttachmentFiles, setWoSavingAttachmentFiles] = useState([]);
  const [deleteAttachmentFiles, setDeleteAttachmentFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const form = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const { setHasUnsavedChanges } = useUnsavedChanges();

  const deletePatient = async () => {
    const key = { type: "guid", value: patientId };
    await DeletePatient({ key });
    navigate(newEntityPath);
    patientId = undefined;
    setRefresh(!refresh);
  };

  const deleteAttachments = () => {
    deleteAttachmentFiles.map(async (file) => {
      const obj = { relatedEntityId: file };
      await DeleteUploadedFiles(obj);
    });
  };

  const saveAttachments = async (id) => {
    const obj = { relatedEntityId: id, uploadedFileType: 1 };
    let formData = new FormData();
    woSavingAttachmentFiles.map((file) => {
      return formData.append("files", file);
    });

    await PostUploadedFiles(obj, formData);
    setWoSavingAttachmentFiles([]);
    setRefresh(!refresh);
    setHasUnsavedChanges(false);
  };

  const saveImgProfile = async (id) => {
    const obj = { relatedEntityId: id, uploadedFileType: 0 };
    let formData = new FormData();
    formData.append("files", imgProfile);
    await PostUploadedFiles(obj, formData);
    setHasUnsavedChanges(false);
  };

  const handleSaveClick = async (event) => {
    setIsLoading(true);
    try {
      setValidated(form.current.checkValidity() === false);
      if (form.current.checkValidity() === false) {
        event.stopPropagation();
      } else {
        setLoading(true);
        if (patientId !== undefined) {
          const key = { type: "guid", value: patientId };
          await PutPatient({ key }, patient);
          if (imgProfile) {
            await saveImgProfile(patientId);
          }
          if (woSavingAttachmentFiles) {
            await saveAttachments(patientId);
          }
          if (deleteAttachmentFiles) {
            deleteAttachments();
          }
        } else {
          const newPatient = await PostPatient(patient);
          if (newPatient) {
            const id = newPatient.Id;
            if (imgProfile) {
              await saveImgProfile(id);
            }
            if (woSavingAttachmentFiles) {
              await saveAttachments(id);
            }
            navigate(`${editEntityPath}${id}`, { state: { id: id } });
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    setHasUnsavedChanges(false);
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
  };

  if (loading) {
    return <Spinner animation="border" variant="secondary" />;
  }

  return (
    <Container>
      <Card>
        <Card.Header>
          {!patientId ? (
            <Toolbar
              title="Crear Paciente"
              entity={entity}
              saveAction={handleSaveClick}
              setRefresh={setRefresh}
              refresh={refresh}
              showBackButton={true}
              showSaveButton={userAllowed}
              key="personalInfo"
              disabled={isLoading}
            />
          ) : (
            <Toolbar
              title="Editar Paciente"
              entity={entity}
              deleteAction={() => DeleteWarning(deletePatient)}
              saveAction={handleSaveClick}
              setRefresh={setRefresh}
              refresh={refresh}
              showBackButton={true}
              showSaveButton={userAllowed}
              showNewButton={userAllowed}
              showDeleteButton={userAllowed}
              showRefreshButton={true}
              key="personalInfo"
              disabled={isLoading}
            />
          )}
        </Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} key="formPatient" ref={form}>
            <Container>
              <Row>
                <PersonalInformationForm
                  userId={patientId}
                  user={patient}
                  setUser={setPatient}
                  preview={preview}
                  setPreview={setPreview}
                  validated={validated}
                  setValidated={setValidated}
                  refresh={refresh}
                  setimgProfile={setimgProfile}
                  setRefresh={setRefresh}
                  userType={entity}
                  featureFillEntity={true}
                  disabled={!userAllowed}
                />
              </Row>
            </Container>
          </Form>
        </Card.Body>
        <Card.Body>
          <AttachmentFiles
            userId={patientId}
            attachmentFiles={attachmentFiles}
            setAttachmentFiles={setAttachmentFiles}
            woSavingAttachmentFiles={woSavingAttachmentFiles}
            setWoSavingAttachmentFiles={setWoSavingAttachmentFiles}
            deleteAttachmentFiles={deleteAttachmentFiles}
            setDeleteAttachmentFiles={setDeleteAttachmentFiles}
            refresh={refresh}
            disabled={!userAllowed}
            uploadedFileType={1}
          />
        </Card.Body>
        <Card.Body>
          <Button
            variant="warning"
            onClick={() =>
              Warning(
                () => resetPasswordEndpoint(patientId),
                "¿Estas seguro que queres restablecer la Contraseña?",
                "Restablecer Contraseña"
              )
            }
          >
            Restablecer Contraseña
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PersonalInformation;
