import Post from "../../BaseApiController/Post"

const endPoint = "/api/Services"

async function PostServices(body) {
    const successMessage = "Prestacion creada con exito.";
    const response = await Post(endPoint, body, null, successMessage)
    return response
}

export default PostServices