import Post from "../../BaseApiController/Post"

const endPoint = "/api/Prescriptions"

async function PostPrescriptions(body) {
    const successMessage = "Receta creado con exito.";
    const prescription = await Post(endPoint, body, null, successMessage)
    return prescription
}

export default PostPrescriptions