import Post from "../../BaseApiController/Post"

const endPoint = "/api/Security/ChangePassword"

async function PostChangePassword(body) {
    const successMessage = "Contraseña modificada con exito.";
    const response = await Post(endPoint, body, null, successMessage)
    return response
}

export default PostChangePassword