import React from "react";
import * as Icons from "@mui/icons-material";
import GetMenu from "../Api/Controllers/Security/GetMenu";

const getIconComponent = (iconName) => {
  const icon = Icons[iconName];
  if (icon) {
    return React.createElement(icon);
  } else {
    console.warn(`Icon "${iconName}" not found`);
    return null;
  }
};

const fetchCategories = async () => {
    const data = await GetMenu();

    const createCategory = (categoryData) => {
      const category = {
        name: categoryData.Name,
        icon: getIconComponent(categoryData.Icon),
        path: categoryData?.Path,
        children: [],
      };

      if (categoryData?.Children) {
        category.children = categoryData.Children.map(createCategory);
      }

      return category;
    };

    const categories = data.map(createCategory);
    return categories;
};


export const item = {
  py: "2px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

export const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};

export default fetchCategories;
