import React, { useState, useEffect } from 'react';
import FormattedTextBox from './FormattedTextBox';
import Popup from './Popup';
import { useUnsavedChanges } from '../Context/UnsavedChangesContext';
import FillEntity from './FillEntity';
import { GetEndPoint } from "../Api/Controllers/Common/EndPoints";

function ItemSelector({ entity, setEntity, onEntitySelected, clear, initialValue }) {
    const [value, setValue] = useState('');
    const [popUpShow, setPopUpShow] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const { setHasUnsavedChanges } = useUnsavedChanges();

    const handleSelectID = (selectedEntity) => {
        const entitySelected = FillEntity(entity, selectedEntity)
        setValue(entitySelected.getName());
        setEntity(entitySelected);
        onEntitySelected && onEntitySelected(entitySelected);
        setPopUpShow(false);
        setHasUnsavedChanges(true); 
    };

    const handleTextBoxClick = () => {
        setHasUnsavedChanges(true);
        setPopUpShow(true);
    };

    const handleSearchInitialValue = async () => {
        if (!initialValue) return;
        const key = { type: 'guid', value: initialValue }
        const api = GetEndPoint(entity);
        const response = await api({ key });
        const responseEntity = FillEntity(entity, response)
        setValue(responseEntity.getName())
    };

    useEffect(() => {
        setValue('');
        handleSearchInitialValue();
        setPopUpShow(false);
    }, [clear, refresh, initialValue]);

    const handleChange = (event) => {
        setHasUnsavedChanges(true);
        setValue(event.target.value);
    };

    return (
        <div style={{ position: "relative" }} onClick={handleTextBoxClick}>
            <FormattedTextBox
                label={entity}
                type="text"
                placeholder={entity}
                name="name"
                value={value}
                handleChange={handleChange}
                required={true}
            />
            <Popup
                show={popUpShow}
                onHide={() => { setPopUpShow(!popUpShow); setRefresh(!refresh); }}
                handleSelect={handleSelectID}
                popupentity={entity}
            />
        </div>
    );
};


export default ItemSelector;
