import Patch from "../../BaseApiController/Patch"

const endPoint = "/api/Professionals"

async function PatchProfessional(queryObj, body) {
    const successMessage = "Profesional modificado con exito.";
    const response = await Patch(endPoint, queryObj, body, null, successMessage)
    return response
}

export default PatchProfessional