class Speciality {
    constructor(Id, Name) {
        this.Id = Id;
        this.Name = Name;
    }

    getName() {
        return this.Name;
    }
}

export default Speciality