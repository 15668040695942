import React, { useState, useContext, memo } from 'react'
import Card from 'react-bootstrap/Card';
import TableSearch from '../TableSearch/TableSearch';
import Container from 'react-bootstrap/Container';
import ToolBar from '../GenericComponents/ToolBar'
import { DeleteEndPoint } from '../Api/Controllers/Common/EndPoints'
import { Spinner } from "react-bootstrap";
import Popup from '../GenericComponents/Popup';
import DeleteWarning from '../GenericComponents/DeleteWarning';
import { UserOperationsAllowedContext } from '../Dashboard/UserOperationsAllowedContext';

const CardTableSearch = memo(({   title,
  entity,
  parentEntity,
  entityId,
  popUp,
  orderBy,
  showDeleteButton = true,
  showNewButton = true,
  checkboxSelection = true,
  columnClickEnabled = true,
  handleSelect,
  action,
  showActionsDropdown = false,
  showCloseButton = false,
  requiredOperations = [],
  showBackButton = false }) => {
    const [checkedRows, setCheckRows] = useState([])
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [popUpShow, setPopUpShow] = useState(false);
    const { userOperationsAllowed, hasAllRequiredOperations } = useContext(UserOperationsAllowedContext);
  
    const api = DeleteEndPoint(entity)
  
    const deleteAction = () => {
      try {
        setIsLoading(true)
        checkedRows.forEach(async (row) => {
          const key = { type: "guid", value: row }
          await api({ key })
          setRefresh(!refresh)
        })
  
      } catch (error) {
        console.log(error)
      } finally {
        setCheckRows([])
        setIsLoading(false)
      }
    }
  
    const handleDeleteIcon = (entity, entityId) => {
      const api = DeleteEndPoint(entity);
      const key = { type: "guid", value: entityId };
      
      DeleteWarning(async () => {
        try {
          await api({ key });
          setRefresh(!refresh);
          console.log("Deleted entity:", entity);
        } catch (error) {
          console.log("Error deleting", entity, error);
        }
      });
    }
  
    const userAllowed = hasAllRequiredOperations({
      requiredOperations,
      userOperations: userOperationsAllowed
    });
  
    try {
      if (isLoading) {
        return <Spinner animation="border" variant="secondary" />
      }
      return (
        <Container>
          <Card>
            <Card.Header>
              <ToolBar
                title={title}
                entity={entity}
                deleteAction={() => DeleteWarning(deleteAction)}
                showDeleteButton={showDeleteButton && checkedRows.length > 0 && userAllowed}
                showNewButton={showNewButton && userAllowed}
                popUp={popUp}
                setPopUpShow={setPopUpShow}
                disabled={isLoading}
                showCloseButton={showCloseButton}
                actionClose={() => action()}
                showActions={showActionsDropdown && userAllowed}
                checkedRows={checkedRows}
                refresh={refresh}
                setRefresh={setRefresh}
                showBackButton={showBackButton}
              />
            </Card.Header>
            <Card.Body>
              <TableSearch
                userType={entity}
                parentEntity={parentEntity ? parentEntity : null}
                refresh={refresh}
                setRefresh={setRefresh}
                setCheckRows={setCheckRows}
                entityId={entityId}
                popUp={popUp}
                orderBy={orderBy}
                checkboxSelection={checkboxSelection}
                columnClickEnabled={columnClickEnabled}
                handleSelect={handleSelect}
                userAllowed={userAllowed}
                showBackButton={showBackButton}
                deleteIconAction={handleDeleteIcon}
              />
            </Card.Body>
          </Card>
          {popUp === true && (<Popup
            show={popUpShow}
            onHide={() => { setPopUpShow(false); setRefresh(!refresh) }}
            entityfieldid={entityId}
            popupentity={entity}
            refresh={refresh}
            userAllowed={userAllowed}
          />)}
        </Container>
      )
    } catch (error) {
      console.log(new Error("Something went wrong", error))
    }
});

export default CardTableSearch