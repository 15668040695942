import Post from "../../BaseApiController/Post"

const endPoint = "/api/HealthInsurances"

async function PostHealthInsurances(body) {
    const successMessage = "Obra social creada con exito.";
    const response = await Post(endPoint, body, null, successMessage)
    return response
}

export default PostHealthInsurances