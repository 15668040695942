import Post from "../../BaseApiController/Post"

const endPoint = "/api/Sites"

async function PostSite(body) {
    const successMessage = "Sede creada con exito.";
    const response = await Post(endPoint, body, null, successMessage)
    return response
}

export default PostSite