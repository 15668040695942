import { useState, useEffect, useRef } from "react";
import {
  Card,
  Container,
  Form,
  Row,
  Col,
  Button,
  Table,
} from "react-bootstrap";
import Toolbar from "../GenericComponents/ToolBar";
import GetEntities from "../GenericComponents/Entities";
import { PostEndPoint } from "../Api/Controllers/Common/EndPoints";
import FormattedTextBox from "../GenericComponents/FormattedTextBox";
import { PlusSquare, Trash3 } from "react-bootstrap-icons";
import CardTableSearch from "../TableSearch/CardTableSearch";
import Prescription from "../../Models/Prescriptions/Prescription";
import ItemSelector from "../GenericComponents/ItemSelector";
import Medicine from "../../Models/Medicine/Medicine";

const Entity = GetEntities();
const professionalId = JSON.parse(localStorage.getItem("myId"));

const Prescriptions = ({ patientId, userAllowed = true }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const form = useRef(null);
  const [gridData, setGridData] = useState([]);
  const postPrescriptionsApi = PostEndPoint(Entity.Recetas);
  const [prescribedDate, setPrescribedDate] = useState("");
  const [isFormValid, setIsFormValid] = useState(true);
  const [clear, setClear] = useState(false);
  const [medicine, setMedicine] = useState(
    new Medicine("", "", "", "", "", "")
  );

  const [formData, setFormData] = useState({
    medicineCode: "",
    medicineDescription: "",
    medicineDrug: "",
    medicinePresentationShape: "",
    medicinePresentation: "",
    quantity: "",
    reason: "",
    dosageInstructions: "",
  });

  const handleAddToGrid = (event) => {
    console.log(userAllowed + " user priv");
    setValidated(form.current.checkValidity() === false);
    if (form.current.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setIsFormValid(true);
      setGridData([...gridData, formData]);
      setMedicine({});
      setClear(!clear);
      setFormData({
        medicineCode: "",
        medicineDescription: "",
        medicineDrug: "",
        medicinePresentationShape: "",
        medicinePresentation: "",
        quantity: "",
        reason: "",
        dosageInstructions: "",
      });
    }
  };

  const handleDelete = (indexToDelete) => {
    const newData = gridData.filter((_, index) => index !== indexToDelete);
    setGridData(newData);
  };

  const handleSaveClick = async () => {
    setIsLoading(true);
    const newPrescription = new Prescription(
      professionalId,
      patientId,
      prescribedDate,
      gridData
    );
    try {
      const response = await postPrescriptionsApi(newPrescription);
      if (response) {
        setGridData([]);
        setPrescribedDate("");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setPrescribedDate(formattedDate);
  }, []);

  return (
    <Container>
      <Card>
        <Card.Header>
          <Toolbar
            title="Crear Recetas"
            showBackButton={false}
            showSaveButton={userAllowed}
            showDeleteButton={false}
            showRefreshButton={false}
            key="prescriptions"
            disabled={isLoading}
            saveAction={handleSaveClick}
          />
        </Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} key="prescriptions" ref={form}>
            <Container>
              <Row className="g-2">
                <Col md>
                  <FormattedTextBox
                    label="Fecha de receta"
                    type="date"
                    name="prescribedDate"
                    required={true}
                    disabled={!userAllowed}
                    value={prescribedDate}
                    handleChange={(e) => setPrescribedDate(e.target.value)}
                  />
                </Col>
                <ItemSelector
                  entity={Entity.Medicina}
                  setEntity={setMedicine}
                  initialValue={medicine.Id}
                  onEntitySelected={(medicineSelected) => {
                    setFormData({
                      ...formData,
                      medicineCode: medicineSelected.Code,
                      medicineDescription: medicineSelected.Name,
                      medicineDrug: medicineSelected.Drug,
                      medicinePresentation: medicineSelected.Presentation,
                      medicinePresentationShape:
                        medicineSelected.PresentationShape,
                    });
                  }}
                  clear={clear}
                />
                <Col md>
                  <FormattedTextBox
                    label="Cantidad"
                    type="number"
                    name="quantity"
                    value={formData.quantity}
                    handleChange={(e) =>
                      setFormData({
                        ...formData,
                        quantity: Number(e.target.value),
                      })
                    }
                    required={true}
                    disabled={!userAllowed}
                  />
                </Col>
              </Row>
              <Row className="g-2">
                <Col md>
                  <FormattedTextBox
                    label="Razon"
                    type="text"
                    name="reason"
                    value={formData.reason}
                    handleChange={(e) =>
                      setFormData({ ...formData, reason: e.target.value })
                    }
                    required={false}
                    disabled={!userAllowed}
                  />
                </Col>
                <Col md>
                  <FormattedTextBox
                    label="Instruccion Dosis"
                    type="text"
                    name="dosageInstructions"
                    value={formData.dosageInstructions}
                    handleChange={(e) =>
                      setFormData({
                        ...formData,
                        dosageInstructions: e.target.value,
                      })
                    }
                    required={false}
                    disabled={!userAllowed}
                  />
                </Col>
                <Col>
                  <Button variant="success" onClick={handleAddToGrid}>
                    <PlusSquare />
                  </Button>
                </Col>
              </Row>
              <Container>
                {gridData.length > 0 && (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Codigo de Medicina</th>
                        <th>Descripcion</th>
                        <th>Cantidad</th>
                        <th>Razon</th>
                        <th>Instruccin dosis</th>
                        <th>Eliminar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gridData.map((item, index) => (
                        <tr key={index}>
                          <td>{item.medicineCode}</td>
                          <td>{item.medicineDescription}</td>
                          <td>{item.quantity}</td>
                          <td>{item.reason}</td>
                          <td>{item.dosageInstructions}</td>
                          <td>
                            {
                              <button
                                variant="danger"
                                onClick={() => handleDelete(index)}
                                style={{ border: "none", background: "none" }}
                              >
                                <Trash3 title="Delete" />
                              </button>
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Container>
              <Card.Body>
                <CardTableSearch
                  title="Recetas"
                  entity={Entity.Recetas}
                  columns={Entity.Recetas}
                  key={Entity.Recetas}
                  entityId={patientId}
                  showNewButton={false}
                  checkboxSelection={false}
                  orderBy={["PrescribedDate desc"]}
                />
              </Card.Body>
            </Container>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Prescriptions;
