import React, { useState, useRef } from 'react';
import { Card, Container, Button, Row, Form } from "react-bootstrap";
import GetEntities from '../GenericComponents/Entities';
import { PostEndPoint } from "../Api/Controllers/Common/EndPoints"
import FormattedTextBox from "../GenericComponents/FormattedTextBox"
import Swal from "sweetalert2";
import { Eye, EyeSlash } from 'react-bootstrap-icons';

const Entity = GetEntities()

const confirmAction = (action) => {
    const alertConfig = {
        showConfirmButton: true,
        width: 500,
        position: "center",
        icon: "question",
        title: "¿Desea cambiar la contraseña?",
        confirmButtonColor: "green",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar"
    };

    Swal.fire(alertConfig).then((result) => {
        if (result.isConfirmed) {
            action()
        }
    })
}

const ChangePassword = () => {

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [validated, setValidated] = useState(false);
    const form = useRef(null);

    const handleOldPasswordChange = event => {
        setOldPassword(event.target.value)
    }

    const handleNewPasswordChange = event => {
        setNewPassword(event.target.value)
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const changePassword = event => {
        setValidated(form.current.checkValidity() === false);
        if (form.current.checkValidity() === false) {
            event.stopPropagation();
        } else {
            confirmAction(() => {
                const apiChangePassword = PostEndPoint(Entity.Password)
                const body = {
                    oldPassword: oldPassword,
                    newPassword: newPassword
                }
                const success = apiChangePassword(body)
                if (success) {
                    setNewPassword("")
                    setOldPassword("")
                    setShowPassword(!showPassword)
                }
            })
        }
    }

    return (
        <Container>
            <Card>
                <Card.Header>
                    <h2>Cambiar contraseña</h2>
                </Card.Header>
                <Card.Body>
                    <Form noValidate validated={validated} key="formProfile" ref={form}>
                        <FormattedTextBox
                            label="Antigua contraseña"
                            type={showPassword ? "text" : "password"}
                            placeholder="Antigua contraseña"
                            name="oldPassword"
                            disabled={false}
                            required={true}
                            value={oldPassword}
                            valida
                            handleChange={handleOldPasswordChange}
                        />
                        <FormattedTextBox
                            label="Nueva contraseña"
                            type={showPassword ? "text" : "password"}
                            placeholder="Nueva contraseña"
                            name="newPassword"
                            disabled={false}
                            required={true}
                            value={newPassword}
                            handleChange={handleNewPasswordChange}
                        />
                    </Form>
                    <Button variant="link" onClick={toggleShowPassword} style={{ color: 'inherit' }}>
                        {showPassword ? <EyeSlash className="mx-2" size={20} /> : <Eye className="mx-2" size={20} />}
                    </Button>
                    <Row>
                        <Button variant="primary" onClick={changePassword}>
                            Confirmar
                        </Button>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default ChangePassword