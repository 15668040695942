import { axiosPrivate } from "./axios"
import QueryBuilder from "../../QueryBuilder/QueryBuilder"
import HandleError from "./ErrorHandling"
import SuccessEvent from "../../GenericComponents/SuccessEvent"

//const baseURL = window.location.origin + ":5000"

async function Patch(endPoint, queryObj, body, headers, successMessage) {
    const query = QueryBuilder(queryObj);
    try {
        const response = await axiosPrivate.patch(endPoint + query, body, { headers });
        const json = await response.data;
        if (successMessage) {
            SuccessEvent(successMessage);
        }
        return json;
    } catch (err) {
        HandleError(err);
    }
}

export default Patch