import React, { useEffect, useState, useRef } from "react";
import { Form, Container, Spinner, Card, Row, Col } from "react-bootstrap";
import Toolbar from "./ToolBar";
import GetHealthInsuranceCopayments from "../Api/Controllers/HealthInsuranceCopayment/GetHealthInsuranceCopayment";
import PostHealthInsuranceCopayment from "../Api/Controllers/HealthInsuranceCopayment/PostHealthInsuranceCopayment";
import DeleteHealthInsuranceCopayment from "../Api/Controllers/HealthInsuranceCopayment/DeleteHealthInsuranceCopayment";
import PutHealthInsuranceCopayment from "../Api/Controllers/HealthInsuranceCopayment/PutHealthInsuranceCopayment";
import GetEntities from "./Entities";
import DeleteWarning from "./DeleteWarning";
import FormattedDropdown from "./FormattedDropdown";
import FormattedTextBox from "./FormattedTextBox";
import FillEntity from "./FillEntity";
import FormattedCurrencyInput from "./FormattedCurrencyInput";

const Entity = GetEntities();

const getEmptyHealthInsuranceCopayment = (healthInsuranceId) => {
  return FillEntity(Entity.Copago, {
    Id: "",
    DateFrom: "2023-08-01",
    ServiceId: "",
    ServiceName: "",
    CareModalityId: "",
    CareModalityName: "",
    Cost: 0,
    HealthInsuranceId: healthInsuranceId,
  });
};

const HealthInsuranceCopaymentForm = ({
  entityId,
  healthInsuranceId,
  action,
  userAllowed = true,
}) => {
  const [loading, setLoading] = useState(false);
  const [healthInsuranceCopyamentId, sethealthInsuranceCopyamentId] =
    useState(entityId);
  const [healthInsuranceCopyament, sethealthInsuranceCopyament] = useState(
    getEmptyHealthInsuranceCopayment(healthInsuranceId)
  );
  const [service, setService] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [validated, setValidated] = useState(false);
  const form = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleDateChange = (event) => {
    sethealthInsuranceCopyament((prevState) => ({
      ...prevState,
      DateFrom: event.target.value,
    }));
  };

  const handleCostChange = (event) => {
    sethealthInsuranceCopyament((prevState) => ({
      ...prevState,
      Cost: event,
    }));
  };

  const fetchhealthInsuranceCopyament = async () => {
    try {
      setLoading(true);
      const key = { type: "guid", value: healthInsuranceCopyamentId };
      const json = await GetHealthInsuranceCopayments({ key });
      const healInsuranceApi = FillEntity(Entity.Copago, json);
      sethealthInsuranceCopyament(healInsuranceApi);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deletehealthInsuranceCopyament = async () => {
    const key = { type: "guid", value: entityId };
    await DeleteHealthInsuranceCopayment({ key });
    sethealthInsuranceCopyamentId(undefined);
    entityId = undefined;
    action();
    setRefresh(!refresh);
  };

  const createhealthInsuranceCopyamentRequest = () => {
    return {
      healthInsuranceId: healthInsuranceCopyament.HealthInsuranceId,
      serviceId: healthInsuranceCopyament.ServiceId,
      dateFrom: healthInsuranceCopyament.DateFrom,
      cost: healthInsuranceCopyament.Cost,
    };
  };

  const handleSaveClick = async (event) => {
    setIsLoading(true);
    try {
      setValidated(form.current.checkValidity() === false);
      if (form.current.checkValidity() === false) {
        event.stopPropagation();
        setIsLoading(false);
        return;
      } else {
        setLoading(true);
        if (healthInsuranceCopyamentId !== undefined) {
          const key = { type: "guid", value: healthInsuranceCopyamentId };
          await PutHealthInsuranceCopayment({ key }, healthInsuranceCopyament);
        } else {
          const request = createhealthInsuranceCopyamentRequest();
          const healthInsuranceCopyament = await PostHealthInsuranceCopayment(
            request
          );
          if (healthInsuranceCopyament) {
            sethealthInsuranceCopyamentId(healthInsuranceCopyament.Id);
            sethealthInsuranceCopyament(healthInsuranceCopyament);
            setRefresh(!refresh);
          }
        }
      }
      action();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (healthInsuranceCopyamentId !== undefined) {
      fetchhealthInsuranceCopyament();
    } else {
      sethealthInsuranceCopyament(
        getEmptyHealthInsuranceCopayment(healthInsuranceId)
      );
    }
  }, [refresh]);

  if (loading) {
    return <Spinner animation="border" variant="secondary" />;
  }

  return (
    <Container>
      <Card>
        <Card.Header>
          {!healthInsuranceCopyamentId ? (
            <Toolbar
              title="Crear Copago"
              entity={Entity.Copago}
              saveAction={handleSaveClick}
              setRefresh={setRefresh}
              refresh={refresh}
              showSaveButton={userAllowed}
              showCloseButton={true}
              actionClose={action}
              disabled={isLoading}
            />
          ) : (
            <Toolbar
              title="Editar Copago"
              entity={Entity.Copago}
              deleteAction={() => DeleteWarning(deletehealthInsuranceCopyament)}
              saveAction={handleSaveClick}
              setRefresh={setRefresh}
              refresh={refresh}
              showSaveButton={userAllowed}
              showDeleteButton={userAllowed}
              showRefreshButton={true}
              showCloseButton={true}
              actionClose={action}
              disabled={isLoading}
            />
          )}
        </Card.Header>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            key="formHealthInsuraceCopayment"
            ref={form}
          >
            <Form.Group className="mb-3">
              <Row className="g-2">
                <Col md>
                  <FormattedDropdown
                    apiEndpoint={Entity.ModalidadAtencion}
                    label="Modalidad de atención"
                    required={true}
                    name="CareModality"
                    value={healthInsuranceCopyament.CareModalityId}
                    onChange={(newSCareModality) => {
                      sethealthInsuranceCopyament((prevState) => ({
                        ...prevState,
                        CareModalityId: newSCareModality,
                      }));
                    }}
                  />
                </Col>
              </Row>
              <Row className="g-2">
                <Col md>
                  <FormattedDropdown
                    apiEndpoint={Entity.Prestacion}
                    label={Entity.Prestacion}
                    required={true}
                    name="GenericId"
                    value={healthInsuranceCopyament.ServiceId}
                    useConditions={true}
                    conditions={
                      healthInsuranceCopyament.CareModalityId
                        ? {
                            ParentId: {
                              eq: {
                                type: "guid",
                                value: healthInsuranceCopyament.CareModalityId,
                              },
                            },
                          }
                        : null
                    }
                    onChange={(newService) => {
                      setService(newService);
                      sethealthInsuranceCopyament((prevState) => ({
                        ...prevState,
                        ServiceId: newService,
                      }));
                    }}
                    disabled={!userAllowed}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3">
              <Row className="g-2">
                <Col md>
                  <FormattedTextBox
                    label="Fecha desde"
                    type="date"
                    name="dateFrom"
                    value={healthInsuranceCopyament.DateFrom}
                    handleChange={handleDateChange}
                    required={true}
                    validated={validated}
                    disabled={!userAllowed}
                  />
                </Col>
                <Col md>
                  <FormattedCurrencyInput
                    label="Monto"
                    required={true}
                    name="Monto"
                    value={healthInsuranceCopyament.Cost}
                    onChange={handleCostChange}
                    disabledTooltip={true}
                    disabled={!userAllowed}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default HealthInsuranceCopaymentForm;
