import GetEntities from "../GenericComponents/Entities";

const Entity = GetEntities();

const patientFilter = (filterValue) => {
  return {
    or: [
      { ["tolower(Name)"]: { contains: filterValue } },
      { ["tolower(LastName)"]: { contains: filterValue } },
      { DocumentNumber: { contains: filterValue } },
      { PhoneNumber: { contains: filterValue } },
      { CellPhoneNumber: { contains: filterValue } },
      { ["tolower(Email)"]: { contains: filterValue } },
    ],
  };
};

const userFilter = (filterValue) => {
  return {
    or: [
      { ["tolower(Name)"]: { contains: filterValue } },
      { ["tolower(LastName)"]: { contains: filterValue } },
      { DocumentNumber: { contains: filterValue } },
      { PhoneNumber: { contains: filterValue } },
      { CellPhoneNumber: { contains: filterValue } },
      { ["tolower(Email)"]: { contains: filterValue } },
    ],
  };
};

const healthInsuranceFilter = (filterValue) => {
  return {
    or: [{ ["tolower(Name)"]: { contains: filterValue } }],
  };
};

const idDescriptionFilter = (filterValue) => {
  return {
    or: [{ ["tolower(Name)"]: { contains: filterValue } }],
  };
};

const ProfesionalScheduleColumnFilter = (filterValue) => {
  return {
    or: [
      { ["tolower(DayOfWeek)"]: { contains: filterValue } },
      { From: { contains: filterValue } },
      { To: { contains: filterValue } },
    ],
  };
};

const nameAndParentName = (filterValue) => {
  return {
    or: [
      { ["tolower(Name)"]: { contains: filterValue } },
      { ["tolower(ParentName)"]: { contains: filterValue } },
    ],
  };
};

const professionalFilter = (filterValue) => {
  return {
    or: [
      { ["tolower(Name)"]: { contains: filterValue } },
      { ["tolower(LastName)"]: { contains: filterValue } },
      { DocumentNumber: { contains: filterValue } },
      { PhoneNumber: { contains: filterValue } },
      { CellPhoneNumber: { contains: filterValue } },
      { ["tolower(Email)"]: { contains: filterValue } },
      { ["tolower(SpecialtyName)"]: { contains: filterValue } },
    ],
  };
};

const myAppointmentFilter = (filterValue) => {
  return {
    or: [
      { ["tolower(professionalName)"]: { contains: filterValue } },
      { ["tolower(serviceName)"]: { contains: filterValue } },
      { ["tolower(assistanceName)"]: { contains: filterValue } },
    ],
  };
};

const medicineFilter = (filterValue) => {
  return {
    or: [
      { ["tolower(Name)"]: { contains: filterValue } },
      { ["tolower(Laboratory)"]: { contains: filterValue } },
      { ["tolower(Presentation)"]: { contains: filterValue } },
      { ["tolower(Drug)"]: { contains: filterValue } },
      { ["tolower(PresentationShape)"]: { contains: filterValue } },
      { Code: { contains: filterValue } },
    ],
  };
};

function Filtering(userType, filterValue) {
  switch (userType) {
    case Entity.Paciente:
      return patientFilter(filterValue);
    case Entity.Pais:
      return idDescriptionFilter(filterValue);
    case Entity.Provincia:
      return nameAndParentName(filterValue);
    case Entity.Localidad:
      return nameAndParentName(filterValue);
    case Entity.Prestacion:
      return nameAndParentName(filterValue);
    case Entity.TipoDocumento:
      return idDescriptionFilter(filterValue);
    case Entity.Profesional:
      return professionalFilter(filterValue);
    case Entity.Agenda:
      return ProfesionalScheduleColumnFilter(filterValue);
    case Entity.MisTurnosPasados:
      return myAppointmentFilter(filterValue);
    case Entity.Medicina:
      return medicineFilter(filterValue);
    case Entity.Usuario:
      return userFilter(filterValue);
    case Entity.ObraSocial:
      return healthInsuranceFilter(filterValue);
    default:
      console.log(new Error("No filters found for this entity"));
      break;
  }
}

export default Filtering;
