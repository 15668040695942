import React, { createContext, useContext, useState, useEffect } from 'react';

const UnsavedChangesContext = createContext();

export const useUnsavedChanges = () => {
    return useContext(UnsavedChangesContext);
}

export const UnsavedChangesProvider = ({ children }) => {
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const resetUnsavedChanges = () => {
        setHasUnsavedChanges(false);
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (hasUnsavedChanges) {
                event.preventDefault();
                event.returnValue = "Tenes cambios sin guardar! Desea continuar?";
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
    }, [hasUnsavedChanges]);

    return (
        <UnsavedChangesContext.Provider value={{ hasUnsavedChanges, setHasUnsavedChanges, resetUnsavedChanges }}>
            {children}
        </UnsavedChangesContext.Provider>
    );
};
