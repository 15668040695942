import React, { useState, useEffect } from "react";
import { Dropdown, DropdownButton, Image } from "react-bootstrap";
import AlertHandler from "../Api/BaseApiController/AlertHandler";
import { useNavigate } from 'react-router-dom';
import userImage from "./../../assets/defaultImage.jpg";
import FillEntity from '../GenericComponents/FillEntity';
import GetEntities from '../GenericComponents/Entities';
import GetCurrentUser from "../Api/Controllers/Security/GetCurrentUser"
import GetUploadedFiles from "../Api/Controllers/UploadedFiles/GetUploadedFiles";

const Entity = GetEntities();

const AvatarDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [profileImg, setProfileImg] = useState();

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);


  const fetchUser = async () => {
    try {
      const response = await GetCurrentUser();

      if (!response.data || Object.keys(response.data).length === 0) {
        Logout();
        return;
      }

      const responseUser = FillEntity(Entity.Usuario, response.data);
      setUser(responseUser);

      const obj = { relatedEntityId: responseUser.Id, uploadedFileType: 0 };
      const srcProfilePicture = await GetUploadedFiles(obj);
      if (srcProfilePicture.length > 0) {
        const urlProfilePicture = srcProfilePicture[0].Url;
        setProfileImg(urlProfilePicture);
      }
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    fetchUser();
  }, []);

  const Logout = () => {
    try {
      localStorage.clear();
      sessionStorage.clear();
      AlertHandler("Success", "Cerrando Sesion");
      setTimeout(function () {
        window.location.href = "/";
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dropdown onToggle={toggleDropdown} show={dropdownOpen}>
      <DropdownButton
        variant="Secondary"
        id="dropdown-basic-button"
        title={
          <>
            <Image
              src={profileImg || userImage}
              roundedCircle
              width={40}
              height={40}
              alt="user"
            />
            <span style={{ color: "white", marginLeft: "10px" }}>
              {user.FullName}
            </span>
          </>
        }
      >
        <Dropdown.Item onClick={() => navigate('/MiPerfil')}>Mi Perfil</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => navigate('/CambiarContraseña')}>Cambiar contraseña</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={Logout}>Cerrar sesion</Dropdown.Item>
      </DropdownButton>
    </Dropdown>
  );
};

export default AvatarDropdown;
