import React, { useState, useEffect, useRef, useMemo } from "react";
import { Form, Card, FloatingLabel, Stack } from "react-bootstrap";
import { GetEndPoint } from "../Api/Controllers/Common/EndPoints";
import FillEntity from "./FillEntity";
import { isEqual } from 'lodash';
import './MultiSelect.css'

const fetchMultiSelectOptions = async (apiEndpoint, conditions, featureFillEntity, orderBy) => {
    const api = await GetEndPoint(apiEndpoint);
    if (!orderBy.length) {
        orderBy = ['Name asc']
    }
    const obj = {
        filter: conditions,
        orderBy: orderBy
    }
    const response = await api(obj);

    return response.value.map((entityResponse) => {
        if (featureFillEntity) {
            return FillEntity(apiEndpoint, entityResponse);
        }
        const { Name, Id } = entityResponse;
        return { Name, Id };
    });
};

const MultiSelect = ({
    apiEndpoint,
    featureFillEntity = false,
    useConditions = false,
    conditions,
    onSelectionChange,
    selectedOptions,
    setSelectedOptions,
    labelComp,
    disabled = false,
    required,
    orderBy = []
}) => {
    const [options, setOptions] = useState([]);
    const prevConditionsRef = useRef();

    const fetchData = async () => {
        if (useConditions && (!conditions || Object.keys(conditions).length === 0)) {
            setOptions([]);
            return;
        }
        const entities = await fetchMultiSelectOptions(apiEndpoint, conditions, featureFillEntity, orderBy);
        setOptions(entities);
    };

    useEffect(() => {
        if (!isEqual(prevConditionsRef.current, conditions)) {
            fetchData();
            prevConditionsRef.current = conditions;
        }
        if (!useConditions) {
            fetchData();
        }
    }, [apiEndpoint, conditions]);

    const sortedOptions = useMemo(() => {
        return [...options].sort((a, b) => a.Name.localeCompare(b.Name));
    }, [options]);

    const handleSelectAll = (selectAll) => {
        setSelectedOptions((prevSelectedOptions) => {
            let newSelectedOptions;
            if (selectAll) {
                newSelectedOptions = sortedOptions.map((option) => option.Id);
            } else {
                newSelectedOptions = [];
            }

            if (typeof onSelectionChange === "function") {
                onSelectionChange(newSelectedOptions);
            }

            return newSelectedOptions;
        });
    };

    const handleSelect = (option) => {
        setSelectedOptions((prevSelectedOptions) => {
            const newSelectedOptions = prevSelectedOptions.includes(option.Id)
                ? prevSelectedOptions.filter((item) => item !== option.Id)
                : [...prevSelectedOptions, option.Id];

            if (typeof onSelectionChange === "function") {
                onSelectionChange(newSelectedOptions);
            }

            return newSelectedOptions;
        });
    };

    return (
        <Card style={{ width: "auto" }}>
            <FloatingLabel
                controlId="multiSelect"
                label=""
                style={{ width: "-webkit-fill-available" }}
                className="me-auto mb-3">
                {sortedOptions.length > 0 && (
                    <div className="fixed-label">{labelComp}</div>
                )}
                <div className="scrollable-div">
                    <Form>
                        <div className="selectable-option" onClick={() => handleSelectAll(selectedOptions.length !== sortedOptions.length)}>
                            <Form.Check
                                type="checkbox"
                                label={selectedOptions.length === sortedOptions.length ? "Deseleccionar todo" : "Seleccionar todo"}
                                checked={selectedOptions.length === sortedOptions.length}
                                readOnly
                                required={required}
                                disabled={disabled}
                            />
                        </div>
                        {sortedOptions.map((option, index) => (
                            <div
                                className="selectable-option"
                                key={index}
                                onClick={() => handleSelect(option)}
                            >
                                <Form.Check
                                    type="checkbox"
                                    label={featureFillEntity ? option.getName() : option.Name}
                                    checked={selectedOptions.includes(option.Id)}
                                    readOnly
                                    disabled={disabled}
                                />
                            </div>
                        ))}
                    </Form>
                </div>
            </FloatingLabel>
        </Card>
    );
};

export default MultiSelect;
