import GetEntities from '../GenericComponents/Entities';

const Entities = GetEntities()

const entityIdMap = new Map()
entityIdMap.set(Entities.Paciente, "patientId");
entityIdMap.set(Entities.Profesional, "professionalId");
entityIdMap.set(Entities.Familiares, "patientId");
entityIdMap.set(Entities.Agenda, "professionalId");
entityIdMap.set(Entities.Copago, "healthInsuranceId");
entityIdMap.set(Entities.Turno, "patientId");
entityIdMap.set(Entities.Recetas, "patientId");

export function GetEntityId(type) {
    return entityIdMap.get(type)
}