import React, { useState, useEffect } from "react";
import { Form, Container, InputGroup, Card, Stack, Button, Spinner } from "react-bootstrap";
import GetUploadedFiles from "../Api/Controllers/UploadedFiles/GetUploadedFiles";
import { FileEarmarkDiff, XCircle } from 'react-bootstrap-icons';
import DeleteWarning from '../GenericComponents/DeleteWarning';

const TitleMap = new Map([
    [1, "Adjuntos"],
    [2, "Firma profesional"],
    [3, "Logo"]
]);

export function GetTitle(uploadedFileType) {
    return TitleMap.get(uploadedFileType);
}

const AttachmentFiles = ({
    userId,
    attachmentFiles,
    setAttachmentFiles,
    woSavingAttachmentFiles,
    setWoSavingAttachmentFiles,
    deleteAttachmentFiles,
    setDeleteAttachmentFiles,
    refresh,
    uploadedFileType = 1,
    disabled = false,
    singleAttachment = false
}) => {
    const [loading, setLoading] = useState(false);

    const fetchAttachmentFiles = async () => {
        try {
            setLoading(true);
            const obj = { relatedEntityId: userId, uploadedFileType: uploadedFileType };
            const response = await GetUploadedFiles(obj);
            if (singleAttachment && response.length > 0) {
                setAttachmentFiles([response[0]]);
            } else {
                setAttachmentFiles(response);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const deleteFileUploaded = (id) => {
        const aux = attachmentFiles.filter(file => file.Id !== id);
        setDeleteAttachmentFiles([...deleteAttachmentFiles, id]);
        setAttachmentFiles(aux);
    };

    const onChange = event => {
        const filesArray = Array.from(event.target.files);
        if (singleAttachment) {
            setWoSavingAttachmentFiles(filesArray.slice(0, 1));
        } else {
            setWoSavingAttachmentFiles([...woSavingAttachmentFiles, ...filesArray]);
        }
    };

    const deleteWoFile = (name) => {
        const aux = woSavingAttachmentFiles.filter(file => file.name !== name);
        setWoSavingAttachmentFiles(aux);
    };

    useEffect(() => {
        if (userId !== undefined) {
            fetchAttachmentFiles();
        }
    }, [refresh, userId]);

    if (loading) {
        return <Spinner animation="border" variant="secondary" />;
    }

    return (
        <Container>
            <Card>
                <Card.Header>
                    <h2>{GetTitle(uploadedFileType)}</h2>
                </Card.Header>
                <Card.Body>
                    {attachmentFiles.length > 0 && <p style={{ textDecoration: "underline" }}>Archivos Subidos</p>}
                    {attachmentFiles.map((file, index) =>
                        <Stack direction="horizontal" gap={2} key={file.Id || index} style={{ alignItems: "flex-start" }}>
                            <a href={file.Url} target="_blank" rel="noopener noreferrer">
                                {file.OriginalFileName}<br /><br />
                            </a>
                            {!disabled && (
                                <Button variant="danger" onClick={() => { DeleteWarning(() => deleteFileUploaded(file.Id)) }} size="sm" id={file.Id}>
                                    <XCircle title="delete" />
                                </Button>
                            )}
                        </Stack>
                    )}

                    {woSavingAttachmentFiles.length > 0 && <p style={{ textDecoration: "underline" }}>Archivos por subir</p>}
                    {woSavingAttachmentFiles.map((file, index) =>
                        <Stack direction="horizontal" gap={2} key={file.name || index} style={{ alignItems: "flex-start" }}>
                            <span>
                                {file.name}<br /><br />
                            </span>
                            {!disabled && (
                                <Button variant="danger" onClick={() => { DeleteWarning(() => deleteWoFile(file.name)) }} size="sm" disabled={disabled} >
                                    <XCircle title="delete" />
                                </Button>
                            )}
                        </Stack>
                    )}
                    <Form.Label htmlFor="attachments">
                        <InputGroup>
                            <InputGroup.Text><FileEarmarkDiff title='Subir Archivos' size={20}></FileEarmarkDiff></InputGroup.Text>
                            <InputGroup.Text>Subir Archivos</InputGroup.Text>
                        </InputGroup>
                    </Form.Label>

                    <Form.Control
                        id="attachments"
                        type="file"
                        placeholder=""
                        name="attachments"
                        multiple={!singleAttachment} // Control multiple attribute based on singleAttachment flag
                        onChange={onChange}
                        size="md"
                        style={{ visibility: 'hidden' }}
                        disabled={disabled}
                    />
                </Card.Body>
            </Card>
        </Container>
    );
};

export default AttachmentFiles;
