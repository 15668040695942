import Post from "../../BaseApiController/Post"

const endPoint = "/api/DocumentTypes"

async function PostDocumentType(body) {
    const successMessage = "Tipo de documento creado con exito.";
    const documentType = await Post(endPoint, body, null, successMessage)
    return documentType
}

export default PostDocumentType