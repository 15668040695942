import React, { useState } from "react";
import { TextField, Box, Grid } from "@mui/material";
import moment from "moment/moment";
import { Form, FloatingLabel } from "react-bootstrap";
import { useUnsavedChanges } from "../Context/UnsavedChangesContext";

const DayTimePicker = ({
  onDayChange,
  onFromTimeChange,
  onToTimeChange,
  professionalSchedule,
  disabled = false
}) => {
  const [day, setDay] = useState((professionalSchedule ?? {}).DayOfWeek ?? 1);
  const [fromTime, setFromTime] = useState((professionalSchedule ?? {}).From ?? "00:00:00");
  const [toTime, setToTime] = useState((professionalSchedule ?? {}).To ?? "00:01:00");
  const { setHasUnsavedChanges } = useUnsavedChanges();

  const handleDayChange = (event) => {
    setHasUnsavedChanges(true);
    const value = event.target.value;
    setDay(value);
    onDayChange && onDayChange(value);
  };

  const handleFromTimeChange = (event) => {
    setHasUnsavedChanges(true);
    const value = event.target.value + ":00";
    setFromTime(value);
    onFromTimeChange && onFromTimeChange(value);
  };

  const handleToTimeChange = (event) => {
    setHasUnsavedChanges(true);
    const value = event.target.value + ":00";
    setToTime(value);
    onToTimeChange && onToTimeChange(value);
  };

  function timeFormatter(time) {
    if (time == null) {
      return "";
    }

    return moment("2000-01-01 " + time).format("HH:mm");
  }

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
            <FloatingLabel
              style={{ width: "-webkit-fill-available" }}
              className="me-auto mb-3"
              controlId="DayGrid"
              label="Dia"
              required
            >
              <Form.Select
                aria-label="Floating label select example"
                onChange={handleDayChange}
                name="Day"
                value={day}
                disabled={disabled}
              >
                <option value={0}>Domingo</option>
                <option value={1}>Lunes</option>
                <option value={2}>Martes</option>
                <option value={3}>Miercoles</option>
                <option value={4}>Jueves</option>
                <option value={5}>Viernes</option>
                <option value={6}>Sabado</option>
              </Form.Select>
            </FloatingLabel>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="From"
            label="Desde"
            type="time"
            onChange={handleFromTimeChange}
            value={timeFormatter(fromTime)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              step: 300, // 5 min
            }}
            fullWidth
            required
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="To"
            label="Hasta"
            type="time"
            onChange={handleToTimeChange}
            value={timeFormatter(toTime)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            fullWidth
            required
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Box>
  );
};





export default DayTimePicker;
