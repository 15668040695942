import Put from "../../BaseApiController/Put"

const endPoint = "/api/Assistances"

async function PutAssistance(queryObj, body) {
    const successMessage = "Asistencia modificada con exito.";
    const response =  await Put(endPoint, queryObj, body, null, successMessage)
    return response
}

export default PutAssistance