class Medicine {
  constructor(
    MedicineId,
    Code,
    Name,
    Laboratory,
    Presentation,
    Drug,
    PresentationShape
  ) {
    this.MedicineId = MedicineId;
    this.Code = Code;
    this.Name = Name;
    this.Laboratory = Laboratory;
    this.Presentation = Presentation;
    this.Drug = Drug;
    this.PresentationShape = PresentationShape;
  }

  getName() {
    return `${this.Code} - ${this.Name} - ${this.Presentation} - ${this.Laboratory}`;
  }
}

export default Medicine;
