import React, { useState, useEffect, useRef, useContext } from "react";
import FormattedTextBox from "../GenericComponents/FormattedTextBox";
import { Form, Container, Card } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { NewEntityPath } from "../Api/Controllers/Common/Paths";
import {
  GetEndPoint,
  PutEndPoint,
  DeleteEndPoint,
  PostEndPoint,
} from "../Api/Controllers/Common/EndPoints";
import Toolbar from "../GenericComponents/ToolBar";
import FormattedDropdown from "../GenericComponents/FormattedDropdown";
import { EditEntityPath } from "../Api/Controllers/Common/Paths";
import DeleteWarning from "../GenericComponents/DeleteWarning";
import { UserOperationsAllowedContext } from "../Dashboard/UserOperationsAllowedContext";
import { useUnsavedChanges } from "../Context/UnsavedChangesContext";
import GetEntities from "../GenericComponents/Entities";
import FillEntity from "../GenericComponents/FillEntity";
import Service from "../../Models/Service/Service";

const Entity = GetEntities().Prestacion;

const getEmptyService = (id) => {
  return new Service(id, "", "", "", null, null);
};

const ServiceForm = ({ requiredOperations = [] }) => {
  let { id } = useParams();
  const newEntityPath = NewEntityPath(Entity);
  let navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [service, setService] = useState(getEmptyService(id));
  const [validated, setValidated] = useState(false);
  const form = useRef(null);
  const editEntityPath = EditEntityPath(Entity);
  const [isLoading, setIsLoading] = useState(false);
  const { userOperationsAllowed, hasAllRequiredOperations } = useContext(
    UserOperationsAllowedContext
  );
  const { setHasUnsavedChanges } = useUnsavedChanges();
  const parentType = GetEntities().ModalidadAtencion;

  const handleChange = (event) => {
    setService({
      ...service,
      [event.target.name]: event.target.value,
    });
    setHasUnsavedChanges(true);
  };

  const fetchServiceData = async (id) => {
    const key = { type: "guid", value: id };
    const api = GetEndPoint(Entity);
    const response = await api({ key });
    const responseService = FillEntity(Entity, response);
    setService(responseService);
  };

  const deleteEntity = async () => {
    const key = { type: "guid", value: id };
    const api = DeleteEndPoint(Entity);
    await api({ key });
    navigate(newEntityPath);
    id = undefined;
    setRefresh(!refresh);
  };

  const handleSaveClick = async (event) => {
    setIsLoading(true);
    setValidated(form.current.checkValidity() === false);
    if (form.current.checkValidity() === false) {
      event.stopPropagation();
    } else {
      let body = service;
      if (id !== undefined) {
        const key = { type: "guid", value: id };
        const api = PutEndPoint(Entity);
        await api({ key }, body);
      } else {
        const api = PostEndPoint(Entity);
        const response = await api(body);
        const newEntityId = response.Id;
        navigate(`${editEntityPath}${newEntityId}`, {
          state: { id: newEntityId },
        });
      }
    }
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
    setHasUnsavedChanges(false);
  };

  useEffect(() => {
    if (id !== undefined) {
      fetchServiceData(id);
    }
    setValidated(false);
  }, [refresh]);

  const userAllowed = hasAllRequiredOperations({
    requiredOperations,
    userOperations: userOperationsAllowed,
  });

  return (
    <Container>
      <Card>
        <Card.Header>
          {!id ? (
            <Toolbar
              title={"Crear Prestacion"}
              entity={Entity}
              saveAction={handleSaveClick}
              setRefresh={setRefresh}
              refresh={refresh}
              showBackButton={true}
              showSaveButton={userAllowed}
              showCloseButton={false}
              disabled={isLoading}
            />
          ) : (
            <Toolbar
              title={"Editar Prestacion"}
              entity={Entity}
              deleteAction={() => DeleteWarning(deleteEntity)}
              saveAction={handleSaveClick}
              setRefresh={setRefresh}
              refresh={refresh}
              showBackButton={true}
              showSaveButton={userAllowed}
              showNewButton={userAllowed}
              showDeleteButton={userAllowed}
              showRefreshButton={true}
              disabled={isLoading}
            />
          )}
        </Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} ref={form}>
            <FormattedTextBox
              label="Nombre"
              type="text"
              placeholder="Ingrese el nombre"
              name="Name"
              value={service.Name}
              handleChange={handleChange}
              required={true}
              disabled={!userAllowed}
            />
            <FormattedDropdown
              apiEndpoint={parentType}
              label={parentType}
              required={true}
              name="CareModalityId"
              value={service.CareModalityId}
              onChange={(event) => {
                setService({
                  ...service,
                  ["CareModalityId"]: event,
                });
                setHasUnsavedChanges(true);
              }}
              disabled={!userAllowed}
            />
            <FormattedTextBox
              label="Maximo de turnos permitido por semana"
              type="number"
              placeholder="Ingrese cantidad maxima permitida por semana"
              name="MaxSelfServiceAppointmentsPerWeek"
              value={service.MaxSelfServiceAppointmentsPerWeek}
              handleChange={handleChange}
              required={false}
              disabled={!userAllowed}
            />
            <FormattedTextBox
              label="Maximo de turnos permitido por mes"
              type="number"
              placeholder="Ingrese cantidad maxima permitida por mes"
              name="MaxSelfServiceAppointmentsPerMonth"
              value={service.MaxSelfServiceAppointmentsPerMonth}
              handleChange={handleChange}
              required={false}
              disabled={!userAllowed}
            />
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ServiceForm;
