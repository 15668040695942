import Swal from "sweetalert2";

const UnsavedChangesWarning = () => {
    const alertConfig = {
        showConfirmButton: true,
        width: 500,
        position: "center",
        icon: "warning",
        title: "Hay cambios sin guardar. ¿Desea continuar?",
        confirmButtonColor: "red",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Continuar"
    };
    
    return new Promise((resolve, reject) => {
        Swal.fire(alertConfig).then((result) => {
            if (result.isConfirmed) {
                resolve(true);
            } else {
                reject(false);
            }
        });
    });
}

export default UnsavedChangesWarning;
