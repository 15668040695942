import React, { useState, useMemo } from 'react';
import { Row, Col, ListGroup, FloatingLabel, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import GetEntities from '../GenericComponents/Entities';
import ToolBar from '../GenericComponents/ToolBar'
import { DeleteEndPoint, GetEndPoint } from "../Api/Controllers/Common/EndPoints"
import Popup from '../GenericComponents/Popup';
import FormattedTextBox from '../GenericComponents/FormattedTextBox';
import DeleteWarning from '../GenericComponents/DeleteWarning';
import FormattedCurrencyInput from '../GenericComponents/FormattedCurrencyInput';
import Warning from '../GenericComponents/Warning';
import { Headset } from 'react-bootstrap-icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';


const Entity = GetEntities();
const deleteScheduleApi = DeleteEndPoint(Entity.Turno);
const reSendNotificationApi = GetEndPoint(Entity.ResendAppointmentNotification);
dayjs.extend(utc);

function ExistingSchedule({
    schedule,
    refresh,
    canEdit = true,
    canResendNotification = true,
    canDelete = true }) {

    const [popUpShow, setPopUpShow] = useState(false);

    const iconColor = useMemo(() => {
        if (schedule.ProfessionalConnectedOnlineOnUtc === null && schedule.PatientConnectedOnlineOnUtc === null) {
            return 'red';
        } else if (schedule.ProfessionalConnectedOnlineOnUtc !== null && schedule.PatientConnectedOnlineOnUtc !== null) {
            return 'green';
        } else {
            return 'orange';
        }
    }, [schedule.ProfessionalConnectedOnlineOnUtc, schedule.PatientConnectedOnlineOnUtc]);

    const renderTooltip = useMemo((props) => {
        const textMessage = (
            <>
                <u>Conexion de Profesional: </u><br />
                <b>{schedule.ProfessionalConnectedOnlineOnUtc ?
                    "SE CONECTO" : "NO SE CONECTO"}</b>
                <br />
                <u>Conexion de Paciente: </u><br />
                <b>{schedule.PatientConnectedOnlineOnUtc ?
                    "SE CONECTO" : "NO SE CONECTO"}</b>
            </>
        );
        return (
            <Tooltip id="button-tooltip" {...props}>
                <div>
                    {textMessage}
                </div>
            </Tooltip>
        );
    });

    const deleteSchedule = async (scheduleId) => {
        const key = { type: "guid", value: scheduleId }
        await deleteScheduleApi({ key })
        refresh();
    }

    const reSendNotificationAction = async (scheduleId) => {
        await reSendNotificationApi(scheduleId);
    }

    return (
        <ListGroup.Item>
            <ToolBar
                showDeleteButton={canDelete}
                deleteAction={() => DeleteWarning(() => deleteSchedule(schedule.Id))}
                showEditButton={canEdit}
                actionEdit={() => setPopUpShow(!popUpShow)}
                title={schedule.AppointmentDateTime.slice(11, 16)}
                titleTag='h4'
                reSendNotification={canResendNotification}
                actionReSendNotification={
                    () => Warning(() => reSendNotificationAction(schedule.Id), "¿Estas seguro que queres reenviar la notificacion del turno?")
                }
            />
            <Row>
                <div className="mt-3">
                    <Row className="g-2">
                        <Col>
                            <FormattedTextBox
                                label="Paciente"
                                type="text"
                                placeholder="Paciente"
                                name="name"
                                value={schedule.PatientName}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <FormattedTextBox
                                label="Modalidad de atencion"
                                type="text"
                                placeholder="Modalidad de atencion"
                                name="name"
                                value={schedule.CareModalityName}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <FormattedTextBox
                                label="Prestacion"
                                type="text"
                                placeholder="Prestacion"
                                name="name"
                                value={schedule.ServiceName}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <FloatingLabel controlId="Virtualidad" label="Virtualidad?">
                                <Form.Select aria-label="Floating label select example" value="1" disabled={true}>
                                    <option value="1">{schedule.IsOnline ? "Virtual" : "Presencial"}</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        {schedule.IsOnline && (
                            <Col xs={2} style={{ paddingTop: "10px" }}>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                >
                                    <span className="info-icon" style={{ cursor: 'pointer' }}>
                                        <Headset color={iconColor} />
                                    </span>
                                </OverlayTrigger>
                            </Col>
                        )}
                    </Row>
                    <Row className="g-2">
                        <Col>
                            <FormattedTextBox
                                label="Sede"
                                type="text"
                                placeholder="Sede"
                                name="name"
                                value={schedule.SiteName}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <FormattedTextBox
                                label="Asistencia"
                                type="text"
                                placeholder="Asistencia"
                                name="name"
                                value={schedule.AssistanceName}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <FormattedTextBox
                                label="Consultorio"
                                type="text"
                                placeholder="Consultorio"
                                name="name"
                                value={schedule.RoomName}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <FormattedTextBox
                                label="Obra social"
                                type="text"
                                placeholder="Obra social"
                                name="name"
                                value={schedule.HealthInsuranceName}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <FormattedCurrencyInput
                                label="Copago Pago"
                                required={false}
                                name="coPaymentCostPaid"
                                value={schedule.CoPaymentCostPaid}
                                disabled={true}
                                CoPaymentCostRequired={schedule.CoPaymentCostRequired}
                            />
                        </Col>
                    </Row>
                </div>
            </Row>
            <Popup
                show={popUpShow}
                onHide={() => { setPopUpShow(false); refresh(); }}
                popupentity={Entity.Turno}
                entity={schedule}
            />
        </ListGroup.Item>

    );
}

export default React.memo(ExistingSchedule);