import {axiosPrivate} from "../../BaseApiController/axios";

const endPoint = "api/Security/Me"

async function GetCurrentUser() {

    const response = await axiosPrivate.get(
        endPoint
      );

    const authUser =  response?.data?.FullName
    localStorage.setItem("authUser", JSON.stringify(authUser));

    const myId =  response?.data?.Id
    localStorage.setItem("myId", JSON.stringify(myId));

    return response
}

export default GetCurrentUser