import Put from "../../BaseApiController/Put"

const endPoint = "/api/ProfessionalSchedules"

async function PutProfessionalSchedule(queryObj, body) {
    const successMessage = "Agenda del profesional modificada con exito.";
    const professionalSchedule = await Put(endPoint, queryObj, body, null, successMessage)
    return professionalSchedule
}

export default PutProfessionalSchedule