import React, { useState, useEffect } from 'react';
import { Grid, Card, CardHeader, CardContent } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import GetEntities from '../GenericComponents/Entities';
import { GetEndPoint } from "../Api/Controllers/Common/EndPoints"
import { Spinner } from 'react-bootstrap';

const Entity = GetEntities()
const calculatePercent = (numerator, denominator) => denominator == 0 ? 0 : ((numerator / denominator) * 100).toFixed(2);


const DisplayInfo = ({ label, count, percent, isOrange, isRed, separator, loading }) => {
  const infoStyle = { display: 'flex', justifyContent: 'space-between' };
  const countStyle = { fontSize: '1.5rem', color: isOrange ? 'orange' : isRed ? 'red' : 'inherit' };
  const separatorStyle = { borderTop: '1px solid #000', marginTop: '1rem', paddingTop: '1rem' };
  const labelStyle = { fontWeight: 'bold', color: '#000000' };

  return (
    <CardContent>
      <div className="appointment-info" style={separator ? { ...infoStyle, ...separatorStyle } : infoStyle}>
        <p style={labelStyle}>{label}</p>
        {loading ? (
          <Spinner animation="border" variant="secondary" size="sm" />
        ) : (
          <span className="appointment-count" style={countStyle}>
            {count} 
            {percent !== null && (
              <>
                ({percent}%)
              </>
            )}
            {isOrange && <WarningIcon fontSize="small" style={{ marginLeft: '5px' }} />}
            {isRed && <ErrorOutlineIcon fontSize="small" style={{ marginLeft: '5px' }} />}
          </span>
        )}
      </div>
    </CardContent>
  );
};

const HomeStatistics = () => {
  const [homeKPIsData, sethomeKPIsData] = useState({
    TodayAppointmentCount: null,
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const homeData = await GetEndPoint(Entity.HomeKPI);
      const response = await homeData();
        sethomeKPIsData(response);
        setIsLoading(false);
    };
    fetchData();
  }, []);

  const {
    TodayAppointmentCount,
    TodayFinalizedAppointmentCount,
    TodayFinalizedWithoutHCAppointmentCount,
    TodayProfessionalWithoutHCCount,
    PastFinalizedWithoutHCAppointmentCount,
    PastProfessionalWithoutHCCount
  } = homeKPIsData;

  const todayFinalizedAppointmentCountPercent = calculatePercent(TodayFinalizedAppointmentCount, TodayAppointmentCount);
  const todayFinalizedWithoutHCAppointmentCountPercent = calculatePercent(TodayFinalizedWithoutHCAppointmentCount, TodayFinalizedAppointmentCount);

  return (
    <Grid container spacing={1}>
      <Grid item xs={5} md={5}>
        <Card>
          <CardHeader title="Estadisticas e indicadores" />
          <DisplayInfo label="Turnos del dia de hoy:" count={TodayAppointmentCount} percent={null} loading={isLoading} />
          <DisplayInfo label="Turnos finalizados el dia de hoy:" count={TodayFinalizedAppointmentCount} percent={todayFinalizedAppointmentCountPercent} loading={isLoading}/>
          <DisplayInfo label="Turnos finalizados sin evolucion del dia de hoy:" count={TodayFinalizedWithoutHCAppointmentCount} percent={todayFinalizedWithoutHCAppointmentCountPercent} isOrange={TodayFinalizedWithoutHCAppointmentCount > 0} loading={isLoading}/>
          <DisplayInfo label="Profesionales sin evolucion en turnos finalizados del dia de hoy:" count={TodayProfessionalWithoutHCCount} percent={null} isOrange={TodayProfessionalWithoutHCCount > 0} loading={isLoading}/>
          <DisplayInfo label="Turnos finalizados sin evolucion en dias anteriores:" count={PastFinalizedWithoutHCAppointmentCount} percent={null} separator={true} isRed={PastFinalizedWithoutHCAppointmentCount > 0} loading={isLoading}/>
          <DisplayInfo label="Profesionales sin evolucion en turnos de dias anteriores:" count={PastProfessionalWithoutHCCount} percent={null} isRed={PastProfessionalWithoutHCCount > 0} loading={isLoading}/>
        </Card>
      </Grid>
    </Grid>
  );
};

export default HomeStatistics;
