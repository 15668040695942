import React from 'react';
import { Row, Card, Container, Col } from "react-bootstrap";
import PatientHelpPdf from '../../assets/PatientHelp.pdf'
import PatientHelpVideo from '../../assets/PatientHelpVideo.mp4'

const PatientHelp = () => {
    return (
        <Container>
            <Card>
                <Card.Header>
                    <h2>Ayuda</h2>
                </Card.Header>
                <Card.Body>
                    <Row> 
                      <Col xs={2}>Sitio institucional: </Col>
                      <Col><a href='https://www.macropsicologia.com.ar' target='_blank'>www.macropsicologia.com.ar</a></Col>
                    </Row>
                    <Row> 
                      <Col xs={2}>Contactanos: </Col>
                      <Col><a href='https://www.macropsicologia.com.ar/contacto' target='_blank'>Informacion de contacto</a></Col>
                    </Row>
                    <Row>
                      <Col xs={2}>Instructivo (PDF):</Col>
                      <Col><a href={PatientHelpPdf} target='_blank'>Descargar PDF</a></Col>
                    </Row>
                    <Row>
                      <Col xs={2}>Instructivo (video):</Col>
                      <Col>
                      <video width="478" height="850" controls>
                        <source src={PatientHelpVideo} type="video/mp4" />
                        Este navegador no puede reproducir video, utilice el instructivo en PDF.
                      </video>
                      </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default PatientHelp