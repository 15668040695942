import { useState, useContext, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Card } from "react-bootstrap";
import PersonalInformation from './PersonalInformation/PersonalInformation';
import { useParams } from "react-router-dom"
import CardTableSearch from '../TableSearch/CardTableSearch';
import Evolution from "./Evolutions/Evolution"
import GetEntities from '../GenericComponents/Entities';
import CoverageAttentionData from './CoverageAttentionData/CoverageAttentionData';
import { useLocation } from 'react-router-dom';
import { UserOperationsAllowedContext } from '../Dashboard/UserOperationsAllowedContext';
import Prescriptions from './Prescriptions';
import GetUserOperationsAllowed from '../GenericComponents/userOperationsAllowed';

const relatedPeopleEntity = GetEntities().Familiares;
const movementsAppointmnes = GetEntities().Turno;
const Operations = GetUserOperationsAllowed();

const Patient = ({ requiredOperations = [] }) => {
    let { patientId } = useParams();
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const savedTab = localStorage.getItem('activePatientTab') || 'formPatient';
    const [activeTab, setActiveTab] = useState(savedTab);
    const { userOperationsAllowed, hasAllRequiredOperations } = useContext(UserOperationsAllowedContext);

    const handleSelect = (key) => {
        localStorage.setItem('activePatientTab', key);
        setActiveTab(key);
    }

    const userAllowed = (requiredOperations) => hasAllRequiredOperations({
        requiredOperations,
        userOperations: userOperationsAllowed
    });

    const prescriptionsAllowed = hasAllRequiredOperations({
        requiredOperations: [, Operations.ManagePrescription, Operations.SearchPrescription],
        userOperations: userOperationsAllowed
    });

    useEffect(() => {
        return () => {
            localStorage.removeItem('activePatientTab');
        };
    }, []);

    return (
        <Card>
            <Tabs
                activeKey={activeTab}
                onSelect={handleSelect}
                id="justify-tab-example"
                className="mb-3"
                justify
            >
                <Tab eventKey="formPatient" title="Datos Personales">
                    {activeTab === 'formPatient' &&
                        <Card.Body>
                            <PersonalInformation patientId={patientId} userAllowed={userAllowed([Operations.ManagePatient])} />
                        </Card.Body>}
                </Tab>
                <Tab eventKey="family" title="Familiares" disabled={patientId === undefined} >
                    {activeTab === 'family' &&
                        <Card.Body>
                            <CardTableSearch
                                title="Familiares"
                                entity={relatedPeopleEntity}
                                columns={relatedPeopleEntity}
                                key={relatedPeopleEntity}
                                entityId={patientId}
                                popUp={true}
                                requiredOperations={[Operations.ManagePatient]}
                            />
                        </Card.Body>}
                </Tab>
                <Tab eventKey="HC" title="Historias Clinicas" disabled={patientId === undefined} >
                    {activeTab === 'HC' &&
                        <Card.Body>
                            <Evolution patientId={patientId} userAllowed={userAllowed([Operations.ManagePatient])} />
                        </Card.Body>}
                </Tab>
                <Tab eventKey="Prescriptions" title="Recetas" disabled={patientId === undefined} >
                    {activeTab === 'Prescriptions' &&
                        <Card.Body>
                            <Prescriptions patientId={patientId} userAllowed={prescriptionsAllowed} />
                        </Card.Body>}
                </Tab>
                <Tab eventKey="movements" title="Movimientos" disabled={patientId === undefined} >
                    {activeTab === 'movements' &&
                        <Card.Body>
                            <CardTableSearch
                                title="Movimientos"
                                entity={movementsAppointmnes}
                                columns={movementsAppointmnes}
                                key={movementsAppointmnes}
                                entityId={patientId}
                                popUp={false}
                                showDeleteButton={false}
                                showNewButton={false}
                                checkboxSelection={false}
                                orderBy={['AppointmentDateTime desc']}
                                columnClickEnabled={false}
                                requiredOperations={[Operations.ManagePatient]} />
                        </Card.Body>}
                </Tab>
                <Tab eventKey="coverageattentiondata" title="Cobertura y atencion" disabled={patientId === undefined} >
                    {activeTab === 'coverageattentiondata' &&
                        <Card.Body>
                            <CoverageAttentionData patientId={patientId} userAllowed={userAllowed([Operations.ManagePatient])} />
                        </Card.Body>}
                </Tab>
            </Tabs>
        </Card>
    );
}

export default Patient;
