import React, { useState, useEffect, useMemo } from "react";
import { Form, FloatingLabel, Row, Col } from "react-bootstrap";
import { GetEndPoint } from "../Api/Controllers/Common/EndPoints";
import FillEntity from "./FillEntity";
import { useUnsavedChanges } from "../Context/UnsavedChangesContext";

const fetchDropdownOptions = async (apiEndpoint, useConditions, conditions, featureFillEntity, orderBy) => {
  const api = await GetEndPoint(apiEndpoint);
  if (!orderBy.length){
    orderBy = ['Name asc']
  }
  const obj = {
    filter: conditions,
    orderBy: orderBy
  }
  const response = await api(obj);

  return response.value.map((entityResponse) => {
    if (featureFillEntity) {
      return FillEntity(apiEndpoint, entityResponse);
    }
    const { Name, Id } = entityResponse;
    return { Name, Id };
  });
};

const FormattedDropdown = ({
  apiEndpoint,
  label,
  required,
  name,
  value,
  onChange,
  featureFillEntity = false,
  useConditions = false,
  conditions,
  disabled = false,
  orderBy = []
}) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const { setHasUnsavedChanges } = useUnsavedChanges();

  useEffect(() => {
    const fetchData = async () => {
      if (useConditions && (!conditions || Object.keys(conditions).length === 0)) {
        setDropdownOptions([]);
        return;
      }
      const entities = await fetchDropdownOptions(apiEndpoint, useConditions, conditions, featureFillEntity, orderBy);
      setDropdownOptions(entities);
    };
    fetchData();
  }, [apiEndpoint, conditions, disabled]);

  const sortedDropdownOptions = useMemo(() => {
    return [...dropdownOptions].sort((a, b) => a.Name.localeCompare(b.Name));
  }, [dropdownOptions]);

  const handleSelectChange = (event) => {
    setHasUnsavedChanges(true);
    const { value: selectedValue } = event.target;

    const isNumericId = dropdownOptions.some(option => typeof option.Id === 'number');
  
    const selectedOption = dropdownOptions.find(option => 
      isNumericId ? option.Id === Number(selectedValue) : option.Id === selectedValue
    );
  
    if (!selectedOption) {
      onChange(null);
    } else {
      onChange(selectedValue === "" ? null : selectedOption.Id);
    }
  };

  return (
    <Row className="gx-2"> 
      <Col> 
        <FloatingLabel
          style={{ width: "100%" }}
          className="me-auto mb-3"
          controlId={`${name}Grid`}
          label={label}
        >
          <Form.Select
            aria-label="Floating label select example"
            required={required}
            onChange={handleSelectChange}
            name={name}
            value={value !== null ? value : ""}
            disabled={disabled}
          >
            <option></option>
            {dropdownOptions.map((opt) => (
              <option key={opt.Id} value={opt.Id}>
                {featureFillEntity ? opt.getName() : opt.Name}
              </option>
            ))}
          </Form.Select>
        </FloatingLabel>
      </Col>
    </Row> 
  );
};

export default FormattedDropdown;
