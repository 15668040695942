class Profesional {
    constructor(Id, FullName, Name, LastName, DateOfBirth, DocumentNumber, PhoneNumber, CellPhoneNumber,
        Email, UserName, DocumentTypeId, AddressStreet, AddressNumber, PostalCode, AddressAdditionalDetails,
        TownId, ProvinceId, CountryId, SpecialtyId, SpecialtyName, RegistrationNumber, IsActive = true, Remarks, GoogleAccountEmail, Cuit) {
        this.Id = Id;
        this.FullName = FullName;
        this.Name = Name;
        this.LastName = LastName;
        this.DateOfBirth = DateOfBirth;
        this.DocumentNumber = DocumentNumber;
        this.PhoneNumber = PhoneNumber;
        this.CellPhoneNumber = CellPhoneNumber;
        this.Email = Email;
        this.UserName = UserName;
        this.DocumentTypeId = DocumentTypeId;
        this.AddressStreet = AddressStreet;
        this.AddressNumber = AddressNumber;
        this.PostalCode = PostalCode;
        this.AddressAdditionalDetails = AddressAdditionalDetails;
        this.TownId = TownId;
        this.ProvinceId = ProvinceId;
        this.CountryId = CountryId;
        this.SpecialtyId = SpecialtyId;
        this.SpecialtyName = SpecialtyName;
        this.RegistrationNumber = RegistrationNumber;
        this.IsActive = IsActive;
        this.Remarks = Remarks;
        this.GoogleAccountEmail = GoogleAccountEmail;
        this.Cuit = Cuit;
    }

    getName() {
        return this.FullName;
    }
}

export default Profesional