import Put from "../../BaseApiController/Put"

const endPoint = "/api/Genders"

async function PutGender(queryObj, body) {
    const successMessage = "Genero modificado con exito.";
    const relationship = await Put(endPoint, queryObj, body, null, successMessage)
    return relationship
}

export default PutGender