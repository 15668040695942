import Put from "../../BaseApiController/Put"

const endPoint = "/api/Towns"

async function PutTown(queryObj, body) {
    const successMessage = "Ciudad modificada con exito.";
    const town = await Put(endPoint, queryObj, body, null, successMessage)
    return town
}

export default PutTown