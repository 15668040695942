import React from 'react';
import { Form, FloatingLabel, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';
import { NumericFormat } from 'react-number-format';

const FormattedCurrencyInput = ({
    label,
    required = false,
    name,
    value = 0.00,
    onChange,
    disabled = false,
    CoPaymentCostRequired = 0,
    disabledTooltip = false
}) => {

    const remainingCost = CoPaymentCostRequired - value;
    const infoIconColor = remainingCost <= 0 ? 'green' : 'red';
    const remainingCostAbs = Math.abs(remainingCost);

    const renderTooltip = (props) => {
        const textMessage = remainingCost <= 0
            ? <><u>Tiene copago a favor</u> = <b>${remainingCostAbs}</b></>
            : <><u>Copago que falta pagar</u> = <b>${remainingCostAbs}</b></>;

        return (
            <Tooltip id="button-tooltip" {...props}>
                <div>
                    <u>Costo de copago requerido </u>= <b>$ {CoPaymentCostRequired}</b> <br />
                    <span style={{ color: infoIconColor }}>{textMessage}</span>
                </div>
            </Tooltip>
        )
    };

    const handleValueChange = onChange
    ? (values) => {
        const { value } = values;
        onChange(parseFloat(value));
    }
    : () => {};

    return (
        <Row>
            <Col>
                <FloatingLabel
                    style={{ width: '-webkit-fill-available' }}
                    className="me-auto mb-3"
                    controlId={`${name}Grid`}
                    label={label}
                >
                    <NumericFormat
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={value}
                        onValueChange={handleValueChange}
                        customInput={Form.Control}
                        type="text"
                        required={required}
                        name={name}
                        disabled={disabled}
                        placeholder="$0.00"
                    />
                </FloatingLabel>
            </Col>
            {!disabledTooltip && remainingCost !== 0 && (
                <Col xs={2} style={{ paddingTop: "10px" }}>
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <span className="info-icon" style={{ cursor: 'pointer' }}>
                            <InfoCircleFill color={infoIconColor} />
                        </span>
                    </OverlayTrigger>
                </Col>
            )}
        </Row>
    );
};

export default FormattedCurrencyInput;
