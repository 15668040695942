import Get from "../../BaseApiController/Get"

const endPoint = "/api/Appointments/{id}/ResendAppointmentNotification"

async function GetResendAppointmentNotification(appointmentId) {
    let ensambledEndPoint = endPoint.replace("{id}", appointmentId);
    const successMessage = "La notificacion se ha enviado con exito!"
    const response = await Get(ensambledEndPoint, undefined, null, null, successMessage);
    return response;
}

export default GetResendAppointmentNotification