import Post from "../../BaseApiController/Post"

const endPoint = "/api/CareModalities"

async function PostCareModality(body) {
    const successMessage = "Modalidad de atencion creada con exito.";
    const response = await Post(endPoint, body, null, successMessage)
    return response
}

export default PostCareModality