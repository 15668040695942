import Post from "../../BaseApiController/Post"

const endPoint = "/api/SelfService/Create/{generalPractitionerId}"

async function PostSelfserviceAppointment(body, generalPractitionerId) {
    const successMessage = "Turno creado con éxito.";
    let ensambledEndPoint = endPoint.replace("{generalPractitionerId}", generalPractitionerId);
    const response = await Post(ensambledEndPoint, body, null, successMessage);
    return response;
}

export default PostSelfserviceAppointment