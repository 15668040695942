import Put from "../../BaseApiController/Put"

const endPoint = "/api/Provinces"

async function PutProvince(queryObj, body) {
    const successMessage = "Provincia modificada con exito.";
    const province = await Put(endPoint, queryObj, body, null, successMessage)
    return province
}

export default PutProvince