import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Form, FormLabel, Container, Row, Col, Card } from "react-bootstrap";
import GetRole from "../Api/Controllers/Roles/GetRole";
import GetRule from "../Api/Controllers/Rules/GetRule";
import PutRole from "../Api/Controllers/Roles/PutRole";
import PostRole from "../Api/Controllers/Roles/PostRole";
import DeleteRole from "../Api/Controllers/Roles/DeleteRole";
import { useNavigate } from "react-router-dom";
import FormattedTextBox from "../GenericComponents/FormattedTextBox";
import Toolbar from "../GenericComponents/ToolBar";
import GetEntities from "../GenericComponents/Entities";
import { EditEntityPath, SearchPath } from "../Api/Controllers/Common/Paths";
import DeleteWarning from "../GenericComponents/DeleteWarning";
import { UserOperationsAllowedContext } from "../Dashboard/UserOperationsAllowedContext";

const entity = GetEntities().Rol;
const editPath = EditEntityPath(entity);
const searchPath = SearchPath(entity);

function EditRole({ requiredOperations = [] }) {
  const [roleName, setRoleName] = useState("");
  const [checkedRules, setCheckedRules] = useState([]);
  const [rules, setRules] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [validated, setValidated] = useState(false);
  const form = useRef(null);
  let { rolId } = useParams();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { userOperationsAllowed, hasAllRequiredOperations } = useContext(
    UserOperationsAllowedContext
  );

  const fetchRolData = async (rolId) => {
    const queryObj = {
      key: { type: "guid", value: rolId },
      expand: ["rules"],
    };
    const responseRol = await GetRole(queryObj);
    setRoleName(responseRol.Name);
    const initalRules = responseRol.Rules.map((obj) => obj.Id);
    setCheckedRules(initalRules);
  };

  const fetchRulesData = async () => {
    const queryObj = {
      orderBy: "Name",
    };
    const responseRule = await GetRule(queryObj);
    setRules(responseRule.value);
  };

  useEffect(() => {
    if (rolId !== undefined) {
      fetchRolData(rolId);
    }
    setValidated(false);
    fetchRulesData();
  }, [rolId, refresh]);

  const handleRoleNameChange = (e) => {
    setRoleName(e.target.value);
  };

  const handleSaveClick = async (event) => {
    setIsLoading(true);
    setValidated(form.current.checkValidity() === false);
    if (form.current.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const body = { name: roleName, ruleIds: checkedRules };
      if (rolId !== undefined) {
        const key = { type: "guid", value: rolId };
        await PutRole({ key }, body);
      } else {
        const newRole = await PostRole(body);
        const id = newRole.Id;
        if (newRole) {
          navigate(`${editPath}${id}`, { state: { id: id } });
        }
      }
    }
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
  };

  const handleCheckboxChange = (e) => {
    const id = e.target.id;
    if (e.target.checked) {
      setCheckedRules([...checkedRules, id]);
    } else {
      setCheckedRules(checkedRules.filter((ruleId) => ruleId !== id));
    }
  };

  const deleteRole = async () => {
    setIsLoading(true);
    const key = { type: "guid", value: rolId };
    await DeleteRole({ key });
    navigate(searchPath);
    rolId = undefined;
    setRefresh(!refresh);
  };

  const handleSelectAllRules = (selectAll) => {
    setCheckedRules((prevCheckedRules) => {
      let newCheckedRules;
      if (selectAll) {
        newCheckedRules = rules.map((rule) => rule.Id);
      } else {
        newCheckedRules = [];
      }

      return newCheckedRules;
    });
  };

  const userAllowed = hasAllRequiredOperations({
    requiredOperations,
    userOperations: userOperationsAllowed,
  });

  return (
    <Container>
      <Card>
        <Card.Header>
          {!rolId ? (
            <Toolbar
              title="Crear Rol"
              entity={entity}
              saveAction={handleSaveClick}
              setRefresh={setRefresh}
              refresh={refresh}
              showBackButton={userAllowed}
              showSaveButton={userAllowed}
              disabled={isLoading}
            />
          ) : (
            <Toolbar
              title="Editar Rol"
              entity={entity}
              deleteAction={() => DeleteWarning(deleteRole)}
              saveAction={handleSaveClick}
              setRefresh={setRefresh}
              refresh={refresh}
              showBackButton={true}
              showSaveButton={userAllowed}
              showNewButton={userAllowed}
              showDeleteButton={userAllowed}
              showRefreshButton={true}
              disabled={isLoading}
            />
          )}
        </Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} key="formRole" ref={form}>
            <FormattedTextBox
              label="Nombre"
              type="text"
              name="name"
              value={roleName}
              handleChange={handleRoleNameChange}
              required={true}
              validated={validated}
              disabled={!userAllowed}
            />
            <Form.Group as={Row}>
              <Col>
                <FormLabel>Acciones</FormLabel>
                {rules.length > 0 && (
                  <Form.Check
                    type="checkbox"
                    label={
                      checkedRules.length === rules.length
                        ? "Deseleccionar todo"
                        : "Seleccionar todo"
                    }
                    checked={checkedRules.length === rules.length}
                    onChange={() =>
                      handleSelectAllRules(checkedRules.length !== rules.length)
                    }
                    disabled={!userAllowed}
                  />
                )}
                {rules.map((rule) => (
                  <Form.Check
                    key={rule.id}
                    type="checkbox"
                    label={rule.Name}
                    id={rule.Id}
                    checked={checkedRules.includes(rule.Id)}
                    onChange={handleCheckboxChange}
                    disabled={!userAllowed}
                  />
                ))}
              </Col>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default EditRole;
