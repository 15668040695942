class Patient {
    constructor(Id, FullName, Name, LastName, DateOfBirth, DocumentNumber, PhoneNumber, CellPhoneNumber,
        Email, UserName, DocumentTypeId, AddressStreet, AddressNumber, PostalCode, AddressAdditionalDetails,
        TownId, ProvinceId, CountryId, GeneralPractitionersIds, HealthInsuranceId, HealthInsuranceNumber, CareModalityId, GenderId) {
        this.Id = Id;
        this.FullName = FullName;
        this.Name = Name;
        this.LastName = LastName;
        this.DateOfBirth = DateOfBirth;
        this.DocumentNumber = DocumentNumber;
        this.PhoneNumber = PhoneNumber;
        this.CellPhoneNumber = CellPhoneNumber;
        this.Email = Email;
        this.UserName = UserName;
        this.DocumentTypeId = DocumentTypeId;
        this.AddressStreet = AddressStreet;
        this.AddressNumber = AddressNumber;
        this.PostalCode = PostalCode;
        this.AddressAdditionalDetails = AddressAdditionalDetails;
        this.TownId = TownId;
        this.ProvinceId = ProvinceId;
        this.CountryId = CountryId;
        this.GeneralPractitionersIds = GeneralPractitionersIds;
        this.HealthInsuranceId = HealthInsuranceId;
        this.HealthInsuranceNumber = HealthInsuranceNumber;
        this.CareModalityId = CareModalityId;
        this.GenderId = GenderId;
    }

    getName() {
        return this.FullName;
    }
}

export default Patient